import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';

export interface IEntitiy {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  title?: string;
}

export const GET_ENTITIES = gql`
  query getEntities($filter: EntityFilterInput, $take: Int, $skip: Int, $orderBy: EntityOrderByInput) {
    findManyEntities(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      title
      parentID
      parent {
        id
        title
        parentID
      }
    }
  }
`;

export const convertEntitiesToComboBoxOptions = (entities: IEntitiy[]) => {
  if (entities) {
    const result: IComboBoxOption[] = entities.map(
      (entity: IEntitiy): IComboBoxOption => ({
        id: entity.id as any,
        name: `${entity.title}`,
        active: true, //no attribute active in entity
      }),
    );
    return result;
  }

  return [];
};
