import { gql } from '@apollo/client';
import { IUser } from './user';
import { IEvent } from './event';

export const CREATE_ACTIVITY_THEMETYPE = gql`
  mutation createActivityThemeType($data: ActivityThemeTypeCreateInput!) {
    createActivityThemeType(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_ACTIVITY_THEMETYPE = gql`
  mutation deleteActivityThemeType($where: ActivityThemeTypeWhereInput) {
    deleteActivityThemeType(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
