import { gql } from '@apollo/client';
import { IUser } from './user';
import { IEvent } from './event';

export const CREATE_USER_ENTITY = gql`
  mutation createUserEntity($data: UserEntityCreateInput!) {
    createUserEntity(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_USER_ENTITY = gql`
  mutation deleteUserEntity($where: UserEntityWhereInput) {
    deleteUserEntity(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
