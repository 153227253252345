import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import Button from '../../components/tailwind/button';
import { Modal } from '../../components/tailwind/modal';
import { IColumn, Table } from '../../components/tailwind/table';
import { ISorting } from '../../types';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  filterComboBoxEntityItem,
  resolveValueForCombobox,
  transformCloudinaryUrl,
} from '../../utils';
import { CREATE_USER, GET_USER, GET_USERS, IUpdateUser, IUser, UPDATE_USER } from '../../utils/user';
import { SearchContext } from '../../context/searchContext';
import { toastError, toastSuccess } from '../../utils/toast';
import { InlineFilter } from '../../components/inlineFilterForm';
import { convertEntitiesToComboBoxOptions, GET_ENTITIES } from '../../utils/entity';
import { CREATE_USER_ENTITY, DELETE_USER_ENTITY } from '../../utils/userEntity';

export const Users: React.FC = () => {
  // ========== GENERAL STATE ==========

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const {
    loading: loadingEntities,
    error: errorEntities,
    data: dataEntities,
  } = useQuery(GET_ENTITIES, {
    variables: {
      filter: {
        title: {
          not: {
            equals: 'Onderwijs',
          },
        },
      },
    },
  });

  // USER ENTITY SSTATE

  const [selectedUserEntity, setSelectedUserEntity] = useState<any | undefined>();
  const [createUserEntity] = useMutation(CREATE_USER_ENTITY);
  const [deleteUserEntity] = useMutation(DELETE_USER_ENTITY);

  const handleCreateUserEntity = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedUserEntity) {
            const createObj: any = {
              user: {
                connect: {
                  id: selectedUser?.id,
                },
              },
              entity: {
                connect: {
                  id: selectedUserEntity?.id,
                },
              },
            };

            createUserEntity({
              variables: {
                data: createObj,
              },
              onCompleted: data => {
                resolve(data.createUserEntity);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchUser();
      toastSuccess('Entiteit succesvol toegevoegd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteUserEntity = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            deleteUserEntity({
              variables: {
                where: {
                  id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchUser(); // activitie
      toastSuccess('Entiteit succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const userEntityColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Titel',
      fieldName: 'propsalLineUser.user.firstName',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.entity?.title}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Actie',
      fieldName: 'action',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <TrashIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                console.log(item);
                handleDeleteUserEntity(item?.id as any);
              }}
            />
          </span>
        );
      },
    },
  ];

  // ========== ACTIVITY STATE ==========

  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [selectedUserTab, setSelectedUserTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingUsers, setSortingUsers] = useState<ISorting>({
    key: 'firstName',
    direction: 'asc',
  });
  const [openUserDetail, setOpenUserDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [userFilterState, setUserFilterState] = useState<{
    isNotActive: boolean;
    isBlocked: boolean;
    status: string;
  }>();
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery]);

  const userColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'user.lastName',
      render: (item: IUser) => {
        return (
          <span className='flex-row'>
            <span
              className={classNames(
                'text-ellipsis text-base overflow-hidden block w-30',
                !item?.active && !item.blocked ? 'text-cego-darkgray' : item.blocked && 'text-cego-red',
              )}
            >
              <img className='h-8 w-8 inline rounded-full bg-gray-50' src={transformCloudinaryUrl(item?.image)} alt='' />
              <span className='pl-2'>
                {item?.firstName} {item?.lastName}
              </span>
            </span>
          </span>
        );
      },
      // sortable: true,
      // isSorted: sortingUsers && sortingUsers.key === 'lastName',
      // isSortedDescending: sortingUsers && sortingUsers.direction === 'desc',
    },
    {
      key: 'column3',
      name: 'Email',
      fieldName: 'user.email',
      render: (item: IUser) => {
        return (
          <span
            className={classNames(
              'text-ellipsis overflow-hidden block w-40 text-base',
              !item.active && !item.blocked ? 'text-cego-darkgray' : item.blocked && 'text-cego-red',
            )}
          >
            {item?.email}
          </span>
        );
      },
    },
    {
      key: 'Job titel',
      name: 'Titel',
      fieldName: 'user.jobTitel',
      render: (item: IUser) => {
        return (
          <span
            className={classNames(
              'text-ellipsis text-base overflow-hidden block w-30',
              !item.active && !item.blocked ? 'text-cego-darkgray' : item.blocked && 'text-cego-red',
            )}
          >
            {item?.jobTitle}
          </span>
        );
      },
    },
  ];

  const getOrderBy = useCallback(
    (sortingOrders: any) => {
      return { [sortingOrders.key]: sortingOrders.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingUsers],
  );

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ lastName: { contains: filterArray[i] } }, { firstName: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataUsers,
    loading: loadingUsers,
    error: errorUsers,
    refetch: refetchUsers,
    fetchMore: fetchMoreUsers,
  } = useQuery(GET_USERS, {
    variables: {
      filter: getFilters(searchQuery),
      orderBy: getOrderBy(sortingUsers),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getUser, { loading: loadingUser, error: errorUser, refetch: refetchUser }] = useLazyQuery(GET_USER);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const handleSetUserSelection = (user?: IUser) => {
    if (user?.id) {
      getUser({
        variables: {
          where: {
            id: user?.id,
          },
        },
        onCompleted: data => {
          setSelectedUser(data.findOneUser);
        },
      });
    } else {
      setSelectedUser(undefined);
    }

    setOpenUserDetail(true);
  };

  const handleUpdateUser = async () => {
    try {
      const res = await toast.promise(
        new Promise(async (resolve, reject) => {
          if (selectedUser?.id) {
            let url = '';

            if (selectedFile) {
              try {
                const data = new FormData();
                data.append('file', selectedFile as any);
                data.append('upload_preset', 'cego_dashboard_preset');
                data.append('cloud_name', 'dzu3bqpsr');

                const response = await fetch('https://api.cloudinary.com/v1_1/dzu3bqpsr/image/upload', {
                  method: 'post',
                  body: data,
                });

                if (!response.ok) {
                  reject('Upload ging mis, bestand heeft verkeerd formaat');
                }

                const dataResponse = await response.json();
                url = dataResponse.url;
              } catch {
                reject('Upload ging mis, bestand heeft verkeerd formaat');
              }
            }

            const updateUserObj: IUpdateUser = {
              firstName: selectedUser?.firstName,
              lastName: selectedUser?.lastName,
              email: selectedUser?.email,
              jobTitle: selectedUser?.jobTitle,
              image: selectedFile
                ? url
                : selectedUser?.image
                ? selectedUser?.image
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
              blocked: selectedUser?.blocked,
              active: selectedUser?.active,
              // roles but remove __typereference propperty
              roles: selectedUser?.roles?.map(role => {
                return {
                  key: role?.key,
                  name: role?.name,
                  description: role?.description,
                };
              }),
            };

            updateUser({
              variables: {
                id: selectedUser?.id,
                data: updateUserObj,
              },
              onCompleted: data => {
                resolve(data.updateUser);
              },
              onError: error => {
                reject(error);
              },
            });
            setSelectedFile(null);
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const user: IUser = (await res) as any;
      setSelectedUser(user);
      refetchUsers({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingUsers),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Gebruiker succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateUser = async () => {
    try {
      const res = await toast.promise(
        new Promise(async (resolve, reject) => {
          if (!selectedUser?.id) {
            let url = '';

            if (selectedFile) {
              try {
                const data = new FormData();
                data.append('file', selectedFile as any);
                data.append('upload_preset', 'cego_dashboard_preset');
                data.append('cloud_name', 'dzu3bqpsr');

                const response = await fetch('https://api.cloudinary.com/v1_1/dzu3bqpsr/image/upload', {
                  method: 'post',
                  body: data,
                });

                if (!response.ok) {
                  reject('Upload ging mis, bestand heeft verkeerd formaat');
                }

                const dataResponse = await response.json();
                url = dataResponse.url;
              } catch {
                reject('Upload ging mis, bestand heeft verkeerd formaat');
              }
            }

            const createUserObj: IUpdateUser = {
              firstName: selectedUser?.firstName,
              lastName: selectedUser?.lastName,
              email: selectedUser?.email,
              jobTitle: selectedUser?.jobTitle,
              image: selectedFile
                ? url
                : selectedUser?.image
                ? selectedUser?.image
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
              blocked: selectedUser?.blocked,
              active: selectedUser?.active,
              roles: selectedUser?.roles?.map(role => {
                return {
                  key: role?.key,
                  name: role?.name,
                  description: role?.description,
                };
              }),
            };

            createUser({
              variables: {
                data: createUserObj,
              },
              onCompleted: data => {
                resolve(data.createUser);
              },
              onError: error => {
                reject(error);
              },
            });
            setSelectedFile(null);
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const user: IUser = (await res) as any;
      setSelectedUser(user);
      refetchUsers({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingUsers),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Gebuiker succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const userFilterSchema: InlineFilter = {
    id: 'user_filter',
    fields: [
      {
        id: 'user_filter_field_0',
        label: 'Status',
        name: 'status',
        value: userFilterState?.isNotActive ? 'inActief' : userFilterState?.isBlocked ? 'Geblokkeerd' : 'Kies',
        width: 24,
        type: 'select',
        selectbox: {
          items: 'Inactief,Geblokkeerd',
        },
        callback: (name: string, value: string) => {
          if (value === 'Inactief') {
            setUserFilterState((prevState: any) => ({
              ...prevState,
              isNotActive: true,
            }));
          }
          return value;
        },
      },
    ],
  };

  const userDetailSchema: DetailSchema = {
    id: 'user_detail',
    object: selectedUser,
    permission: '',
    selectedTab: selectedUserTab,
    setSelectedTab: setSelectedUserTab,
    handleUpdate: handleUpdateUser,
    handleCreate: handleCreateUser,
    sections: [
      {
        id: 'user_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'user_detail_section_0_field_0',
            label: 'Voornaam',
            name: 'firstName',
            required: true,
            value: selectedUser?.firstName,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'user_detail_section_0_field_1',
            label: 'Achternaam',
            name: 'lastName',
            required: true,
            value: selectedUser?.lastName,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'user_detail_section_0_field_3',
            label: 'Job titel',
            name: 'jobTitle',
            required: true,
            value: selectedUser?.jobTitle,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'user_detail_section_0_field_2',
            label: 'Email',
            name: 'email',
            required: true,
            value: selectedUser?.email,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string) => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Email moet minimaal 6 karakters bevatten',
                };
              }

              if (!value || value.length < 1) {
                return {
                  valid: false,
                  message: 'Email moet minimaal 6 karakters bevatten',
                };
              }

              if (value.length > 50) {
                return {
                  valid: false,
                  message: 'Email mag maximaal 50 karakters bevatten',
                };
              }

              return { valid: true };
            },
            // disableCallback: () => {
            //   if (selectedUser?.id) {
            //     return true;
            //   }

            //   return false;
            // },
          },
          {
            id: 'user_detail_section_0_field_5',
            label: 'Actief',
            name: 'active',
            required: true,
            value: selectedUser?.active,
            placeholder: '',
            type: 'switch',
            width: 20.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'user_detail_section_0_field_6',
            label: 'Geblokkeerd',
            name: 'blocked',
            required: true,
            value: selectedUser?.blocked,
            placeholder: '',
            type: 'switch',
            width: 20.5,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              if (selectedUser?.id) {
                return true;
              }

              return false;
            },
          },
          {
            id: 'user_detail_section_0_field_4',
            label: 'Rollen',
            name: 'roles',
            required: true,
            value: selectedUser?.roles,
            placeholder: '',
            type: 'roles',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value, //formatRoles
              }));
              return value;
            },
          },
          // {
          //   id: 'user_detail_section_0_field_4xextra',
          //   label: 'Entiteiten',
          //   name: 'roles',
          //   required: true,
          //   value: selectedUser?.userEntities,
          //   placeholder: '',
          //   type: 'roles',
          //   width: 100,
          //   callback: (name: string, value: string) => {
          //     setSelectedUser((prevState: any) => ({
          //       ...prevState,
          //       [name]: value, //formatRoles
          //     }));
          //     return value;
          //   },
          // },
          {
            id: 'activity_detail_section_0_field_7',
            label: 'Foto',
            name: 'image',
            value: selectedUser?.image,
            //  required: true,
            type: 'dropzone',
            dropzone: {
              selectedFile: selectedFile,
              setSelectedFile: setSelectedFile,
            },
            width: 100,
            callback: (name: string, value: any) => {
              setSelectedUser((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            // validateCallback: (value?: any): { valid: boolean; message?: string } => {
            //   if (!selectedFile && !value) {
            //     return {
            //       valid: false,
            //       message: 'Upload een foto',
            //     };
            //   }

            //   return { valid: true };
            // },
          },
        ],
      },
      {
        id: 'user_detail_section_11',
        title: 'Entiteiten',
        width: 100,
        fields: [
          {
            id: 'user_detail_section_11_field_1',
            label: 'Entiteiten',
            name: 'Entiteiten',
            value: selectedUser?.userEntities,
            fasttable: {
              columns: userEntityColumns,
              items: selectedUser?.userEntities ? selectedUser?.userEntities : [],
            },
            type: 'fast-table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedUserEntity((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'user_detail_section_11_field_2',
            label: 'Entiteit',
            name: 'id',
            required: true,
            value: resolveValueForCombobox(
              selectedUserEntity?.id ? selectedUserEntity?.id : '',
              convertEntitiesToComboBoxOptions(
                dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities ? dataEntities.findManyEntities : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertEntitiesToComboBoxOptions(
                dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities
                  ? selectedUser?.id
                    ? dataEntities.findManyEntities
                    : filterComboBoxEntityItem(dataEntities.findManyEntities)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedUserEntity((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            hideCallback: () => {
              // if (selectedEvent?.closed === true) {
              //   return false;
              // }
              return true;
            },
          },
          {
            id: 'activity_detail_section_11_field_3',
            label: '',
            name: '',
            value: '',
            placeholder: '',
            type: 'add-button',
            width: 66,
            callback: (name: string, value: any) => {
              handleCreateUserEntity();
              return value;
            },
            disableCallback(value) {
              if (selectedUser?.userEntities && selectedUser?.userEntities.some(item => item.entity.id === selectedUserEntity?.id)) {
                return true;
              }
              return selectedUserEntity?.id ? false : true;
            },
          },
        ],
        hideCallback: () => {
          if (selectedUser?.id) {
            return true;
          }
          return false;
        },
      },
    ],
  };

  // ====================================

  // ============ EVENT STATE ===========
  // ====================================

  // =========== TICKET STATE ===========
  // ====================================

  if (errorUsers) return <div>error</div>;
  if (loadingUsers) return <div>loading</div>;

  return (
    <div id='users'>
      <header>
        <h1 className='font-bold text-2xl'>Gebruikers</h1>
      </header>
      <div className='my-4'>
        <Button
          quaternary
          onClick={() => {
            handleSetUserSelection();
          }}
        >
          {'Nieuw'}{' '}
          <span className='inline ml-1'>
            <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
          </span>
        </Button>
      </div>
      <div>
        <Table
          items={dataUsers ? dataUsers.findManyUsers : []}
          columns={userColumns}
          loading={loadingUsers}
          onSelect={handleSetUserSelection}
          selection={selectedUser}
          lazyLoading={true}
          loadMore={true}
          loadMoreCallback={() => {
            const skip = pagedState.skip + pagedState.take;

            fetchMoreUsers({
              variables: {
                filter: getFilters(searchQuery),
                orderBy: getOrderBy(sortingUsers),
                skip: skip,
                take: pagedState.take,
              },
            });

            setPagedState(prevState => ({
              ...prevState,
              skip: skip,
            }));
          }}
        />
      </div>

      <Modal open={openUserDetail} setOpen={setOpenUserDetail}>
        {openUserDetail === true ? (
          <>
            {!loadingUser && !errorUser && (
              <DetailForm
                schema={userDetailSchema}
                title={'Gebruiker'}
                caption={selectedUser?.id ? 'Bewerk gebruiker' : 'Nieuwe gebruiker'}
              />
            )}
            {loadingUser && (
              <div className='h-5/6 flex items-center'>
                <div className={classNames('sweet-loading text-center w-full')}>
                  <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                </div>
              </div>
            )}
            {errorUser && <p>Error</p>}
          </>
        ) : (
          <div />
        )}
      </Modal>
    </div>
  );
};

export default Users;

// const handleSetUserSelection = async (user?: IUser) => {
//   if (user && user?.id) {
//     const res = await toast.promise(
//       new Promise((resolve, reject) => {
//         if (selectedUser?.id) {
//           getUser({
//             variables: {
//               where: {
//                 id: user?.id,
//               },
//             },
//             onCompleted: data => {
//               resolve(data.findOneUser);
//             },
//             onError: error => {
//               reject(error);
//             },
//           });
//         }
//       }),
//       {
//         pending: {
//           position: DEFAULT_TOAST_POSITION,
//           render() {
//             return DEFAULT_LOADING_MESSAGE;
//           },
//         },
//         error: {
//           position: DEFAULT_TOAST_POSITION,
//           render() {
//             return DEFAULT_ERROR_MESSAGE;
//           },
//           icon: FiAlertCircle,
//         },
//       },
//       {
//         autoClose: DEFAULT_TOAST_DURATION,
//       },
//     );

//     const retrievedUser: IUser = (await res) as any;

//     setSelectedUser(retrievedUser);
//     toastSuccess('Gebruiker succesvol opgehald');
//   } else {
//     setSelectedUser(undefined);
//   }

//   setOpenUserDetail(true);
// };
