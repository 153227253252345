/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react';

type SearchContextValue = {
  permissions: string[];
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>;
};

export const PermissionContext = createContext<SearchContextValue>({
  permissions: [],
  setPermissions: () => {},
});

// Create a provider component for the search context
export const PermissionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  return <PermissionContext.Provider value={{ permissions, setPermissions }}>{children}</PermissionContext.Provider>;
};
