import { gql } from '@apollo/client';
import { IEvent } from './event';

export interface ITicketType {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eventID?: number;
  event?: IEvent;

  price: number;
  stock: number;
  actualStock: number;
  amountSold: number;
  name: string;
  code: string;
  numberAdditionalAttendees?: number;
  hasAdditionalAttendees?: boolean;

  criticalStockLevel?: number;
  availableFrom?: Date;
  availableTill?: Date;
}

export interface ICreateTicketType {
  price?: number;
  stock?: number;
  name?: string;
  code?: string;
  numberAdditionalAttendees?: number;
  hasAdditionalAttendees?: boolean;
}
export interface IUpdateTicketType {
  price?: number;
  stock?: number;
  name?: string;
  code?: string;
  numberAdditionalAttendees?: number;
  hasAdditionalAttendees?: boolean;
}

export const GET_TICKETTYPES = gql`
  query getTicketTypes($filter: TicketTypeFilterInput, $take: Int, $skip: Int, $orderBy: TicketTypeOrderByInput) {
    findManyTicketTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      code

      numberAdditionalAttendees
      hasAdditionalAttendees

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const GET_TICKETTYPE = gql`
  query getTicketType($where: TicketTypeWhereInput) {
    findOneTicketType(where: $where) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      eventID

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      code

      numberAdditionalAttendees
      hasAdditionalAttendees

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const CREATE_TICKETTYPE = gql`
  mutation createTicketType($data: TicketTypeCreateInput!) {
    createTicketType(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      code

      numberAdditionalAttendees
      hasAdditionalAttendees

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const UPDATE_TICKETTYPE = gql`
  mutation updateTicketType($id: Int!, $data: TicketTypeUpdateInput) {
    updateTicketType(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      code

      numberAdditionalAttendees
      hasAdditionalAttendees

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const DELETE_TICKETTYPE = gql`
  mutation deleteTicketType($where: TicketTypeWhereInput) {
    deleteTicketType(where: $where) {
      id
    }
  }
`;
