/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { selectLimit } from 'async';
import { forEach } from 'lodash';
import { classNames } from '../../utils';
import { IFormatedRole, IRole } from '../../types';
import { Input } from './input';

interface Props {
  roles: IRole[];
  userRoles: IRole[];
  callback: any;
  name: string;
}

const RolesComponent: React.FC<Props> = ({ roles, userRoles, callback, name }) => {
  const checkIfHasRole = (role: IRole) => {
    for (let i = 0; i < userRoles.length; i++) {
      if (userRoles[i].key === role.key) {
        return true;
      }
    }

    return false;
  };

  const deformatRoles = (roles: IFormatedRole[]) => {
    const deformattedRoles: IRole[] = [];

    for (let i = 0; i < roles.length; i++) {
      if (roles[i].checked) {
        deformattedRoles.push({
          key: roles[i].key,
          name: roles[i].name,
          description: roles[i].description,
        });
      }
    }

    return deformattedRoles;
  };

  const initRoleState = () => {
    const formattedRoles: IFormatedRole[] = [];

    for (let i = 0; i < roles.length; i++) {
      formattedRoles.push({
        ...roles[i],
        checked: checkIfHasRole(roles[i]),
      });
    }

    return formattedRoles;
  };

  const [formattedRoles, setFormattedRoles] = useState<IFormatedRole[]>(initRoleState());

  const handleOnRoleCheckchange = (index: number) => {
    const updatedRoles = [...formattedRoles];

    updatedRoles[index] = { ...updatedRoles[index], checked: !updatedRoles[index].checked };

    setFormattedRoles(updatedRoles);
    callback(name, deformatRoles(updatedRoles));
  };

  return (
    <>
      <fieldset>
        <legend className='sr-only'>Applicatie Rollen</legend>
        <div className='flex flex-row justify-between items-stretch '>
          {formattedRoles.map((formattedRole, index) => (
            <div key={formattedRole.key} className='items-start flex flex-1'>
              <div className='flex h-6 items-center'>
                <input
                  id={formattedRole.key}
                  aria-describedby='comments-description'
                  name={formattedRole.key}
                  type='checkbox'
                  checked={formattedRole.checked}
                  onChange={() => handleOnRoleCheckchange(index)}
                  className='h-4 w-4 rounded border-cego-lightbrown text-cego-black focus:ring-cego-black cursor-pointer'
                />
              </div>
              <div className='ml-3 text-sm leading-6'>
                <label htmlFor={formattedRole.key} className='font-medium text-gray-900 cursor-pointer'>
                  {formattedRole.name}
                </label>
                <p id='comments-description' className='text-cego-darkgray'>
                  {formattedRole.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </>
  );
};

export { RolesComponent };
