/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { selectLimit } from 'async';
import { useDrag, useDrop } from 'react-dnd';
import { TrashIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utils';
import { Input } from './input';

interface Props {
  list: string[];
  callback: any;
  name: string;
}

const StringList: React.FC<Props> = ({ list, callback, name }) => {
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
  const [items, setItems] = useState(list);

  const handleOnListItemClickX = (index: number) => {
    if (index !== selectedItem) {
      const newList = list.filter((n: any) => n);
      setSelectedItem(index);
      callback(name, [...newList]);
      console.log(name);
    }
  };

  const handleUnListItemClickX = () => {
    setSelectedItem(undefined);
  };

  const handleOnNewListItemClickX = () => {
    const newList = list.filter(n => n);
    newList.push('');
    callback(name, [...newList]);
    setSelectedItem(newList.length - 1);
  };

  const handleOnListItemChangeX = (index: number, value: string) => {
    const updatedItems = [...list];
    updatedItems[index] = value;
    callback(name, [...updatedItems]);
  };

  const handleOnRemoveListItemClickX = (index: number) => {
    const newList = [...list];
    newList.splice(index, 1);
    callback(name, [...newList]);
    setSelectedItem(undefined);
  };

  const handleOnListItemClick = (index: number) => {
    if (index !== selectedItem) {
      setSelectedItem(index);
      console.log('click');
      callback(name, [...items]);
    }
  };

  const handleOnListItemUnClick = () => {
    setSelectedItem(undefined);
    console.log('click2');
    callback(name, [...items]);
  };

  const handleOnNewListItemClick = () => {
    const newList = [...items, ''];
    setItems(newList);
    callback(name, newList);
    setSelectedItem(newList.length - 1);
  };

  const handleOnListItemChange = (index: number, value: string) => {
    const updatedItems = [...items];
    updatedItems[index] = value;
    setItems(updatedItems);
    callback(name, updatedItems);
  };

  const handleOnRemoveListItemClick = (index: number) => {
    const newList = [...items];
    newList.splice(index, 1);
    setItems(newList);
    callback(name, newList);
    setSelectedItem(undefined);
  };

  const moveItem = (dragId: number, hoverId: number) => {
    const dragIndex = items.findIndex((_, index) => index === dragId);
    const hoverIndex = items.findIndex((_, index) => index === hoverId);

    if (dragIndex < 0 || hoverIndex < 0) {
      return;
    }

    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);

    setItems(newItems);
  };

  const DraggableItem: React.FC<{ id: any; text: any; moveItem: any; index: any; listitem: string }> = ({
    id,
    text,
    moveItem,
    index,
    listitem,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, dragRef] = useDrag({
      type: 'item',
      item: { id },
    });

    const [, dropRef] = useDrop({
      accept: 'item',
      hover(item: any, monitor: any) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.id;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveItem(dragIndex, hoverIndex);

        item.id = hoverIndex;
      },
    });

    dragRef(dropRef(ref));

    return (
      <li key={index} ref={ref as any} onClick={() => handleOnListItemClick(index)}>
        <span className={classNames('block hover:bg-cego-offwhite', index === selectedItem && 'bg-cego-offwhite')}>
          <div className='flex relative items-center font-bold px-4 py-4 font- sm:px-6'>
            <div className='flex min-w-0 flex-1 items-center'>
              <div className={classNames('min-w-0 flex-1', index === selectedItem ? 'px-4' : 'px-4')}>
                <div>
                  {index === selectedItem ? (
                    <>
                      <XMarkIcon
                        className='w-4 h-4 absolute left-3.5 top-8 hover:cursor-pointer text-cego-darkgray hover:text-cego-lightbrown'
                        onClick={() => handleOnListItemUnClick()}
                      />
                      <Input
                        type={'text'}
                        id={index + listitem}
                        value={listitem}
                        onChange={e => handleOnListItemChange(index, e.target.value)}
                      />
                      <TrashIcon
                        className='w-4 h-4 absolute right-3.5 top-8 hover:cursor-pointer text-cego-darkgray hover:text-cego-lightbrown'
                        onClick={() => handleOnRemoveListItemClick(index)}
                      />
                    </>
                  ) : (
                    <p className='text-sm text-gray-900'>{listitem}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </span>
      </li>
    );
  };

  // const handleOnListItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  // const newList = [...stringList];
  // newList[selectedItem] = e.target.value;
  // setStringList(newList);
  // callback(newList);
  // };

  return selectedItem !== undefined ? (
    <>
      <div className='overflow-hidden bg-white shadow-cego-lightbrown border border-cegolightbrown rounded-sm'>
        <ul role='list' className='divide-y divide-cego-lightbrown'>
          {list.map((listitem, index) => (
            <li key={index} onClick={() => handleOnListItemClickX(index)}>
              <span className={classNames('block hover:bg-cego-offwhite', index === selectedItem && 'bg-cego-offwhite')}>
                <div className='flex relative items-center font-bold px-4 py-4 font- sm:px-6'>
                  <div className='flex min-w-0 flex-1 items-center'>
                    <div className={classNames('min-w-0 flex-1', index === selectedItem ? 'px-4' : 'px-4')}>
                      <div>
                        {index === selectedItem ? (
                          <>
                            <XMarkIcon
                              className='w-4 h-4 absolute left-3.5 top-8 hover:cursor-pointer text-cego-darkgray hover:text-cego-lightbrown'
                              onClick={() => handleOnListItemUnClick()}
                            />
                            <Input
                              type={'text'}
                              id={index + listitem}
                              value={listitem}
                              onChange={e => handleOnListItemChange(index, e.target.value)}
                            />
                            <TrashIcon
                              className='w-4 h-4 absolute right-3.5 top-8 hover:cursor-pointer text-cego-darkgray hover:text-cego-lightbrown'
                              onClick={() => handleOnRemoveListItemClick(index)}
                            />
                          </>
                        ) : (
                          <p className='text-sm text-gray-900'>{listitem}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <p
        className='hover:cursor-pointer underline text-cego-darkgray pt-1 text-sm font-normal hover:text-cego-lightbrown mb-1'
        onClick={() => handleOnNewListItemClick()}
      >
        Nieuw item
      </p>
    </>
  ) : (
    <>
      <div className='overflow-hidden bg-white shadow-cego-lightbrown border border-cegolightbrown rounded-sm'>
        <ul role='list' className='divide-y divide-cego-lightbrown'>
          {items.map((listitem, index) => (
            <DraggableItem key={listitem || index} id={index} text={listitem} moveItem={moveItem} index={index} listitem={listitem} />
          ))}
        </ul>
      </div>
      <p
        className='hover:cursor-pointer underline text-cego-darkgray pt-1 text-sm font-normal hover:text-cego-lightbrown mb-1'
        onClick={() => handleOnNewListItemClick()}
      >
        Nieuw item
      </p>
    </>
  );
};

export { StringList };
