import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { classNames } from '../utils';
import { DetailField } from './detailForm';
import { Input } from './tailwind/input';
import { Combobox } from './tailwind/comboBox';
import { Switch } from './tailwind/switch';

interface Props {
  schema: InlineFilter;
  filterState: InlineFilter;
  setFilterState: any;
}

export interface InlineFilter {
  id: string;
  fields: DetailField[];
}

export const FilterField: React.FC<any> = ({ field, isSubmit, onChangeFieldValue, getFieldValue }) => {
  const [isTimeOut, setIsTimeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeOut(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {((field.hideCallback && field.hideCallback()) || !field.hideCallback) && (
        <div className='py-2.5' style={{ width: `${field.width}%` }}>
          <label
            htmlFor={field.id}
            className={classNames(
              isSubmit && field.validateCallback && !field.validateCallback(field.value).valid ? 'text-cego-red' : 'text-cego-darkgray',
              'block ml-0.5 mb-0.5 text-xs font-medium leading-6',
            )}
          >
            {field.label && field.label.length > 0 ? field.label : ''}
            {field.required && <span>*</span>}{' '}
            {isSubmit && field.validateCallback && !field.validateCallback(field.value).valid && isTimeOut && (
              <span>{`(${field.validateCallback(field.value).message})`}</span>
            )}
          </label>
          <div>
            {/* TEXT/NUMBER INPUT */}
            {(field.type === 'text' || field.type === 'number') && (
              <Input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={getFieldValue(field)}
                onChange={(e: any) => onChangeFieldValue(field, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {/* DATE INPUT */}
            {field.type === 'datetime-local' && (
              <Input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={moment(getFieldValue(field)).format('YYYY-MM-DD')}
                onChange={(e: any) => onChangeFieldValue(field, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {field.type === 'combobox' && field.combobox && (
              <>
                <Combobox
                  items={field.combobox.items ? field.combobox.items : []}
                  selected={field.value}
                  setSelected={e => onChangeFieldValue(field, e)}
                  placeholder='Selecteer'
                />
              </>
            )}

            {field.type === 'date' && (
              <>
                <Input
                  type={field.type}
                  id={field.id}
                  placeholder={field.placeholder}
                  value={moment(getFieldValue(field)).format('YYYY-MM-DD')}
                  onInput={(e: any) => {
                    onChangeFieldValue(field, e.target.value);
                  }}
                  disabled={field.disableCallback ? field.disableCallback() : false}
                />
              </>
            )}

            {field.type === 'select' && field.selectbox && (
              <>
                <select
                  id={field.id}
                  name={field.name}
                  value={getFieldValue(field)}
                  className={
                    'capitalize block w-full shadow-sm focus:ring-cego-lightbrown focus:border-cego-lightbrown font-light text-base border-cego-lightbrown rounded-sm py-2.5'
                  }
                  onChange={e => onChangeFieldValue(field, e.target.value)}
                >
                  <option value='Alles'>Alles</option>
                  {field.selectbox.items?.split(',').map((item: any, index: number) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </>
            )}
            {field.type === 'select-custom' && field.selectbox && (
              <>
                <select
                  id={field.id}
                  name={field.name}
                  value={getFieldValue(field)}
                  className={
                    'capitalize block w-full shadow-sm focus:ring-cego-lightbrown focus:border-cego-lightbrown font-light text-base border-cego-lightbrown rounded-sm py-2.5'
                  }
                  onChange={e => onChangeFieldValue(field, e.target.value)}
                >
                  <option value='Huidige vormingen'>Huidige vormingen</option>
                  {field.selectbox.items?.split(',').map((item: any, index: number) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </>
            )}

            {/* SWITCH INPUT */}
            {field.type === 'switch' && (
              <>
                <Switch
                  checked={getFieldValue(field) === 'true' ? true : false}
                  onChange={(e: any) => {
                    onChangeFieldValue(field, e);
                  }}
                  disabled={field.disableCallback && !field.disableCallback()}
                />
              </>
            )}
          </div>
          {field.caption && <p className='ml-0.5 mt-1 text-xs font-light leading-6 text-cego-darkgray'>{field.caption}</p>}
        </div>
      )}
    </>
  );
};

export const initFilterPanelState = (filter: InlineFilter) => {
  const newFields: any[] = [];

  const getParsedValue = (field: any, value: any) => {
    const prismaFilterObj: any = {
      [field.name]: field.callback(value),
    };

    return prismaFilterObj;
  };

  for (let t = 0; t < filter.fields.length; t++) {
    newFields.push({
      id: filter.fields[t].id,
      name: filter.fields[t].name,
      value: filter.fields[t].value ? filter.fields[t].value : '',
      parsedValue: filter.fields[t].value && filter.fields[t].name ? getParsedValue(filter.fields[t], filter.fields[t].value) : '',
    });
  }

  const newFilterState: InlineFilter = {
    id: filter.id,
    fields: newFields,
  };

  return newFilterState;
};

export const InlineFilterForm: React.FC<Props> = ({ schema, filterState, setFilterState }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [filter, setFilter] = useState(filterState);

  const getParsedValue = (field: any, value: any) => {
    const prismaFilterObj: any = {
      [field.name]: field.callback(value),
    };

    return prismaFilterObj;
  };

  const onChangeFieldValue = (field: any, value: any) => {
    setFilterState((prevState: any) => {
      let newState = _.cloneDeep(prevState);

      if (newState && newState.fields) {
        const fieldIndex = newState.fields.findIndex((item: any) => item.id === field.id);

        if (fieldIndex === -1) {
          const fieldList: any[] = newState.fields;
          fieldList.push({
            id: field.id,
            name: field.name,
            value,
            parsedValue: getParsedValue(field, value),
          });

          newState = {
            ...newState,
            fields: fieldList,
          };
        }

        if (fieldIndex > -1) {
          const fieldList: any[] = newState.fields;
          fieldList[fieldIndex] = {
            ...newState.fields[fieldIndex],
            value,
            parsedValue: getParsedValue(field, value),
          };

          newState = {
            ...newState,
            fields: fieldList,
          };
        }

        return newState;
      }
    });

    setIsSubmit(false);
  };

  const getFieldValue = (field: any) => {
    if (filterState && filterState.fields) {
      const fieldIndex = filterState?.fields.findIndex(item => item.id === field.id);
      if (fieldIndex !== -1) {
        return filterState?.fields[fieldIndex].value.toString();
      }
    }

    return '';
  };

  return (
    <>
      <form>
        <div className='flex flex-wrap justify-start bg-cego-offwhite px-4 pb-4 rounded-sm mt-2 space-x-2'>
          {schema.fields.map((field: DetailField) => (
            <>
              <FilterField
                key={field.id}
                field={field}
                isSubmit={isSubmit}
                schema={schema as any}
                onChangeFieldValue={onChangeFieldValue}
                getFieldValue={getFieldValue}
              />
            </>
          ))}
        </div>
      </form>
    </>
  );
};

export default InlineFilterForm;
