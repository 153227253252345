/* eslint-disable @next/next/no-img-element */
import { Outlet, Link, useLocation } from 'react-router-dom';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  HomeIcon,
  UsersIcon,
  InboxArrowDownIcon,
  XMarkIcon,
  CalendarDaysIcon,
  TicketIcon,
  UserGroupIcon,
  CreditCardIcon,
  BanknotesIcon,
  WalletIcon,
  IdentificationIcon,
  RectangleStackIcon,
  MapPinIcon,
  ChartBarIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { CEGO_LOGO, classNames, hasPermission } from '../utils';
import { SearchContext } from '../context/searchContext';
import useDebounce from '../components/tailwind/hooks/useDebounce';
import 'react-toastify/dist/ReactToastify.css';
import { PermissionContext } from '../context/permissionContext';

const env = process.env.REACT_APP_ENV;

const prod_navigation = [
  { name: 'Dashboard', href: '', icon: HomeIcon, current: true },
  {
    name: 'Vormingen',
    href: 'vormingen',
    icon: CalendarDaysIcon,
    current: true,
    permission: 'nav:vormingen',
  },
  {
    name: 'Evenementen',
    href: 'events',
    icon: CalendarDaysIcon,
    current: true,
    permission: 'nav:events',
  },
  { name: 'Bestellingen', href: 'bestellingen', icon: TicketIcon, current: false, permission: 'nav:bestellingen' },
  { name: 'Waardebonnen', href: 'coupons', icon: TicketIcon, current: false, permission: 'nav:gebruikers' }, // admin rights
  { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false, permission: 'nav:tickets' },
  { name: 'Aanvragen', href: 'aanvragen', icon: InboxArrowDownIcon, current: false, permission: 'nav:aanvragen' },
  { name: 'Kandidaturen', href: 'candidates', icon: ClipboardDocumentIcon, current: false, permission: 'nav:aanvragen' },
  { name: 'Producten', href: 'producten', icon: RectangleStackIcon, current: false, permission: 'nav:producten' },
  { name: 'Rekeningen', href: 'rekeningen', icon: CreditCardIcon, current: false, permission: 'nav:producten' },
  { name: 'Locaties', href: 'locaties', icon: MapPinIcon, current: false, permission: 'nav:locaties' },
  { name: 'Klanten', href: 'klanten', icon: UserGroupIcon, current: false, permission: 'nav:klanten' },
  { name: 'Factuur klanten', href: 'factuurklanten', icon: IdentificationIcon, current: false, permission: 'nav:klanten' },
  { name: 'Gebruikers', href: 'gebruikers', icon: UsersIcon, current: false, permission: 'nav:gebruikers' },
  { name: 'Prestaties', href: 'prestaties', icon: ChartBarIcon, current: false, permission: 'nav:aanvragen' },
];

const dev_navigation = [
  { name: 'Dashboard', href: '', icon: HomeIcon, current: true },
  {
    name: 'Vormingen',
    href: 'vormingen',
    icon: CalendarDaysIcon,
    current: true,
    permission: 'nav:vormingen',
  },
  {
    name: 'Evenementen',
    href: 'events',
    icon: CalendarDaysIcon,
    current: true,
    permission: 'nav:evenementen',
  },
  { name: 'Bestellingen', href: 'bestellingen', icon: TicketIcon, current: false, permission: 'nav:bestellingen' },
  { name: 'Waardebonnen', href: 'coupons', icon: TicketIcon, current: false, permission: 'nav:gebruikers' }, // admin rights
  { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false, permission: 'nav:tickets' },
  { name: 'Aanvragen', href: 'aanvragen', icon: InboxArrowDownIcon, current: false, permission: 'nav:aanvragen' },
  { name: 'Kandidaturen', href: 'candidates', icon: ClipboardDocumentIcon, current: false, permission: 'nav:aanvragen' },
  { name: 'Producten', href: 'producten', icon: RectangleStackIcon, current: false, permission: 'nav:producten' },
  { name: 'Rekeningen', href: 'rekeningen', icon: CreditCardIcon, current: false, permission: 'nav:producten' },
  { name: 'Locaties', href: 'locaties', icon: MapPinIcon, current: false, permission: 'nav:locaties' },
  { name: 'Klanten', href: 'klanten', icon: UserGroupIcon, current: false, permission: 'nav:klanten' },
  { name: 'Factuur klanten', href: 'factuurklanten', icon: IdentificationIcon, current: false, permission: 'nav:klanten' },
  { name: 'Gebruikers', href: 'gebruikers', icon: UsersIcon, current: false, permission: 'nav:gebruikers' },
  { name: 'Prestaties', href: 'prestaties', icon: ChartBarIcon, current: false, permission: 'nav:aanvragen' },
];
const userNavigation: any[] = [];

export default function Root() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const { permissions, setPermissions } = useContext(PermissionContext);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const debouncedValue = useDebounce(searchString, 500);
  const path = useLocation();

  useEffect(() => {
    setSearchQuery(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setSearchString('');
  }, [path]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4 rounded-md'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2 rounded-sm'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-shrink-0 items-center px-4'>
                    <img className='h-11 w-auto' src={CEGO_LOGO} alt='Cego logo' />
                  </div>
                  <div className='mt-8 h-0 flex-1 overflow-y-auto'>
                    <nav className='space-y-3 px-2'>
                      {(env === 'production' || env === 'development' ? dev_navigation : prod_navigation).map((item, index) => (
                        <>
                          {item.permission && hasPermission(permissions, item.permission) ? (
                            <Link
                              key={index}
                              to={item.href}
                              className={classNames(
                                path.pathname === `/${item.href}`
                                  ? 'bg-cego-lightgray text-cego-black'
                                  : 'text-cego-black hover:bg-cego-offwhite hover:text-cego-darkgray',
                                'group flex items-center rounded-sm px-2 py-3 text-md font-medium',
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  path.pathname === `/${item.href}` ? 'text-cego-black' : 'text-cego-black group-hover:text-cego-darkgray',
                                  'mr-4 h-6 w-6 flex-shrink-0 stroke-2',
                                )}
                                aria-hidden='true'
                              />
                              {item.name}
                            </Link>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col z-20'>
          {/* Sidebar component, swap this element with another sidebar if you like BORDER- R*/}
          <div className='flex flex-grow flex-col overflow-y-auto bg-white pt-5'>
            <div className='flex flex-shrink-0 items-center px-4'>
              <img className='h-11 w-auto' src={CEGO_LOGO} alt='Cego logo' />
            </div>
            <div className='mt-8 flex flex-grow flex-col'>
              <nav className='flex-1 space-y-3 px-2 pb-4'>
                {(env === 'production' || env === 'development' ? dev_navigation : prod_navigation).map((item, index) => (
                  <>
                    {item.permission && hasPermission(permissions, item.permission) ? (
                      <Link
                        key={index}
                        to={item.href}
                        className={classNames(
                          path.pathname === `/${item.href}`
                            ? 'bg-cego-lightgray text-cego-black'
                            : 'text-cego-black hover:bg-cego-offwhite hover:text-cego-darkgray',
                          'group flex items-center rounded-sm px-2 py-3 text-md font-medium',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            path.pathname === `/${item.href}` ? 'text-cego-black' : 'text-cego-black group-hover:text-cego-darkgray',
                            'mr-3 h-6 w-6 flex-shrink-0 stroke-2',
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className='flex flex-1 flex-col lg:pl-64 z-0'>
          <div className='sticky top-0 z-10 flex h-20 flex-shrink-0 bg-white shadow-bottom'>
            <button type='button' className='pr-12 sm:pr-14 px-4 lg:px-0 lg:hidden animate' onClick={() => setSidebarOpen(true)}>
              <span className='sr-only'>Open sidebar</span>
              <div className='pointer-events-none absolute inset-y-0 left-4 sm:left-6 flex items-center hover:animate-spin'>
                <span className='bg-cego-offwhite p-3 rounded-full hover:animate-spin'>
                  <Bars3BottomLeftIcon className='stroke-2 h-7 w-7 text-cego-black font-bold text-3xl pl-[2px] pr-[2.5px] pt-[1px] hover:animate-spin' />
                </span>
              </div>
            </button>
            <div className='flex flex-1 justify-between px-4'>
              <div className='flex flex-1'>
                <form className='flex w-full lg:ml-0' action='#' method='GET'>
                  <label htmlFor='search-field' className='sr-only'>
                    Zoeken
                  </label>
                  <div className='relative w-full text-gray-400 focus-within:text-gray-600 cursor-pointer'>
                    <div className='cursor-pointer absolute inset-y-0 left-0 flex items-center'>
                      <span className='sr:bg-cego-orange bg-cego-white lg:bg-cego-white p-3 rounded-full cursor-pointer'>
                        <FontAwesomeIcon
                          icon={faSearch}
                          className='h-5 w-5 text-cego-black font-extrabold text-2xl pl-[2px] pr-[2.5px] pt-[1px] hover:animate-pulse'
                        />
                      </span>
                    </div>
                    <input
                      id='search-field'
                      className='block h-full w-full border-transparent py-2 pl-14 ml-1 pr-3 text-cego-black focus:border-transparent focus:outline-none focus:ring-0 focus:placeholder:text-gray-400'
                      placeholder='Zoeken'
                      type='search'
                      name='search'
                      value={searchString}
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              </div>
              <div className='ml-4 flex items-center lg:ml-6 mr-2 lg:mr-4'>
                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex max-w-xs items-center rounded-full bg-white text-sm'>
                      <span className='sr-only'>Open user menu</span>
                      {isAuthenticated && user && user.picture ? (
                        <span className='bg-cego-offwhite rounded-full'>
                          <img src={user.picture} alt={'profile picture'} className='w-12 h-12 rounded-full'></img>
                        </span>
                      ) : (
                        <span className='bg-cego-offwhite p-3 rounded-full'>
                          <FontAwesomeIcon
                            icon={faUser}
                            className='h-5 w-5 text-cego-black font-extrabold text-2xl pl-[2px] pr-[2.5px] pt-[1px] hover:animate-pulse'
                          />
                        </span>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-cego-black')}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}

                      {isAuthenticated ? (
                        <Menu.Item key={'logout'}>
                          {({ active }) => (
                            <span
                              onClick={() => logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI } })}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-cego-black rounded-sm')}
                            >
                              {'Logout'}
                            </span>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item key={'login'}>
                          {({ active }) => (
                            <span
                              onClick={() => loginWithRedirect()}
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-cego-black')}
                            >
                              {'Login'}
                            </span>
                          )}
                        </Menu.Item>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className='flex-1'>
            <div className='pt-4'>
              <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
