import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IProposal } from './proposal';

export interface IDirectInvoice {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eenvouwdigFacturerenID?: number;
  eenvoudigFacturerenInvoice?: any;

  proposal: IProposal;
  proposalID: number;

  description?: string;
  date?: string; // datetime
  price?: number;
  vat?: number;
}

export const CREATE_DIRECTINVOICE = gql`
  mutation createDirectInvoice($data: DirectInvoiceCreateInput!) {
    createDirectInvoice(data: $data) {
      id
    }
  }
`;

export const UPDATE_DIRECTINVOICE = gql`
  mutation updateDirectInvoice($id: Int!, $data: DirectInvoiceUpdateInput) {
    updateDirectInvoice(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_DIRECTINVOICE = gql`
  mutation deleteDirectInvoice($where: DirectInvoiceWhereInput) {
    deleteDirectInvoice(where: $where) {
      id
    }
  }
`;

export const SEND_DIRECTINVOICE = gql`
  mutation sendDirectInvoice($id: Int!) {
    sendDirectInvoice(id: $id) {
      id
    }
  }
`;
