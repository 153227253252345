import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';

export interface IMailing {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  type?: string;
  subject?: string;
  body?: string;
  sender?: string;
  recipients?: string[];
  attachments?: string[];
  hasError?: boolean;
  mailingLogs?: IMailingLog[];
}

export interface IMailingLog {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime

  mailingID?: number;
  mailing?: IMailing;

  isResolved?: boolean;
  isError?: boolean;

  recipient?: string;
  sender?: string;
  resendResponse?: string;
}

// full object for detail page
export const GET_MAILING = gql`
  query getMailing($where: MailingWhereInput) {
    findOneMailing(where: $where) {
      createdAt
      updatedAt
      id
      type
      body
      subject
      sender
      attachments
      recipients
      hasError
      mailingLogs {
        id
        createdAt
        updatedAt
        isResolved
        isError
        recipient
        sender
        resendResponse
      }
    }
  }
`;

export const CLOSE_MAILINGLOG = gql`
  mutation closeMailingLog($id: Int!) {
    closeMailingLog(id: $id) {
      id
      createdAt
      updatedAt
      isResolved
      isError
      recipient
      sender
      resendResponse
    }
  }
`;
