import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IEntitiy } from './entity';

export interface ITargetAudienceType {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  title?: string;
}

export const GET_TARGET_AUDIENCE_TYPES = gql`
  query getTargetAudienceTypes($filter: TargetAudienceTypeFilterInput, $take: Int, $skip: Int, $orderBy: TargetAudienceTypeOrderByInput) {
    findManyTargetAudienceTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      title
    }
  }
`;

export const convertTargetAudienceTypesToComboBoxOptions = (targetAudienceTypes: ITargetAudienceType[]) => {
  if (targetAudienceTypes) {
    const result: IComboBoxOption[] = targetAudienceTypes.map(
      (targetAudienceType: ITargetAudienceType): IComboBoxOption => ({
        id: targetAudienceType.id as any,
        name: `${targetAudienceType.title}`,
        active: true,
      }),
    );
    return result;
  }

  return [];
};
