import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Textarea: React.FC<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>> = ({
  className,
  ...props
}) => {
  return (
    <textarea
      className={classNames(
        'shadow-sm block w-full focus:ring-cego-lightbrown focus:border-cego-lightbrown font-light text-base border border-cego-lightbrown rounded-sm',
        className || '',
      )}
      {...props}
    />
  );
};

export { Textarea };
