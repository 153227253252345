import { gql } from '@apollo/client';

export const CREATE_CANDIDATE = gql`
  mutation createCandidate($data: CandidateCreateInput!) {
    createCandidate(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_CANDIDATE = gql`
  mutation deleteCandidate($where: CandidateWhereInput) {
    deleteCandidate(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
