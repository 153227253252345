import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IEntitiy } from './entity';

export interface IThemeType {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  title?: string;
}

export const GET_THEME_TYPES = gql`
  query getThemeTypes($filter: ThemeTypeFilterInput, $take: Int, $skip: Int, $orderBy: ThemeTypeOrderByInput) {
    findManyThemeTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      title
    }
  }
`;

export const convertThemeTypesToComboBoxOptions = (themeTypes: IThemeType[]) => {
  if (themeTypes) {
    const result: IComboBoxOption[] = themeTypes.map(
      (themeType: IThemeType): IComboBoxOption => ({
        id: themeType.id as any,
        name: `${themeType.title}`,
        active: true,
      }),
    );
    return result;
  }

  return [];
};
