import { Dialog } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Button from './tailwind/button';

interface Props {
  title: string;
  text: string;
  handleDelete: () => void;
  setIsOpenDelete: (isOpen: boolean) => void;
}

export const DeleteForm: React.FC<Props> = ({ title, text, handleDelete, setIsOpenDelete }) => {
  return (
    <div className='self-center px-8'>
      <div className='sm:flex sm:items-start mb-6'>
        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
          <ExclamationTriangleIcon className='h-6 w-6 text-cego-red' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <Dialog.Title as='h3' className='text-lg font-semibold leading-6 mb-4 text-gray-900'>
            {title}
          </Dialog.Title>
          <div className='mt-2'>
            <p className='text-base text-cego-darkgray'>{text}</p>
          </div>
        </div>
      </div>

      <div className='flex justify-end space-x-2'>
        <Button
          tertiary
          onClick={() => {
            setIsOpenDelete(false);
          }}
        >
          {'Annuleren'}{' '}
        </Button>

        <Button
          secondary
          onClick={() => {
            handleDelete();
          }}
        >
          {'Verwijderen'}{' '}
          <span className='inline ml-1'>
            <TrashIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
          </span>
        </Button>
      </div>
    </div>
  );
};
