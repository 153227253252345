import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { PaperAirplaneIcon, PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Link } from 'react-router-dom';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  filterComboBoxItem,
  resolveMoney,
  resolveValueForCombobox,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import Button from '../../components/tailwind/button';
import { toastError, toastSuccess } from '../../utils/toast';
import { GET_TICKET, GET_TICKETS, ITicket } from '../../utils/ticket';
import { GET_ACCOUNTS, convertAccountsToComboBoxOptions } from '../../utils/account';
import { FullScreenModal } from '../../components/tailwind/fullscreenmodal';

export const Tickets: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  const [selectedTicket, setSelectedTicket] = useState<ITicket | undefined>();
  const [selectedTicketTab, setSelectedTicketTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingTickets, setSortingTickets] = useState<ISorting>({
    key: 'createdAt',
    direction: 'desc',
  });
  const [openTicketDetail, setOpenTicketDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openTicketCreate, setOpenTicketCreate] = useState(DEFAULT_DETAIL_OPEN);
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery]);

  const TicketColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Deelnemers',
      fieldName: 'Ticket.name',
      render: (item: ITicket) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.attendee.firstName} {item?.attendee.lastName}
            {item?.attendee?.attendees?.length > 0
              ? item?.attendee?.attendees.map((person: any) => `, ${person.firstName} ${person.lastName}`)
              : ''}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Status',
      fieldName: 'Ticket.status',
      render: (item: ITicket) => {
        let color = '#ff8e3b';
        const string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer`;
        let text = item.status;

        if (item?.status === 'BOOKED') {
          color = '#20A558';
          text = 'Toegewezen';
        }

        if (item?.status === 'CANCELED') {
          color = '#FF4536';
          text = 'Geannuleerd';
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span className={string} style={{ backgroundColor: color }}>
              <span className={item?.status === 'BOOKED' ? 'pr-1 text-center' : ''}>{text}</span>
            </span>
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Bestelling',
      fieldName: 'Ticket.order.id',
      render: (item: ITicket) => {
        return <span className={classNames('text-ellipsis text-base overflow-hidden block w-46')}>#{item?.order.id}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Klant',
      fieldName: 'Ticket.order.customer.institution',
      render: (item: ITicket) => {
        return <span className={classNames('text-ellipsis text-base overflow-hidden block w-46')}>{item?.order.customer.institution}</span>;
      },
    },
    {
      key: 'column5',
      name: 'Vorming',
      fieldName: 'Ticket.vatPercentage',
      render: (item: ITicket) => {
        return <span className={classNames('text-ellipsis overflow-hidden block w-18 text-base')}>{item?.event?.activity?.title}</span>;
      },
    },
  ];

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          {
            order: {
              customer: {
                institution: { contains: filterArray[i] },
              },
            },
          },
          {
            event: {
              activity: {
                title: { contains: filterArray[i] },
              },
            },
          },
          {
            status: { contains: filterArray[i] },
          },
          {
            attendee: {
              lastName: { contains: filterArray[i] },
            },
          },
          {
            attendee: {
              firstName: { contains: filterArray[i] },
            },
          },
          {
            attendee: {
              email: { contains: filterArray[i] },
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const getOrderBy = useCallback(
    (sortingOrders: any) => {
      return { [sortingOrders.key]: sortingOrders.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingTickets],
  );

  const {
    data: dataTickets,
    loading: loadingTickets,
    error: errorTickets,
    refetch: refetchTickets,
    fetchMore: fetchMoreTickets,
  } = useQuery(GET_TICKETS, {
    variables: {
      filter: getFilters(searchQuery),
      orderBy: getOrderBy(sortingTickets),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getTicket, { loading: loadingTicket, error: errorTicket, refetch: refetchTicket }] = useLazyQuery(GET_TICKET);

  const handleSetTicketSelection = (Ticket?: ITicket) => {
    if (Ticket?.id) {
      getTicket({
        variables: {
          where: {
            id: Ticket?.id,
          },
        },
        onCompleted: data => {
          setSelectedTicket(data.findOneTicket);
          setOpenTicketDetail(true);
        },
      });
    } else {
      setSelectedTicket(undefined);
    }

    setOpenTicketDetail(true);
  };

  const TicketDetailSchema: DetailSchema = {
    id: 'ticket_detail',
    object: selectedTicket,
    permission: '',
    selectedTab: selectedTicketTab,
    setSelectedTab: setSelectedTicketTab,
    sections: [
      {
        id: 'ticket_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'Ticket_detail_section_0_field_0',
            label: 'Voornaam',
            name: 'name',
            required: true,
            value: selectedTicket?.attendee.firstName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              return value;
            },
          },
          {
            id: 'Ticket_detail_section_0_field_1',
            label: 'Achternaam',
            name: 'name',
            required: true,
            value: selectedTicket?.attendee.lastName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              return value;
            },
          },
          {
            id: 'Ticket_detail_section_0_field_2',
            label: 'Email',
            name: 'name',
            required: true,
            value: selectedTicket?.attendee.email,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              return value;
            },
          },
          {
            id: 'Ticket_detail_section_0_field_3',
            label: 'Telefoon',
            name: 'name',
            required: true,
            value: selectedTicket?.attendee.phone,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              return value;
            },
          },
          {
            id: 'Ticket_detail_section_0_field_EXTRA',
            label: '',
            name: 'attendees',
            required: false,
            value: selectedTicket?.attendee?.attendees ? selectedTicket?.attendee?.attendees : [],
            placeholder: '',
            type: 'extraAttendees',
            width: 100,
            callback: (name: string, value: string) => {
              return;
            },
          },
          {
            id: 'Ticket_detail_section_0_field_4',
            label: '',
            name: 'link-button',
            value: selectedTicket?.orderID,
            placeholder: '',
            type: 'link-button',
            linkbutton: {
              text: 'Ga naar bestelling',
              link: `/bestellingen/${selectedTicket?.orderID}`,
            },
            width: 48.5,
            callback: (name: string, value: string) => {
              return value;
            },
          },
        ],
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Ticketen</h1>
      </header>
      <div>
        {errorTickets ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingTickets ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataTickets ? dataTickets.findManyTickets : []}
                columns={TicketColumns}
                loading={loadingTickets}
                onSelect={handleSetTicketSelection}
                selection={selectedTicket}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  const skip = pagedState.skip + pagedState.take;
                  fetchMoreTickets({
                    variables: {
                      filter: getFilters(searchQuery),
                      orderBy: getOrderBy(sortingTickets),
                      skip: skip,
                      take: pagedState.take,
                    },
                  });

                  setPagedState(prevState => ({
                    ...prevState,
                    skip: skip,
                  }));
                }}
              />
            </div>
            <Modal open={openTicketDetail} setOpen={setOpenTicketDetail}>
              <>
                {openTicketDetail === true ? (
                  <>
                    {!loadingTicket && !errorTicket && (
                      <DetailForm
                        schema={TicketDetailSchema}
                        title={'Ticket'}
                        caption={selectedTicket?.id ? 'Ticket updaten' : 'Ticket toevoegen'}
                      />
                    )}
                    {loadingTicket && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorTicket && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tickets;
