import { gql } from '@apollo/client';
import { IUser } from './user';
import { IEvent } from './event';

export const CREATE_ACTIVITY_TARGETAUDIENCETYPE = gql`
  mutation createActivityTargetAudienceType($data: ActivityTargetAudienceTypeCreateInput!) {
    createActivityTargetAudienceType(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_ACTIVITY_TARGETAUDIENCETYPE = gql`
  mutation deleteActivityTargetAudienceType($where: ActivityTargetAudienceTypeWhereInput) {
    deleteActivityTargetAudienceType(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
