import { gql } from '@apollo/client';
import { IEvent } from './event';
import { ITicketType } from './ticketType';
import { IOrder } from './orders';

export interface ITicket {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eventID?: number;
  event?: IEvent;

  attendeeID?: number;
  attendee?: any;

  order: IOrder;
  orderID: string;

  price?: number;
  status?: string;

  ticketType: ITicketType;
  ticketTypeID: string;
}

export const GET_TICKETS = gql`
  query getTickets($filter: TicketFilterInput, $take: Int, $skip: Int, $orderBy: TicketOrderByInput) {
    findManyTickets(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      status

      ticketType {
        id
        name
        price
        numberAdditionalAttendees
        hasAdditionalAttendees
      }

      order {
        id
        createdAt
        customer {
          institution
        }
      }
      orderID

      attendee {
        id
        firstName
        lastName
        email
        phone
        additionalInformation
        attendees {
          firstName
          lastName
          email
          phone
        }
      }

      eventID
      event {
        id
        activity {
          title
        }
      }
    }
  }
`;

export const GET_TICKET = gql`
  query getTicket($where: TicketWhereInput) {
    findOneTicket(where: $where) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      status

      ticketType {
        id
        name
        price
        numberAdditionalAttendees
        hasAdditionalAttendees
      }
      orderID

      attendee {
        id
        firstName
        lastName
        email
        phone
        additionalInformation
        attendees {
          firstName
          lastName
          email
          phone
        }
      }

      event {
        id
        activity {
          title
        }
      }
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation createTicket($data: TicketCreateInput!) {
    createTicket(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      status

      ticketType {
        id
        name
        price
        numberAdditionalAttendees
        hasAdditionalAttendees
      }
      orderID

      attendee {
        id
        firstName
        lastName
        email
        phone
        additionalInformation
        attendees {
          firstName
          lastName
          email
          phone
        }
      }

      eventID
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation updateTicket($id: Int!, $data: TicketUpdateInput) {
    updateTicket(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      orderID
      price
      status

      ticketType {
        id
        name
        price
        numberAdditionalAttendees
        hasAdditionalAttendees
      }

      attendee {
        id
        firstName
        lastName
        email
        phone
        additionalInformation
        attendees {
          firstName
          lastName
          email
          phone
        }
      }

      eventID
    }
  }
`;

export const DELETE_TICKET = gql`
  mutation deleteTicket($where: TicketWhereInput) {
    deleteTicket(where: $where) {
      id
    }
  }
`;
