import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';

export interface IVenue {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  active?: boolean;
  name?: string;
  address?: string;
  city?: string;
  zipcode?: string;
}

export const GET_VENUES = gql`
  query getVenues($filter: VenueFilterInput, $skip: Int, $take: Int) {
    findManyVenues(filter: $filter, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      active
      name
      address
      city
      zipcode
    }
  }
`;

// full object for detail page
export const GET_VENUE = gql`
  query getVenue($where: VenueWhereInput) {
    findOneVenue(where: $where) {
      createdAt
      updatedAt
      id
      active
      name
      address
      city
      zipcode
    }
  }
`;

export const CREATE_VENUE = gql`
  mutation createVenue($data: VenueCreateInput!) {
    createVenue(data: $data) {
      createdAt
      updatedAt
      id
      active
      name
      address
      city
      zipcode
    }
  }
`;

export const UPDATE_VENUE = gql`
  mutation updateVenue($id: Int!, $data: VenueUpdateInput) {
    updateVenue(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      active
      name
      address
      city
      zipcode
    }
  }
`;

export const DELETE_VENUE = gql`
  mutation deleteVenue($where: VenueWhereInput) {
    deleteVenue(where: $where) {
      id
      name
    }
  }
`;

export const convertVenuesToComboBoxOptions = (venues: IVenue[]) => {
  if (venues) {
    const result: IComboBoxOption[] = venues.map(
      (venue: IVenue): IComboBoxOption => ({
        id: venue.id as any,
        name: `${venue.name}`,
        active: venue.active ? true : false,
      }),
    );
    return [...result, { id: 'selecteer', name: 'selecteer', active: true }];
  }

  return [{ id: 'selecteer' as any, name: 'selecteer', active: true }];
};
