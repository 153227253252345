import { gql } from '@apollo/client';
import { IEvent } from './event';
import { ITicket } from './ticket';

export interface IAttendee {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  additionalInformation?: string;
  eventID: number;
  event?: IEvent;

  active?: boolean;
  hasAttended?: boolean;

  tickets?: ITicket[];
  sessions?: any[];

  attendees: any[];
}

//limited obj for overvieuw table
export const GET_ATTENDEES = gql`
  query getAttendees($filter: AttendeeFilterInput, $skip: Int, $take: Int) {
    findManyAttendees(filter: $filter, skip: $skip, take: $take) {
      id
      firstName
      lastName
      email
      phone
      additionalInformation
      eventID
      active
      hasAttended
      sessions {
        id
        price
        status
        sessionType {
          id
          name
        }
      }
    }
  }
`;

// full object for detail page
export const GET_ATTENDEE = gql`
  query getAttendee($where: AttendeeWhereInput) {
    findOneAttendee(where: $where) {
      id
      firstName
      lastName
      email
      phone
      additionalInformation
      eventID
      active
      hasAttended
      attendees {
        id
        firstName
        lastName
        email
        phone
      }
      sessions {
        id
        price
        status
        sessionType {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_ATTENDEE = gql`
  mutation createAttendee($data: AttendeeCreateInput!) {
    createAttendee(data: $data) {
      id
      firstName
      lastName
      email
      phone
      additionalInformation
      eventID
      active
      attendees {
        id
        firstName
        lastName
        email
        phone
      }
      hasAttended
      sessions {
        id
        price
        status
        sessionType {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_ATTENDEE = gql`
  mutation updateAttendee($id: Int!, $data: AttendeeUpdateInput) {
    updateAttendee(id: $id, data: $data) {
      id
      firstName
      lastName
      email
      phone
      additionalInformation
      eventID
      active
      hasAttended
      attendees {
        id
        firstName
        lastName
        email
        phone
      }
      sessions {
        id
        price
        status
        sessionType {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ATTENDEE = gql`
  mutation deleteAttendee($where: ActivityWhereInput) {
    deleteActivity(where: $where) {
      id
    }
  }
`;
