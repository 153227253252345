import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { CEGO_LOGO, classNames, hasPermission } from '../utils';
import { PermissionContext } from '../context/permissionContext';

interface Props {
  component: React.FC; //check type in parent
  permission?: string;
}

export const AuthenticationGuard: React.FC<Props> = ({ component, permission }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { permissions, setPermissions } = useContext(PermissionContext);

  if (isLoading)
    return (
      <div className='h-screen flex items-center justify-center'>
        <div className={classNames('sweet-loading my-10 text-center')}>
          <PulseLoader color={'#A9C1C9'} loading={true} size={20} aria-label='Loading Spinner' data-testid='loader' />
        </div>
      </div>
    );

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }

  if (!isLoading && isAuthenticated && permission && !hasPermission(permissions, permission))
    return (
      <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
        <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
          <div className='flex flex-shrink-0 justify-center'></div>
          <div className='py-16'>
            <div className='text-center'>
              <p className='text-6xl font-bold text-cego-black'>403</p>
              <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Forbidden</h1>
              <p className='mt-2 text-base text-gray-500'>The page you&apos;re trying to access is restricted and cannot be accessed.</p>
              <div className='mt-6'>
                <Link to='/' className='text-base font-medium text-cego-black'>
                  Go back home
                  <span aria-hidden='true'> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    );

  return <>{component}</>;
};
