import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/solid';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actions?: React.ReactElement;
  lightDismiss?: boolean;
  children?: React.ReactElement;
}

const SmallModal: React.FC<Props> = ({ actions, children, open, setOpen, lightDismiss }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto my-auto'>
          <div className='flex align-middle h-screen w-screen justify-center'>
            <div className='flex items-end justify-center text-center sm:items-center h-52 w-5/12 self-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full p-0 sm:max-w-6xl h-full'>
                  <div className='h-full'>
                    <div className='absolute top-0 right-0 hidden sm:block pt-8 pr-8'>
                      <button
                        type='button'
                        className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                        onClick={() => setOpen(false)}
                      >
                        <span className='sr-only'>Close</span>
                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                      </button>
                    </div>
                    <div className='h-full overflow-y-auto flex'>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { SmallModal };
