import { gql } from '@apollo/client';
import { IUser } from './user';
import { IEvent } from './event';

export const CREATE_ACTIVITY_ENTITY = gql`
  mutation createActivityEntity($data: ActivityEntityCreateInput!) {
    createActivityEntity(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_ACTIVITY_ENTITY = gql`
  mutation deleteActivityEntity($where: ActivityEntityWhereInput) {
    deleteActivityEntity(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
