import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/solid';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import { Dialog } from '@headlessui/react';
import { useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { set } from 'lodash';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DATE_FORMAT_LONG,
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  resolveMoney,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import Button from '../../components/tailwind/button';
import { SearchContext } from '../../context/searchContext';
import { toastError, toastSuccess } from '../../utils/toast';
import { CREATE_ORDER, GET_ORDER, GET_ORDERS, IOrder, UPDATE_ORDER } from '../../utils/orders';
import { ITicket, UPDATE_TICKET } from '../../utils/ticket';
import { GET_ATTENDEE, IAttendee, UPDATE_ATTENDEE } from '../../utils/attendee';
import { ISession, UPDATE_SESSION } from '../../utils/session';
import { IRoom, UPDATE_ROOM } from '../../utils/room';
import { SmallModal } from '../../components/tailwind/smallmodal';
import { DeleteForm } from '../../components/deleteForm';
import OrderCreateForm from '../../components/orderCreateForm';
import FilterForm, { InlineFilter, initFilterPanelState } from '../../components/inlineFilterForm';
import { extractCreditNotes } from '../../utils/proposal';

export const Orders: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      handleSetOrderSelection({ id: parseInt(id) } as any);
    }
  }, []);

  // ===========================================
  // ================ ROOM STATE ===============

  const [selectedRoom, setSelectedRoom] = useState<IRoom | undefined>();
  const [isOpenRoomDelete, setIsOpenRoomDelete] = useState(DEFAULT_DETAIL_OPEN);

  const roomColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Type',
      fieldName: 'room.roomType.name',
      render: (item: IRoom) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.roomType.name}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Prijs',
      fieldName: 'room.roomType.price',
      render: (item: IRoom) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-36', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.price && item?.price !== 0 ? `${resolveMoney(item?.price)}` : ''}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Actie',
      fieldName: 'room.roomType.actie',
      render: (item: IRoom) => {
        return (
          <span
            className={classNames(
              'text-ellipsis text-base overflow-hidden block w-36',
              item.status === 'CANCELED' ? 'text-cego-red' : ' hover:text-cego-darkgray cursor-pointer',
            )}
          >
            <ArchiveBoxIcon
              className='h-5 w-5'
              onClick={() => {
                if (item.status !== 'CANCELED') {
                  setSelectedRoom(item);
                  setIsOpenRoomDelete(true);
                }
              }}
            />
          </span>
        );
      },
    },
  ];

  const [updateRoom] = useMutation(UPDATE_ROOM);

  const handleUpdateRoom = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedRoom?.id) {
            const updateRoomObj: any = {
              status: 'CANCELED',
            };

            updateRoom({
              variables: {
                id: selectedRoom?.id,
                data: updateRoomObj,
              },
              onCompleted: data => {
                resolve(data.updateRoom);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const room: IRoom = (await res) as any;
      refetchOrder();

      toastSuccess('Kamer succesvol geannuleerd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  // ===========================================
  // ============== SESSION STATE ==============

  const [selectedSession, setSelectedSession] = useState<ISession | undefined>();
  const [isOpenSessionDelete, setIsOpenSessionDelete] = useState(DEFAULT_DETAIL_OPEN);

  const sessionColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'session.sessionType.name',
      render: (item: ISession) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-80', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.sessionType.name}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Actie',
      fieldName: 'session.sessionType.actie',
      render: (item: ISession) => {
        return (
          <span
            className={classNames(
              'text-ellipsis text-base overflow-hidden block w-36',
              item.status === 'CANCELED' ? 'text-cego-red' : ' hover:text-cego-darkgray cursor-pointer',
            )}
          >
            <ArchiveBoxIcon
              className='h-5 w-5'
              onClick={() => {
                if (item.status !== 'CANCELED') {
                  setSelectedSession(item);
                  setIsOpenSessionDelete(true);
                }
              }}
            />
          </span>
        );
      },
    },
  ];

  const [updateSession] = useMutation(UPDATE_SESSION);

  const handleUpdateSession = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedSession?.id) {
            const updateSessionObj: any = {
              status: 'CANCELED',
            };

            updateSession({
              variables: {
                id: selectedSession?.id,
                data: updateSessionObj,
              },
              onCompleted: data => {
                resolve(data.updateSession);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const session: ISession = (await res) as any;
      refetchAttendee({
        where: {
          id: selectedAttendee?.id,
        },
      });

      toastSuccess('Sessie succesvol geannuleerd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  // ===========================================
  // ========== TICKET ATTENDEE TYPE ===========

  const [selectedTicket, setSelectedTicket] = useState<ITicket | undefined>();
  const [selectedAttendee, setSelectedAttendee] = useState<IAttendee | undefined>();
  const [selectedAttendeeTab, setSelectedAttendeeTab] = useState(DEFAULT_SELECTED_TAB);
  const [openAttendeeDetail, setOpenAttendeeDetail] = useState(DEFAULT_DETAIL_OPEN);

  const ticketColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'ticket.attendee',
      render: (item: ITicket) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.attendee.firstName} {item?.attendee.lastName}
            {item?.attendee?.attendees?.length > 0
              ? item?.attendee?.attendees.map((person: any) => `, ${person.firstName} ${person.lastName}`)
              : ''}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Email',
      fieldName: 'ticket.attendee.email',
      render: (item: ITicket) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.attendee.email}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Type',
      fieldName: 'ticket.ticketType.name',
      render: (item: ITicket) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {item?.ticketType.name}
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: 'Prijs',
      fieldName: 'ticketType.price',
      render: (item: ITicket) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-30', item.status === 'CANCELED' && 'text-cego-red')}>
            {resolveMoney(item?.ticketType.price)}
          </span>
        );
      },
    },
  ];

  const [updateAttendee] = useMutation(UPDATE_ATTENDEE);
  const [updateTicket] = useMutation(UPDATE_TICKET);

  const [getAttendee, { loading: loadingAttendee, error: errorAttendee, refetch: refetchAttendee }] = useLazyQuery(GET_ATTENDEE);

  const handleSetTicketSelection = (ticket?: ITicket) => {
    if (ticket?.id) {
      getAttendee({
        variables: {
          where: {
            id: ticket?.attendeeID,
          },
        },
        onCompleted: data => {
          setSelectedAttendee(data.findOneAttendee);
          setSelectedTicket(ticket);
        },
      });
    } else {
      setSelectedTicket(undefined);
    }

    setOpenAttendeeDetail(true);
  };

  const handleUpdateAttendee = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedAttendee?.id) {
            const updateAttendeeObj: any = {
              firstName: selectedAttendee?.firstName,
              lastName: selectedAttendee?.lastName,
              phone: selectedAttendee?.phone,
              email: selectedAttendee?.email,
              attendees: selectedAttendee?.attendees.map(({ __typename, ...rest }) => rest),
            };

            updateAttendee({
              variables: {
                id: selectedAttendee?.id,
                data: updateAttendeeObj,
              },
              onCompleted: data => {
                resolve(data.updateAttendee);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const attendee: IAttendee = (await res) as any;
      setSelectedAttendee(attendee);
      refetchOrder();

      toastSuccess('Ticket succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleUpdateTicket = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedTicket?.id) {
            const updateTicketObj: any = {
              status: 'CANCELED',
            };

            updateTicket({
              variables: {
                id: selectedTicket?.id,
                data: updateTicketObj,
              },
              onCompleted: data => {
                resolve(data.updateTicket);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const ticket: ITicket = (await res) as any;
      setSelectedTicket(ticket);
      refetchOrder();
      setOpenAttendeeDetail(false);

      toastSuccess('Ticket succesvol geannuleerd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const attendeeDetailSchema: DetailSchema = {
    id: 'attendee_detail',
    object: selectedAttendee,
    permission: '',
    selectedTab: selectedAttendeeTab,
    setSelectedTab: setSelectedAttendeeTab,
    handleUpdate: selectedTicket?.status === 'CANCELED' ? undefined : handleUpdateAttendee,
    handleDelete: selectedTicket?.status === 'CANCELED' ? undefined : handleUpdateTicket,
    delete: {
      title: 'Ticket annuleren',
      text: 'Weet je zeker dat je dit ticket wilt annuleren? Dit kan niet ongedaan worden gemaakt.',
      buttonText: 'Ticket annuleren',
    },
    sections: [
      {
        id: 'attendee_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'attendee_detail_section_0_field_0',
            label: 'Voornaam',
            name: 'firstName',
            required: true,
            value: selectedAttendee?.firstName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAttendee((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Status moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback(value) {
              return selectedTicket?.status === 'CANCELED';
            },
          },
          {
            id: 'attendee_detail_section_0_field_1',
            label: 'Achternaam',
            name: 'lastName',
            required: true,
            value: selectedAttendee?.lastName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAttendee((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Status moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback(value) {
              return selectedTicket?.status === 'CANCELED';
            },
          },
          {
            id: 'attendee_detail_section_0_field_2',
            label: 'Telefoon',
            name: 'phone',
            value: selectedAttendee?.phone,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAttendee((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback(value) {
              return selectedTicket?.status === 'CANCELED';
            },
          },
          {
            id: 'attendee_detail_section_0_field_3',
            label: 'Email',
            name: 'email',
            required: true,
            value: selectedAttendee?.email,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAttendee((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Email moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback(value) {
              return selectedTicket?.status === 'CANCELED';
            },
          },
          {
            id: 'Ticket_detail_section_0_field_EXTRA',
            label: '',
            name: 'attendees',
            required: false,
            value: selectedAttendee?.attendees ? selectedAttendee?.attendees : [],
            placeholder: '',
            type: 'extraAttendees',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedAttendee((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
            },
          },
          {
            id: 'attendee_detail_section_2_field_0',
            label: 'Sessies',
            name: 'sessions',
            value: selectedAttendee?.sessions,
            table: {
              onCreate: handleSetTicketSelection,
              columns: sessionColumns,
              items: selectedAttendee?.sessions ? selectedAttendee.sessions : [],
              onSelect: handleSetTicketSelection,
              selection: selectedTicket,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedOrder((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            hideCallback(value) {
              return selectedOrder?.event?.sessionTypes && selectedOrder?.event?.sessionTypes.length > 0 ? true : false;
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // =============== ORDER STATE ===============

  const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>();
  const [selectedOrderTab, setSelectedOrderTab] = useState(DEFAULT_SELECTED_TAB);
  const [openOrderDetail, setOpenOrderDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openOrderCreate, setOpenOrderCreate] = useState(DEFAULT_DETAIL_OPEN);
  const [sortingOrders, setSortingOrders] = useState<{ key: string; direction: string }>({
    key: 'createdAt',
    direction: 'desc',
  });
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  const orderFilterSchema: InlineFilter = {
    id: 'order_filter_v1',
    fields: [
      {
        id: 'order_filter_field_1',
        label: 'Datum van',
        name: 'createdAt',
        width: 24,
        type: 'date',
        callback: (fieldValue: string) => ({
          gte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
      {
        id: 'order_filter_field_3',
        label: 'Datum tot',
        name: 'createdAt',
        width: 24,
        type: 'date',
        callback: (fieldValue: string) => ({
          lte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
    ],
  };

  const [orderInlineFilterState, setOrderInlineFilterState] = useState<InlineFilter>(initFilterPanelState(orderFilterSchema));
  const orderColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'OrderNr',
      fieldName: 'order.customer.institution',
      render: (item: IOrder) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-18'>#{item?.id}</span>;
      },
    },
    {
      key: 'column1',
      name: 'Klant',
      fieldName: 'order.customer.institution',
      render: (item: IOrder) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-18'>{item?.customer?.institution}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Vorming',
      fieldName: 'order.event.activity.title',
      render: (item: IOrder) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-46'>{item?.event?.activity?.title}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Plaats',
      fieldName: 'order.event.location.name',
      render: (item: IOrder) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base'>{item?.event?.location.city}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Datum',
      fieldName: 'createdAt',
      render: (item: IOrder) => {
        return (
          <span className='text-ellipsis overflow-hidden block w-18 text-base'>
            {item.createdAt ? moment(item.createdAt).format(DEFAULT_DATE_FORMAT_LONG) : ''}
          </span>
        );
      },
      sortable: true,
      isSorted: sortingOrders.key === 'createdAt',
      isSortedDescending: sortingOrders.direction === 'desc',
      onHeaderClick: () => {
        setSortingOrders(prevState => {
          if (prevState.key === 'createdAt') {
            return {
              key: 'createdAt',
              direction: prevState.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return {
              key: 'createdAt',
              direction: 'asc',
            };
          }
        });
      },
    },
  ];

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery, orderInlineFilterState]);

  const getFilters = useCallback(
    (filterString: string, inlineOrderFilter?: InlineFilter | undefined) => {
      const filterArray = filterString.split(' ');

      const filterObject: any = {
        AND: [],
      };

      if (inlineOrderFilter && inlineOrderFilter.fields && inlineOrderFilter.fields.length > 0) {
        for (let i = 0; i < inlineOrderFilter.fields.length; i++) {
          const field = inlineOrderFilter.fields[i];

          if ((field as any).parsedValue) {
            filterObject.AND.push((field as any).parsedValue);
          }
        }
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { firstName: { contains: filterArray[i] } },
            { lastName: { contains: filterArray[i] } },
            { id: isNaN(Number(filterArray[i])) || filterArray[i] === '' ? undefined : parseInt(filterArray[i]) },
            {
              customer: {
                OR: [{ institution: { contains: filterArray[i] } }, { email: { contains: filterArray[i] } }],
              },
            },
            {
              event: {
                OR: [
                  {
                    activity: {
                      OR: [{ title: { contains: filterArray[i] } }],
                    },
                  },
                ],
              },
            },
          ],
        };

        filterObject.AND.push(filterValue);
      }

      return filterObject;
    },
    [orderInlineFilterState],
  );

  const getOrderBy = useCallback(
    (sortingOrders: any) => {
      return { [sortingOrders.key]: sortingOrders.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingOrders],
  );

  const {
    data: dataOrders,
    loading: loadingOrders,
    error: errorOrders,
    refetch: refetchOrders,
    fetchMore: fetchMoreOrders,
  } = useQuery(GET_ORDERS, {
    variables: {
      filter: getFilters(searchQuery, orderInlineFilterState),
      orderBy: getOrderBy(sortingOrders),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getOrder, { loading: loadingOrder, error: errorOrder, refetch: refetchOrder }] = useLazyQuery(GET_ORDER);
  const [createOrder] = useMutation(CREATE_ORDER);
  // const [updateOrder] = useMutation(UPDATE_ORDER);

  const handleSetOrderSelection = (order?: IOrder) => {
    if (order?.id) {
      getOrder({
        variables: {
          where: {
            id: order?.id,
          },
        },
        onCompleted: data => {
          setSelectedOrder(data.findOneOrder);
          setOpenOrderDetail(true);
        },
      });
    } else {
      setSelectedOrder(undefined);
    }
    setOpenOrderDetail(true);
  };

  const handleCreateOrder = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedOrder?.id) {
            const createOrderObj: any = {
              customer: {
                connect: {
                  id: selectedOrder?.customerID,
                },
              },
              event: {
                connect: {
                  id: selectedOrder?.eventID,
                },
              },
            };

            createOrder({
              variables: {
                data: createOrderObj,
              },
              onCompleted: data => {
                resolve(data.createOrder);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newCreatedOrder: IOrder = (await res) as any;
      setSelectedOrder(newCreatedOrder);
      refetchOrders({
        filter: getFilters(searchQuery, orderInlineFilterState),
        orderBy: getOrderBy(sortingOrders),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Bestelling succesvol gecreerd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const orderDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedOrder,
    permission: '',
    selectedTab: selectedOrderTab,
    setSelectedTab: setSelectedOrderTab,
    handleCreate: !selectedOrder?.id ? handleCreateOrder : undefined,
    sections: [
      {
        id: 'order_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'order_detail_section_x_field_0',
            label: 'Overzicht',
            name: 'overview',
            value: selectedOrder,
            placeholder: '',
            type: 'custom',
            custom: {
              setObject: setSelectedOrder,
            },
            width: 100,
            callback: (name: string, value: string) => {
              return value;
            },
          },
        ],
      },
      {
        id: 'order_detail_section_2',
        title: 'Tickets',
        width: 100,
        fields: [
          {
            id: 'order_detail_section_2_field_0',
            label: 'Tickets',
            name: 'tickets',
            value: selectedOrder?.tickets,
            table: {
              onCreate: handleSetTicketSelection,
              columns: ticketColumns,
              items: selectedOrder?.tickets ? selectedOrder.tickets : [],
              onSelect: handleSetTicketSelection,
              selection: selectedTicket,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedOrder((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'order_detail_section_2_field_1',
            label: '',
            name: 'detail',
            value: selectedOrder?.tickets,
            detail: {
              open: openAttendeeDetail,
              setOpen: setOpenAttendeeDetail,
              title: 'Ticket',
              caption: selectedTicket?.id ? 'Ticket updaten' : 'Ticket toevoegen',
              schema: attendeeDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedOrder((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedOrder?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'order_detail_section_6',
        title: 'Kamers',
        width: 100,
        fields: [
          {
            id: 'order_detail_section_6_field_0',
            label: 'Kamers',
            name: 'rooms',
            value: selectedOrder?.rooms,
            table: {
              onCreate: handleSetTicketSelection,
              columns: roomColumns,
              items: selectedOrder?.rooms ? selectedOrder.rooms : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedOrder((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedOrder?.id && selectedOrder?.event?.roomTypes && selectedOrder?.event?.roomTypes.length > 0) {
            return true;
          }

          return false;
        },
      },
      {
        id: 'proposal_detail_section_3',
        title: 'Facturen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_1_field_3',
            label: 'Facturen',
            name: 'facturen',
            value: selectedOrder?.invoices,
            table: {
              columns: [
                {
                  key: 'column0',
                  name: 'EF nummer',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span
                          className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
                          onClick={() => {
                            const win: any = window.open(
                              `https://eenvoudigfactureren.be/invoices#pg=view&state=all&page=1&doc_id=${item.eenvouwdigFacturerenInvoice.invoice_id}`,
                              '_blank',
                            );
                            win.focus();
                          }}
                        >
                          {item.eenvouwdigFacturerenInvoice.invoice_id}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'>not found</span>;
                    }
                  },
                },
                {
                  key: 'column0',
                  name: 'Type',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span className='text-ellipsis text-base overflow-hidden block w-30'>
                          {item.eenvouwdigFacturerenInvoice.type === 'invoice' ? 'Factuur' : 'Creditnota'}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
                    }
                  },
                },
                {
                  key: 'column1',
                  name: 'Titel',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span className='text-ellipsis text-base overflow-hidden block w-30'>
                          {item.eenvouwdigFacturerenInvoice.reference}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
                    }
                  },
                },
                {
                  key: 'column2',
                  name: 'Totaal incl BTW',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span className='text-ellipsis text-base overflow-hidden block w-30'>
                          {resolveMoney(item.eenvouwdigFacturerenInvoice.total_with_tax)}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
                    }
                  },
                },
                {
                  key: 'column3',
                  name: 'Totaal excl BTW',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span className='text-ellipsis text-base overflow-hidden block w-30'>
                          {resolveMoney(item.eenvouwdigFacturerenInvoice.total_without_tax)}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
                    }
                  },
                },
                {
                  key: 'column3',
                  name: 'Klant',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    if (item?.eenvouwdigFacturerenInvoice) {
                      return (
                        <span className='text-ellipsis text-base overflow-hidden block w-30'>
                          {item.eenvouwdigFacturerenInvoice.client_name}
                        </span>
                      );
                    } else {
                      <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
                    }
                  },
                },
              ],
              items: selectedOrder?.invoices ? selectedOrder.invoices : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              return value;
            },
          },
          {
            id: 'mailing_detail_section_1x_field_3x',
            label: 'Creditnotas',
            name: 'facturen',
            value: extractCreditNotes(selectedOrder?.invoices ? selectedOrder.invoices : []),
            table: {
              columns: [
                {
                  key: 'column0x',
                  name: 'EF nummer',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    return (
                      <span
                        className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
                        onClick={() => {
                          const win: any = window.open(
                            `https://eenvoudigfactureren.be/invoices#pg=view&state=all&page=1&doc_id=${item.invoice_id}`,
                            '_blank',
                          );
                          win.focus();
                        }}
                      >
                        {item.invoice_id}
                      </span>
                    );
                  },
                },
                {
                  key: 'column1x',
                  name: 'Informatie',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.information}</span>;
                  },
                },
                {
                  key: 'column2',
                  name: 'Totaal incl BTW',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item.total_with_tax)}</span>;
                  },
                },
                {
                  key: 'column3',
                  name: 'Totaal excl BTW',
                  fieldName: 'proposal.createdAt',
                  render: (item: any) => {
                    return (
                      <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item.total_without_tax)}</span>
                    );
                  },
                },
              ],
              items: selectedOrder?.invoices ? extractCreditNotes(selectedOrder?.invoices) : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedOrder((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedOrder?.id) {
            return false;
          }

          return true;
        },
      },
    ],
  };

  const refetchOrdersForDashbaord = () => {
    refetchOrders({
      filter: getFilters(searchQuery, orderInlineFilterState),
      orderBy: getOrderBy(sortingOrders),
      skip: 0,
      take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
    });
  };

  // ===========================================

  return (
    <div id='orders'>
      <header>
        <h1 className='font-bold text-2xl'>Bestellingen</h1>
      </header>
      <div>
        <div className='my-4'>
          <div className='mb-4'>
            <FilterForm schema={orderFilterSchema} filterState={orderInlineFilterState} setFilterState={setOrderInlineFilterState} />
          </div>
          <Button
            quaternary
            onClick={() => {
              setOpenOrderCreate(true);
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorOrders ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingOrders ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataOrders ? dataOrders.findManyOrders : []}
                columns={orderColumns}
                loading={loadingOrders}
                onSelect={handleSetOrderSelection}
                selection={selectedOrder}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  const skip = pagedState.skip + pagedState.take;

                  fetchMoreOrders({
                    variables: {
                      filter: getFilters(searchQuery, orderInlineFilterState),
                      orderBy: getOrderBy(sortingOrders),
                      skip: skip,
                      take: pagedState.take,
                    },
                  });

                  setPagedState(prevState => ({
                    ...prevState,
                    skip: skip,
                  }));
                }}
              />
            </div>

            <Modal open={openOrderDetail} setOpen={setOpenOrderDetail}>
              <>
                {openOrderDetail === true ? (
                  <>
                    {!loadingOrder && !errorOrder && (
                      <DetailForm
                        schema={orderDetailSchema}
                        title={'Bestelling'}
                        caption={selectedOrder?.id ? 'Bestelling updaten' : 'Bestelling toevoegen'}
                      />
                    )}
                    {loadingOrder && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorOrder && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
                <SmallModal open={isOpenRoomDelete} setOpen={setIsOpenRoomDelete}>
                  <>
                    <DeleteForm
                      title={'Kamer annuleren'}
                      text={'Weet je zeker dat je deze kamer wilt annuleren? dit kan niet ongedaan worden gemaakt.'}
                      handleDelete={handleUpdateRoom}
                      setIsOpenDelete={setIsOpenRoomDelete}
                    />
                  </>
                </SmallModal>

                <SmallModal open={isOpenSessionDelete} setOpen={setIsOpenSessionDelete}>
                  <>
                    <DeleteForm
                      title={'Sessie annuleren'}
                      text={'Weet je zeker dat je deze sessie wilt annuleren? dit kan niet ongedaan worden gemaakt.'}
                      handleDelete={handleUpdateSession}
                      setIsOpenDelete={setIsOpenSessionDelete}
                    />
                  </>
                </SmallModal>
              </>
            </Modal>
          </div>
        )}
        <Modal open={openOrderCreate} setOpen={setOpenOrderCreate}>
          <div className='m-8'>
            <Dialog.Title className='mb-4'>
              <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4'>Bestelling</h1>
              <p className='mb-4'>Bestelling aanmaken</p>
            </Dialog.Title>
            {openOrderCreate === true && (
              <OrderCreateForm refetchOrders={refetchOrdersForDashbaord} setOpenOrderCreate={setOpenOrderCreate} date={new Date()} />
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Orders;
