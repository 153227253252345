import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DocumentIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../utils';

interface FileUploadProps {
  selectedFiles: File[] | null;
  setSelectedFiles: Dispatch<SetStateAction<File[] | null>>;
  value: string[];
}

const MultiFileUpload: React.FC<FileUploadProps> = ({ selectedFiles, setSelectedFiles, value }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [imageStrings, setImageStrings] = useState<string[] | undefined>(value ? value : []);

  useEffect(() => {
    setSelectedFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);

      const files = Array.from(event.dataTransfer.files);

      const newFiles = selectedFiles ? selectedFiles.concat(files) : files;
      setSelectedFiles(newFiles);
      setImageStrings(newFiles.map(file => file.name ?? 'unknown'));
    },
    [selectedFiles],
  );

  const handleRemoveFile = useCallback((index: any) => {
    setSelectedFiles(prevState => prevState?.filter((_, i) => i !== index) ?? []);
    setImageStrings(prevState => prevState?.filter((_, i) => i !== index) ?? []);
  }, []);

  return (
    <div
      className={`dropzone ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={event => event.preventDefault()}
      onDrop={handleDrop}
    >
      <button
        type='button'
        className={classNames(
          'relative block w-full rounded-sm border border-dashed border-cego-lightbrown text-center hover:border-cego-lightbrown focus:outline-none focus:ring-2 focus:ring-cego-lightbrown focus:ring-offset-2',
          imageStrings && imageStrings.length > 0 ? 'py-5 px-2.5' : 'p-20',
        )}
      >
        <div className='flex flex-row'>
          {(imageStrings ? imageStrings : []).map((imageString, index) => (
            <div key={index} className='px-2.5'>
              <div className='border border-cego-lightbrown rounded-sm'>
                <div>
                  {!imageString ? (
                    <div>
                      <p className='text-cego-darkgray pt-1 text-sm font-normal mb-1'>Sleep hier je bestand naartoe</p>
                    </div>
                  ) : (
                    <div
                      className='p-5'
                      style={{
                        backgroundImage: `url(${imageString})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    >
                      <div className='m-0.5'>
                        <DocumentIcon className='inline h-12 w-12 text-cego-lightbrown margin-auto' aria-hidden='true' />
                      </div>
                      <p className='text-xs tracking-tighter text-cego-darkgray'>{imageString}</p>
                    </div>
                  )}
                </div>
                {imageString && imageString !== '' ? (
                  <p
                    className='hover:cursor-pointer underline text-cego-darkgray pt-1 text-sm font-normal hover:text-cego-lightbrown mb-1'
                    onClick={() => handleRemoveFile(index)}
                  >
                    Verwijder
                  </p>
                ) : (
                  <p className='text-cego-darkgray pt-1 text-sm font-normal mb-1'>Drop hier je bestand</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </button>
    </div>
  );
};

export { MultiFileUpload };
