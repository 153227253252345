import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IBillingCustomer } from './billingCustomer';
import { IOrder } from './orders';

export interface ICustomer {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  auth0ID?: string;
  billingID?: string;
  institution?: string;
  institutionID?: string;
  address?: any;
  email?: string;
  phone?: string;

  billingCustomer?: IBillingCustomer;
  billingCustomerID?: number;

  orders?: IOrder[];
  proposals?: any[];
}

export const GET_CUSTOMERS = gql`
  query getCustomers($filter: CustomerFilterInput, $skip: Int, $take: Int) {
    findManyCustomers(filter: $filter, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      institution
      institutionID
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      billingCustomer {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

// full object for detail page
export const GET_CUSTOMER = gql`
  query getCustomer($where: CustomerWhereInput) {
    findOneCustomer(where: $where) {
      createdAt
      updatedAt
      id
      institution
      institutionID
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      billingCustomer {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const GET_CUSTOMER_DEBOUNCE = gql`
  query getCustomerDebounce($institutionID: String) {
    findOneCustomerByInstitutionID(institutionID: $institutionID) {
      institution
      institutionID
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      billingCustomer {
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      createdAt
      updatedAt
      id
      institution
      institutionID
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      billingCustomer {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($id: Int!, $data: CustomerUpdateInput) {
    updateCustomer(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      institution
      institutionID
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      billingCustomer {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const convertCustomersToComboBoxOptions = (customers: ICustomer[]) => {
  if (customers) {
    const result: IComboBoxOption[] = customers.map(
      (customer: ICustomer): IComboBoxOption => ({
        id: customer.id as any,
        name: `${customer.institution} (${customer.institutionID})`,
        active: true,
      }),
    );

    return result;
  }

  return [];
};
