import { gql } from '@apollo/client';
import { IEvent } from './event';

export interface IRoomType {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eventID?: number;
  event?: IEvent;

  price?: number;
  stock?: number;
  actualStock: number;
  amountSold: number;
  name?: string;
  amountPersons?: number;
  code?: string;

  criticalStockLevel?: number;
  availableFrom?: Date;
  availableTill?: Date;
}

export interface ICreateRoomType {
  price?: number;
  stock?: number;
  name?: string;
  amountPersons?: number;
  code?: string;
}

export interface IUpdateRoomType {
  price?: number;
  stock?: number;
  name?: string;
  amountPersons?: number;
  code?: string;
}

export const GET_ROOMTYPES = gql`
  query getRoomTypes($filter: RoomTypeFilterInput, $take: Int, $skip: Int, $orderBy: RoomTypeOrderByInput) {
    findManyRoomTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      amountPersons
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const GET_ROOMTYPE = gql`
  query getRoomType($where: RoomTypeWhereInput) {
    findOneRoomType(where: $where) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      eventID

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      amountPersons
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const CREATE_ROOMTYPE = gql`
  mutation createRoomType($data: RoomTypeCreateInput!) {
    createRoomType(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      amountPersons
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const UPDATE_ROOMTYPE = gql`
  mutation updateRoomType($id: Int!, $data: RoomTypeUpdateInput) {
    updateRoomType(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      amountPersons
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const DELETE_ROOMTYPE = gql`
  mutation deleteRoomType($where: RoomTypeWhereInput) {
    deleteRoomType(where: $where) {
      id
    }
  }
`;
