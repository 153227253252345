import { resolve } from 'path';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import {
  PlusIcon,
  TrashIcon,
  PaperAirplaneIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ReceiptPercentIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import moment from 'moment';
import { Font, PDFViewer, Page, View, Text, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { orderBy, set } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  CEGO_LEGAL_ADDRESS,
  CEGO_LEGAL_BIC,
  CEGO_LEGAL_EMAIL,
  CEGO_LEGAL_IBAN,
  CEGO_LEGAL_NAME,
  CEGO_LEGAL_PHONE,
  CEGO_LEGAL_QUOTE_DAYS_VALID,
  CEGO_LEGAL_QUOTE_ENDING,
  CEGO_LEGAL_VAT,
  CEGO_LEGAL_WEBSITE,
  CEGO_LOGO,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_LONG,
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  PROPOSAL_STATUS_OPTIONS,
  classNames,
  filterComboBoxItem,
  filterComboBoxUserItem,
  resolveMoney,
  resolveStringOfUsers,
  resolveValueForCombobox,
  transformCloudinaryUrl,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { IComboBoxOption, ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import Button from '../../components/tailwind/button';
import {
  CREATE_PROPOSAL,
  GET_PROPOSAL,
  GET_PROPOSALS,
  INVOICE_PROPOSAL_LINES,
  IProposal,
  UPDATE_PROPOSAL,
  SEND_NOTIFICATIONS,
  extractCreditNotes,
  DELETE_PROPOSAL,
  CANCEL_PROPOSAL,
} from '../../utils/proposal';
import { GET_USERS, IUser, convertUsersToComboBoxOptions } from '../../utils/user';
import { toastSuccess, toastError, toastInfo } from '../../utils/toast';
import {
  CREATE_PROPOSAL_LINE,
  DELETE_PROPOSAL_LINE,
  GET_PROPOSAL_LINE,
  IProposalLine,
  UPDATE_PROPOSAL_LINE,
  UPDATE_PROPOSAL_LINE_EXECUTED,
} from '../../utils/proposalLine';
import { GET_PRODUCTS, IProduct, convertProductsToComboBoxOptions } from '../../utils/product';
import { GET_ACCOUNTS, convertAccountsToComboBoxOptions } from '../../utils/account';
import { GET_PROPOSAL_TYPES, convertProposalTypesToComboBoxOptions } from '../../utils/proposalType';
import { CREATE_PROPOSAL_LINE_USER, DELETE_PROPOSAL_LINE_USER, IProposalLineUser } from '../../utils/proposalLineUser';
import { FullScreenModal } from '../../components/tailwind/fullscreenmodal';
import fontPoppins from '../../fonts/Poppins-Regular.ttf';
import fontPoppinsSemiBold from '../../fonts/Poppins-SemiBold.ttf';
import fontPoppinsBold from '../../fonts/Poppins-Bold.ttf';
import { GET_MAILING, IMailing } from '../../utils/mailing';
import FilterForm, { InlineFilter, initFilterPanelState } from '../../components/inlineFilterForm';
import { GET_CUSTOMERS, ICustomer, convertCustomersToComboBoxOptions } from '../../utils/customer';
import useDebounce from '../../components/tailwind/hooks/useDebounce';
import { SmallModal } from '../../components/tailwind/smallmodal';
import { DeleteForm } from '../../components/deleteForm';
import { ConfirmationForm } from '../../components/confirmationForm';
import {
  CREATE_DIRECTINVOICE,
  DELETE_DIRECTINVOICE,
  IDirectInvoice,
  SEND_DIRECTINVOICE,
  UPDATE_DIRECTINVOICE,
} from '../../utils/directInvoice';

function isAdmin(user?: any): boolean {
  return user?.['https://auth.cego.be/roles']?.includes('admin') ?? false;
}

export const Proposals: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const userIsAdmin = isAdmin(user);

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  });
  const {
    loading: loadingProducts,
    error: errorPropducts,
    data: dataProducts,
  } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });
  const {
    loading: loadingAccounts,
    error: errorAccounts,
    data: dataAccounts,
  } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });
  const { loading: loadingProposalTypes, error: errorProposalTypes, data: dataProposalTypes } = useQuery(GET_PROPOSAL_TYPES); // wil not change regularly

  // -------------------------------------- DEBOUNCE COMBOBOX --------------------------------------
  const [selectedCustomer, setSelectedCustomer] = useState<any | undefined>(undefined);
  const [customerQueryString, setCustomerQueryString] = useState<string>('');
  const [debounceCustomerQueryString, setDebounceCustomerQueryString] = useState<string>(' ');
  const debouncedCustomerQuery = useDebounce(customerQueryString, 500);

  const [selectedCustomer2, setSelectedCustomer2] = useState<any | undefined>(undefined);
  const [customerQueryString2, setCustomerQueryString2] = useState<string>('');
  const [debounceCustomerQueryString2, setDebounceCustomerQueryString2] = useState<string>(' ');
  const debouncedCustomerQuery2 = useDebounce(customerQueryString2, 500);

  useEffect(() => {
    setDebounceCustomerQueryString(debouncedCustomerQuery);
  }, [debouncedCustomerQuery]);

  useEffect(() => {
    setDebounceCustomerQueryString2(debouncedCustomerQuery2);
  }, [debouncedCustomerQuery2]);

  const getFiltersCustomerQuery = (filterString: string, selectedCustomer: ICustomer | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ institution: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedCustomer && selectedCustomer.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedCustomer.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const getFiltersCustomerQuery2 = (filterString: string, selectedCustomer: IUser | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ firstName: { contains: filterArray[i] } }, { lastName: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedCustomer2 && selectedCustomer2.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedCustomer2.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataCustomers,
    loading: loadingCustomers,
    error: errorCustomers,
    refetch: refetchCustomers,
    fetchMore: fetchMoreCustomers,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      filter: getFiltersCustomerQuery(debounceCustomerQueryString, selectedCustomer),
      take: DEFAULT_TAKE,
    },
  });

  const {
    data: dataCustomers2,
    loading: loadingCustomers2,
    error: errorCustomers2,
    refetch: refetchCustomers2,
    fetchMore: fetchMoreCustomers2,
  } = useQuery(GET_USERS, {
    variables: {
      filter: getFiltersCustomerQuery2(debounceCustomerQueryString2, selectedCustomer2),
      orderBy: {
        firstName: 'asc',
      },
    },
  });
  // ------------------------------------------------------------------------------------------------

  // ============== INVOICE STATE ===============

  const invoiceColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'EF nummer',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              const win: any = window.open(
                `https://eenvoudigfactureren.be/invoices#pg=view&state=all&page=1&doc_id=${item.eenvouwdigFacturerenInvoice.invoice_id}`,
                '_blank',
              );
              win.focus();
            }}
          >
            {item.eenvouwdigFacturerenInvoice.invoice_id}
          </span>
        );
      },
    },
    {
      key: 'column0',
      name: 'Type',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item.eenvouwdigFacturerenInvoice.type === 'invoice' ? 'Factuur' : 'Creditnota'}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Titel',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.eenvouwdigFacturerenInvoice.reference}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Totaal incl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {resolveMoney(item.eenvouwdigFacturerenInvoice.total_with_tax)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Totaal excl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {resolveMoney(item.eenvouwdigFacturerenInvoice.total_without_tax)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Klant',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.eenvouwdigFacturerenInvoice.client_name}</span>;
      },
    },
  ];

  const creditColumns: IColumn[] = [
    {
      key: 'column0x',
      name: 'EF nummer',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              const win: any = window.open(
                `https://eenvoudigfactureren.be/invoices#pg=view&state=all&page=1&doc_id=${item.invoice_id}`,
                '_blank',
              );
              win.focus();
            }}
          >
            {item.invoice_id}
          </span>
        );
      },
    },
    {
      key: 'column1x',
      name: 'Informatie',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.information}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Totaal incl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item.total_with_tax)}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Totaal excl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item.total_without_tax)}</span>;
      },
    },
  ];

  // ============== DIRECT INVOICE STATE ==============

  const [selectedDirectInvoice, setSelectedDirectInvoice] = useState<IDirectInvoice | undefined>();
  const [selectedDirectInvoiceTab, setSelectedDirectInvoiceTab] = useState(DEFAULT_SELECTED_TAB);
  const [openDirectInvoiceDetail, setOpenDirectInvoiceDetail] = useState(DEFAULT_DETAIL_OPEN);

  const [createDirectInvoice] = useMutation(CREATE_DIRECTINVOICE);
  const [updateDirectInvoice] = useMutation(UPDATE_DIRECTINVOICE);
  const [deleteDirectInvoice] = useMutation(DELETE_DIRECTINVOICE);

  const [sendDirectInvoice] = useMutation(SEND_DIRECTINVOICE);

  const handleSendDirectInvoice = async (object: IDirectInvoice) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (object?.id) {
            sendDirectInvoice({
              variables: {
                id: object?.id,
              },
              onCompleted: data => {
                resolve(data.sendDirectInvoice);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const directInvoice: IDirectInvoice = (await res) as any;
      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      setOpenDirectInvoiceDetail(false);
      toastSuccess('factuur succesvol vestuurd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteDirectInvoice = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteDirectInvoice({
            variables: {
              where: {
                id: selectedDirectInvoice?.id,
              },
            },
            onCompleted: data => {
              resolve(data.deleteDirectInvoice);
            },
            onError: error => {
              reject(error);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const directInvoice: IDirectInvoice = (await res) as any;

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      setOpenDirectInvoiceDetail(false);
      toastSuccess('factuur succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteProposal = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposal?.id) {
            deleteProposal({
              variables: {
                where: {
                  id: selectedProposal?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedProposal(undefined);
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      setOpenProposalDetail(false);

      toastSuccess('Aanvraag succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateDirectInvoice = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedDirectInvoice) {
            const createEventUserObj: any = {
              proposal: {
                connect: {
                  id: selectedProposal?.id,
                },
              },
              description: selectedDirectInvoice.description,
              price: Number(selectedDirectInvoice.price),
              vat: Number(selectedDirectInvoice.vat),
              date: moment(selectedDirectInvoice?.date).toISOString(),
            };

            createDirectInvoice({
              variables: {
                data: createEventUserObj,
              },
              onCompleted: data => {
                resolve(data.createdDirectInvoice);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      setOpenDirectInvoiceDetail(false);
      toastSuccess('Factuur succesvol toegevoegd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleUpdateDirectInvoice = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedDirectInvoice) {
            const createEventUserObj: any = {
              description: selectedDirectInvoice.description,
              price: Number(selectedDirectInvoice.price),
              vat: Number(selectedDirectInvoice.vat),
              date: moment(selectedDirectInvoice?.date).toISOString(),
            };

            updateDirectInvoice({
              variables: {
                data: createEventUserObj,
                id: selectedDirectInvoice?.id,
              },
              onCompleted: data => {
                resolve(data.updateDirectInvoice);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      setOpenDirectInvoiceDetail(false);
      toastSuccess('Factuur succesvol opgeslagen');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const directInvoiceToSendColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Beschrijving',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item.description} {item.eenvouwdigFacturerenID}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Prijs',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item.price)}</span>;
      },
    },
    {
      key: 'column1',
      name: 'BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.vat ? `${item.vat}%` : '0%'}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Geplande datum',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span
            className={
              classNames('text-ellipsis text-base overflow-hidden block w-30', moment(item.date).isAfter(moment())) && 'text-cego-orange'
            }
          >
            {item.date ? moment(item.date).format(DEFAULT_DATE_FORMAT) : ''}
          </span>
        );
      },
    },
  ];

  const directInvoiceColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'EF nummer',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        if (item?.eenvouwdigFacturerenInvoice?.invoice_id) {
          return (
            <span
              className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
              onClick={() => {
                const win: any = window.open(
                  `https://eenvoudigfactureren.be/invoices#pg=view&state=all&page=1&doc_id=${item.eenvouwdigFacturerenInvoice.invoice_id}`,
                  '_blank',
                );
                win.focus();
              }}
            >
              {item.eenvouwdigFacturerenInvoice.invoice_id}
            </span>
          );
        } else {
          return <span className='text-ellipsis text-base overflow-hidden block w-30'>{''}</span>;
        }
      },
    },
    {
      key: 'column0',
      name: 'Type',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {'Factuur'} {item.eenvouwdigFacturerenID}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Titel',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.eenvouwdigFacturerenInvoice?.reference}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Totaal incl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {resolveMoney(item?.eenvouwdigFacturerenInvoice?.total_with_tax)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Totaal excl BTW',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {resolveMoney(item?.eenvouwdigFacturerenInvoice?.total_without_tax)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Klant',
      fieldName: 'proposal.createdAt',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.eenvouwdigFacturerenInvoice?.client_name}</span>;
      },
    },
  ];

  // const [getDirectInvoice, { loading: loading, error: errorMailing, refetch: refetchMailing }] = useLazyQuery(GET_MAILING);

  const handleSetDirectInvoiceSelection = (directInvoice?: IDirectInvoice) => {
    if (directInvoice?.id) {
      setSelectedDirectInvoice(directInvoice);
    } else {
      setSelectedDirectInvoice(undefined);
    }

    setOpenDirectInvoiceDetail(true);
  };

  const directInvoiceDetailSchema: DetailSchema = {
    id: 'directInvoice_detail',
    object: selectedDirectInvoice,
    permission: '',
    selectedTab: selectedDirectInvoiceTab,
    setSelectedTab: setSelectedDirectInvoiceTab,
    handleCreate: handleCreateDirectInvoice,
    handleUpdate: handleUpdateDirectInvoice,
    handleDelete: handleDeleteDirectInvoice,
    extraButtons: [
      {
        key: 'showProposalPDF',
        name: 'showProposalPDF',
        fieldName: 'action',
        render: () => {
          return (
            <Button
              quaternary
              onClick={() => {
                handleSendDirectInvoice(selectedDirectInvoice as any);
              }}
            >
              {'Factuur versturen'}{' '}
              <span className='inline ml-1'>
                <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          );
        },
      },
    ],
    sections: [
      {
        id: 'directInvoice_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_0_field_0',
            label: 'Beschrijving',
            name: 'description',
            required: true,
            value: selectedDirectInvoice?.description,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedDirectInvoice((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'product_detail_section_0_field_1',
            label: 'Prijs',
            name: 'price',
            required: true,
            value: selectedDirectInvoice?.price,
            placeholder: '',
            type: 'number',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedDirectInvoice((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Prijs moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_3',
            label: 'BTW Percentage',
            name: 'vat',
            required: true,
            value: selectedDirectInvoice?.vat,
            placeholder: '',
            type: 'number',
            max: 21,
            min: 0,
            step: 1,
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedDirectInvoice((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            // validateCallback: (value?: string): { valid: boolean; message?: string } => {
            //   if (!value && (value as any) !== 0) {
            //     return {
            //       valid: false,
            //       message: 'BTW moet worden ingevuld',
            //     };
            //   }

            //   return { valid: true };
            // },
          },
          {
            id: 'event_detail_section_0_field_4',
            label: 'Datum',
            name: 'date',
            required: true,
            value: selectedDirectInvoice?.date ? moment(selectedDirectInvoice?.date).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedDirectInvoice((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Datum moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // ============== CUSTOMER STATE ==============

  const [selectedProposalLineUser, setSelectedProposalLineUser] = useState<IProposalLineUser | undefined>();
  const [createProposalLineUser] = useMutation(CREATE_PROPOSAL_LINE_USER);
  const [deleteProposalLineUser] = useMutation(DELETE_PROPOSAL_LINE_USER);

  const handleCreateProposalLineUser = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposalLineUser) {
            const createProposalLineUserObj: any = {
              proposalLine: {
                connect: {
                  id: selectedProposalLine?.id,
                },
              },
              user: {
                connect: {
                  id: selectedProposalLineUser?.id,
                },
              },
            };

            createProposalLineUser({
              variables: {
                data: createProposalLineUserObj,
              },
              onCompleted: data => {
                resolve(data.createProposalLineUser);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchProposalLine();
      toastSuccess('Medewerker succesvol toegevoegd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteProposalLineUser = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            deleteProposalLineUser({
              variables: {
                where: {
                  id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchProposalLine(); // activitie

      toastSuccess('Medewerker succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const proposalLineUserColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Voornaam',
      fieldName: 'propsalLineUser.user.firstName',
      render: (item: IProposalLineUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.firstName}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Achternaam',
      fieldName: 'propsalLineUser.user.lastName',
      render: (item: IProposalLineUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.lastName}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Functie',
      fieldName: 'propsalLineUser.user.jobTitle',
      render: (item: IProposalLineUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.jobTitle}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Actie',
      fieldName: 'action',
      render: (item: IProposalLineUser) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            {selectedProposalLine?.invoiced !== true && (
              <TrashIcon
                className='inline w-5 h-5 cursor-pointer'
                onClick={() => {
                  handleDeleteProposalLineUser(item?.id as any);
                }}
              />
            )}
          </span>
        );
      },
    },
  ];

  // ===========================================
  // ============== CUSTOMER STATE =============

  const [selectedMailing, setSelectedMailing] = useState<IMailing | undefined>();
  const [selectedMailingTab, setSelectedMailingTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingMailing, setSortingMailing] = useState<ISorting>(DEFAULT_SORTING);
  const [openMailingDetail, setOpenMailingDetail] = useState(DEFAULT_DETAIL_OPEN);

  const mailingColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Datum',
      fieldName: 'mailing.createdAt',
      render: (item: IMailing) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>
            {moment(item?.createdAt).format(DEFAULT_DATE_FORMAT_LONG)}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Onderwerp',
      fieldName: 'mailing.subject',
      render: (item: IMailing) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.subject}`}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Ontvangers',
      fieldName: 'mailing.recipients',
      render: (item: IMailing) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.recipients}`}</span>;
      },
    },
  ];

  const candidatesColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Voornaam',
      fieldName: 'mailing.boor',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.user.firstName}`}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Achternaam',
      fieldName: 'mailing.achter',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.user.lastName}`}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Selecteren',
      fieldName: 'action',
      render: (item: IProposalLineUser) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <UserPlusIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                toastInfo('Niet-opgeslagen wijzigingen');
                setSelectedProposal((prevState: any) => ({
                  ...prevState,
                  ownerID: item.userID,
                }));
              }}
            />
          </span>
        );
      },
    },
  ];

  const [getMailing, { loading: loadingMailing, error: errorMailing, refetch: refetchMailing }] = useLazyQuery(GET_MAILING);

  const handleSetMailingSelection = (mailing?: IMailing) => {
    if (mailing?.id) {
      getMailing({
        variables: {
          where: {
            id: mailing?.id,
          },
        },
        onCompleted: data => {
          setSelectedMailing(data.findOneMailing);
        },
      });
    } else {
      setSelectedMailing(undefined);
    }

    setOpenMailingDetail(true);
  };

  const mailingDetailSchema: DetailSchema = {
    id: 'mailing_detail',
    object: selectedMailing,
    permission: '',
    selectedTab: selectedMailingTab,
    setSelectedTab: setSelectedMailingTab,
    sections: [
      {
        id: 'mailing_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_0_field_0',
            label: 'Verzender',
            name: 'sender',
            required: true,
            value: selectedMailing?.sender,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
          },
          {
            id: 'mailing_detail_section_0_field_1',
            label: 'Ontvanger(s)',
            name: 'recipients',
            required: true,
            value: selectedMailing?.recipients ? JSON.stringify(selectedMailing?.recipients) : '',
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
          },
          {
            id: 'mailing_detail_section_0_field_2',
            label: 'Naam',
            name: 'subject',
            required: true,
            value: selectedMailing?.subject,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
          },
          {
            id: 'mailing_detail_section_0_field_3',
            label: 'Body',
            name: 'body',
            required: true,
            value: selectedMailing?.body,
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
          },
          {
            id: 'mailing_detail_section_0_field_4',
            label: 'Bijlagen',
            name: 'attachments',
            required: true,
            value: selectedMailing?.attachments,
            placeholder: '',
            type: 'attachments',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
          },
        ],
      },
    ],
  };

  const [selectedProposalLine, setSelectedProposalLine] = useState<IProposalLine | undefined>();
  const [selectedProposalLineTab, setSelectedProposalLineTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingProposalLines, setSortingProposalLines] = useState<ISorting>(DEFAULT_SORTING);
  const [openProposalLineDetail, setOpenProposalLineDetail] = useState(DEFAULT_DETAIL_OPEN);

  const proposalLineColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Factureren',
      fieldName: 'propsalLine.invoiced',
      render: (item: IProposalLine) => {
        if (item.actuallyInvoiced) {
          return (
            <span className='text-ellipsis text-base overflow-hidden block w-30'>
              <input
                id={`checkbox${item.id}`}
                aria-describedby='gefactureerd'
                name='comments'
                checked
                type='checkbox'
                disabled
                className='h-4 w-4 rounded border-gray-300 text-gray-500 focus:ring-cego-black'
              />
            </span>
          );
        } else {
          return (
            <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>
              <input
                id={`checkbox${item.id}`}
                aria-describedby='comments-description'
                name='comments'
                checked={item.invoiced}
                type='checkbox'
                onChange={() => {
                  //set invoiced true of this proposalLine in the selected proposal
                  setSelectedProposal((prevState: any) => ({
                    ...prevState,
                    proposalLines: prevState.proposalLines.map((itemx: any) => {
                      if (itemx.id === item.id) {
                        return {
                          ...itemx,
                          invoiced: !itemx.invoiced,
                        };
                      } else {
                        return itemx;
                      }
                    }, {}),
                  }));
                }}
                className='h-4 w-4 rounded border-gray-300 text-cego-black focus:ring-0 cursor-pointer'
              />
            </span>
          );
        }
      },
    },
    {
      key: 'column188',
      name: 'Gefactureerd',
      fieldName: 'propsalLine.title',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            {item?.actuallyInvoiced ? 'Ja' : 'Nee'}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'propsalLine.title',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer '
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            {item?.title}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Prijs',
      fieldName: 'propsalLine.price',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            {resolveMoney(item?.price)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Aantal',
      fieldName: 'propsalLine.amount',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            {item.quantity}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Medewerkers',
      fieldName: 'propsalLine.users',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            <div className='flex -space-x-0.5'>
              <dt className='sr-only'>Commenters</dt>
              {item.proposalLineUsers &&
                item.proposalLineUsers.map(commenter => (
                  <dd key={commenter.id}>
                    <img
                      className='h-6 w-6 rounded-full bg-cego-offwhite ring-2 ring-cego-offwhite'
                      src={transformCloudinaryUrl(commenter.user?.image)}
                      alt={commenter.user?.firstName}
                    />
                  </dd>
                ))}
            </div>
          </span>
        );
      },
    },

    {
      key: 'column3xs',
      name: 'Datum uitgevoerd',
      fieldName: 'propsalLine.date',
      render: (item: IProposalLine) => {
        return (
          <span
            className='text-ellipsis overflow-hidden block w-18 text-base cursor-pointer'
            onClick={() => {
              handleSetProposalLineSelection(item);
            }}
          >
            {item?.date ? moment(item?.date).format('DD/MM/YYYY') : ''}
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: 'Uitgevoerd',
      fieldName: 'Uitgevoerd',
      render: (item: IProposalLine) => {
        let color = '#ff8e3b';
        let string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs ring-offset-2 ring-2 ring-cego-orange`;

        if (item?.executed) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs ring-offset-2 ring-2 ring-cego-green`;
          color = '#20A558';
        }

        if (item?.invoiced) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs ring-cego-green`;
          color = '#20A558';
        }

        return (
          <span
            className={'text-ellipsis overflow-hidden w-40 tracking-widest cursor-pointer'}
            onClick={() => {
              if (!item?.invoiced) handleUpdateProposalLineExecuted(item);
              else handleSetProposalLineSelection(item);
            }}
          >
            <span className={string} style={{ backgroundColor: color }}>
              <span className={item.executed ? 'pr-2 text-center' : ''}>{item?.executed ? 'Ja' : 'Nee'}</span>
            </span>
          </span>
        );
      },
    },
  ];

  const [getProposalLine, { loading: loadingProposalLine, error: errorProposalLine, refetch: refetchProposalLine }] =
    useLazyQuery(GET_PROPOSAL_LINE);
  const [createProposalLine] = useMutation(CREATE_PROPOSAL_LINE);
  const [updateProposalLine] = useMutation(UPDATE_PROPOSAL_LINE);
  const [updateProposalLineExecuted] = useMutation(UPDATE_PROPOSAL_LINE_EXECUTED);
  const [deleteProposalLine] = useMutation(DELETE_PROPOSAL_LINE);

  const handleSetProposalLineSelection = (proposalLine?: IProposalLine) => {
    if (proposalLine?.id) {
      getProposalLine({
        variables: {
          where: {
            id: proposalLine?.id,
          },
        },
        onCompleted: data => {
          setSelectedProposalLine(data.findOneProposalLine);
        },
      });
    } else {
      setSelectedProposalLine(undefined);
    }

    setSelectedProposalLineUser(undefined);
    setOpenProposalLineDetail(true);
  };

  const handleUpdateProposalLine = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposalLine?.id) {
            const updateEventObj: any = {
              title: selectedProposalLine?.title ? selectedProposalLine?.title : '',
              service: selectedProposalLine?.service,
              description: selectedProposalLine?.description ? selectedProposalLine?.description : '',
              price: selectedProposalLine?.price ? parseFloat(selectedProposalLine?.price.toString()) : 0,
              vatPercentage: selectedProposalLine?.vatPercentage ? parseFloat(selectedProposalLine?.vatPercentage.toString()) : 0,
              quantity: selectedProposalLine?.quantity ? parseFloat(selectedProposalLine?.quantity.toString()) : 1,
              product: selectedProposalLine?.productID
                ? {
                    connect: {
                      id: selectedProposalLine?.productID,
                    },
                  }
                : undefined,
              account: selectedProposalLine?.accountID && {
                connect: {
                  id: selectedProposalLine?.accountID,
                },
              },
              date: selectedProposalLine?.date ? moment(selectedProposalLine?.date).toISOString() : null,
            };

            updateProposalLine({
              variables: {
                id: selectedProposalLine?.id,
                data: updateEventObj,
              },
              onCompleted: data => {
                resolve(data.updateProposalLine);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const proposalLine: IProposalLine = (await res) as any;
      setSelectedProposalLine(proposalLine);

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('Aanvraag lijn succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCancelProposal = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposal?.id) {
            const updateEventObj: any = {
              body: '',
            };

            cancelProposal({
              variables: {
                id: selectedProposal?.id,
                data: updateEventObj,
              },
              onCompleted: data => {
                resolve(data.cancelProposal);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      (await res) as any;
      // setSelectedProposalLine(proposalLine);

      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('Aanvraag lijn succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleUpdateProposalLineExecuted = async (object: IProposalLine) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (object?.id) {
            const updateEventObj: any = {
              executed: object?.executed ? false : true,
            };

            updateProposalLineExecuted({
              variables: {
                id: object?.id,
                data: updateEventObj,
              },
              onCompleted: data => {
                resolve(data.updateProposalLineExecuted);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const proposalLine: IProposalLine = (await res) as any;
      setSelectedProposalLine(proposalLine);

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Aanvraag lijn succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateProposalLine = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedProposalLine?.id) {
            const createProposalLineObj: any = {
              title: selectedProposalLine?.title ? selectedProposalLine?.title : '',
              service: selectedProposalLine?.service,
              description: selectedProposalLine?.description ? selectedProposalLine?.description : '',
              price: selectedProposalLine?.price ? parseFloat(selectedProposalLine?.price.toString()) : 0,
              vatPercentage: selectedProposalLine?.vatPercentage ? parseFloat(selectedProposalLine?.vatPercentage.toString()) : 0,
              quantity: selectedProposalLine?.quantity ? parseFloat(selectedProposalLine?.quantity.toString()) : 1,
              proposal: {
                connect: {
                  id: selectedProposal?.id,
                },
              },
              product: selectedProposalLine?.productID
                ? {
                    connect: {
                      id: selectedProposalLine?.productID,
                    },
                  }
                : undefined,
              account: selectedProposalLine?.accountID && {
                connect: {
                  id: selectedProposalLine?.accountID,
                },
              },
              date: selectedProposalLine?.date ? moment(selectedProposalLine?.date).toISOString() : undefined,
            };

            createProposalLine({
              variables: {
                data: createProposalLineObj,
              },
              onCompleted: data => {
                resolve(data.createProposalLine);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const proposalLine: IProposalLine = (await res) as any;
      setSelectedProposalLine(proposalLine);

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Aanvraag lijn succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteProposalLine = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposalLine?.id) {
            deleteProposalLine({
              variables: {
                where: {
                  id: selectedProposalLine?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedProposalLine(undefined);

      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      setOpenProposalLineDetail(false);

      toastSuccess('Aanvraag lijn succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const proposalLineDetailSchema: DetailSchema = {
    id: 'proposalLine_detail',
    object: selectedProposalLine,
    permission: '',
    selectedTab: selectedProposalLineTab,
    setSelectedTab: setSelectedProposalLineTab,
    handleUpdate: handleUpdateProposalLine,
    handleCreate: handleCreateProposalLine,
    handleDelete: handleDeleteProposalLine,
    hideDeleteCallback: () => {
      return selectedProposalLine?.invoiced ? true : false;
    },
    delete: {
      title: 'Aanvraag lijn verwijderen?',
      text: 'Weet je zeker dat je deze lijn wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'proposalLine_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'proposalLine_detail_section_0_field_0',
            label: 'Naam',
            name: 'title',
            required: true,
            value: selectedProposalLine?.title,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_date',
            label: 'Uitgevoerd',
            name: 'date',
            value: selectedProposalLine?.date ? moment(selectedProposalLine?.date).format('YYYY-MM-DD') : '',
            type: 'date',
            placeholder: '',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              // if (!value) {
              //   return {
              //     valid: false,
              //     message: 'Datum moet worden ingevuld',
              //   };
              // }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_1',
            label: 'Beschrijving',
            name: 'description',
            value: selectedProposalLine?.description,
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_2',
            label: 'Prijs',
            name: 'price',
            value: selectedProposalLine?.price,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_3',
            label: 'Aantal',
            name: 'quantity',
            value: selectedProposalLine?.quantity,
            placeholder: '',
            type: 'number',
            required: true,
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Aantal moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_4',
            label: 'BTW Percentage',
            name: 'vatPercentage',
            value: selectedProposalLine?.vatPercentage,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_5',
            label: 'Product',
            name: 'productID',
            value: resolveValueForCombobox(
              selectedProposalLine?.productID ? selectedProposalLine.productID : '',
              convertProductsToComboBoxOptions(
                dataProducts && dataProducts.findManyProducts && !loadingProducts && !errorPropducts ? dataProducts.findManyProducts : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertProductsToComboBoxOptions(
                dataProducts && dataProducts.findManyProducts && !loadingProducts && !errorPropducts
                  ? selectedProposalLine?.id
                    ? dataProducts.findManyProducts
                    : filterComboBoxItem(dataProducts.findManyProducts)
                  : [],
              ),
            },
            width: 48.5,
            callback: (name: string, value: any) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value.id,
                price: dataProducts.findManyProducts.find((item: IProduct) => item.id === value.id)?.price,
                title: dataProducts.findManyProducts.find((item: IProduct) => item.id === value.id)?.name,
                vatPercentage: dataProducts.findManyProducts.find((item: IProduct) => item.id === value.id)?.vatPercentage,
                accountID: dataProducts.findManyProducts.find((item: IProduct) => item.id === value.id)?.accountID,
                service: dataProducts.findManyProducts.find((item: IProduct) => item.id === value.id)?.service,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_0_field_6',
            label: 'Rekening',
            name: 'accountID',
            value: resolveValueForCombobox(
              selectedProposalLine?.accountID ? selectedProposalLine.accountID : '',
              convertAccountsToComboBoxOptions(
                dataAccounts && dataAccounts.findManyAccounts && !loadingAccounts && !errorAccounts ? dataAccounts.findManyAccounts : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            required: true,
            combobox: {
              items: convertAccountsToComboBoxOptions(
                dataAccounts && dataAccounts.findManyAccounts && !loadingAccounts && !errorAccounts
                  ? selectedProposalLine?.id
                    ? dataAccounts.findManyAccounts
                    : filterComboBoxItem(dataAccounts.findManyAccounts)
                  : [],
              ),
            },
            width: 48.5,
            callback: (name: string, value: any) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Rekening moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'product_detail_section_0_field_9sd0',
            label: 'Dienst',
            name: 'service',
            value: selectedProposalLine?.service,
            placeholder: '',
            type: 'switch',
            width: 31.5,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
      },
      {
        id: 'proposalLine_detail_section_1',
        title: 'Medewerkers',
        width: 100,
        fields: [
          {
            id: 'proposalLine_detail_section_1_field_1',
            label: 'Aanvraag medewerkers',
            name: 'proposalLineUsers',
            value: selectedProposalLine?.proposalLineUsers,
            fasttable: {
              columns: proposalLineUserColumns,
              items: selectedProposalLine?.proposalLineUsers ? selectedProposalLine.proposalLineUsers : [],
            },
            type: 'fast-table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'proposalLine_detail_section_1_field_2',
            label: 'Medewerker',
            name: 'id',
            required: true,
            value: selectedProposalLineUser
              ? resolveValueForCombobox(
                  selectedProposalLineUser?.id ? selectedProposalLineUser?.id : '',
                  convertUsersToComboBoxOptions(
                    dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
                  ),
                )
              : ({ id: 'selecteer', name: '' } as IComboBoxOption),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
                  ? selectedProposalLine?.id
                    ? dataUsers.findManyUsers
                    : filterComboBoxUserItem(dataUsers.findManyUsers)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposalLineUser((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            hideCallback: () => {
              return !selectedProposalLine?.invoiced ? true : false;
            },
          },
          {
            id: 'proposalLine_detail_section_1_field_3',
            label: '',
            name: '',
            value: '',
            placeholder: '',
            type: 'add-button',
            width: 66,
            callback: (name: string, value: any) => {
              handleCreateProposalLineUser();
              return value;
            },
            disableCallback(value) {
              if (
                selectedProposalLine?.proposalLineUsers &&
                selectedProposalLine?.proposalLineUsers.some(item => item.userID === selectedProposalLineUser?.id)
              ) {
                return true;
              }

              if (selectedProposalLineUser?.id?.toString() === 'selecteer') {
                return true;
              }
              return selectedProposalLineUser?.id ? false : true;
            },
            hideCallback: () => {
              return !selectedProposalLine?.invoiced ? true : false;
            },
          },
        ],
        hideCallback: () => {
          if (selectedProposalLine?.id) {
            return true;
          }
          return true;
        },
      },
    ],
  };

  // ===========================================
  // ========== PRODUCT CUSTOMER STATE =========

  const [selectedProposal, setSelectedProposal] = useState<IProposal | undefined>();
  const [selectedProposalTab, setSelectedProposalTab] = useState(DEFAULT_SELECTED_TAB);
  const [openProposalDetail, setOpenProposalDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openProposalPDF, setOpenProposalPDF] = useState(DEFAULT_DETAIL_OPEN);
  const [sortingProposals, setSortingProposals] = useState<{ key: string; direction: string }>({
    key: 'createdAt',
    direction: 'desc',
  });
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  const proposalFilterSchema: InlineFilter = {
    id: 'proposal_filter_v1',
    fields: [
      {
        id: 'proposal_filter_field_1',
        label: 'Datum van',
        name: 'createdAt',
        width: 20,
        type: 'date',
        callback: (fieldValue: string) => ({
          gte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
      {
        id: 'proposal_filter_field_3',
        label: 'Datum tot',
        name: 'createdAt',
        width: 20,
        type: 'date',
        callback: (fieldValue: string) => ({
          lte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
      {
        id: 'user_filter_field_0',
        label: 'Status',
        name: 'status',
        value: '',
        width: 20,
        type: 'select',
        selectbox: {
          items: 'Verstuurd,Open,Nieuw,In behandeling,Voltooid,Geannuleerd,Goedgekeurd',
        },
        callback: (fieldValue: any) => {
          if (fieldValue === 'Alles') {
            return;
          }
          return {
            contains: fieldValue ? fieldValue : undefined,
          };
        },
      },
      {
        id: 'user_filter_field_01',
        label: 'Medewerkers',
        name: 'OR',
        value: selectedCustomer2
          ? ({
              id: selectedCustomer2?.id as any,
              name: `${selectedCustomer2?.firstName} ${selectedCustomer2?.lastName}`,
            } as IComboBoxOption)
          : undefined,
        width: 26,
        type: 'combobox',
        placeholder: 'Selecteer',
        combobox: {
          items: [
            { id: 'Alles', name: 'Alles', active: true },
            ...convertUsersToComboBoxOptions(
              dataCustomers2 && dataCustomers2.findManyUsers && !loadingCustomers2 && !errorCustomers2 ? dataCustomers2.findManyUsers : [],
            ),
          ],
          query: customerQueryString2,
          setQuery: setCustomerQueryString2,
        },
        callback: (fieldValue: any) => {
          if (fieldValue.id !== 'Alles') {
            console.log(fieldValue);
            // setSelectedCustomer2(dataCustomers2.findManyUsers.find((item: any) => item.id.toString() === fieldValue.id.toString()));
            return [
              {
                proposalLines: {
                  some: {
                    proposalLineUsers: {
                      some: {
                        userID: Number(fieldValue.id),
                      },
                    },
                  },
                },
              },
              {
                ownerID: Number(fieldValue.id),
              },
            ];
          } else {
            setSelectedCustomer2({ id: '', name: '', active: true });
          }

          return [];
        },
      },
      {
        id: 'user_filter_field_02',
        label: 'Factureren',
        name: 'OR',
        value: '',
        width: 10,
        type: 'switch',
        callback: (fieldValue: any) => {
          if (fieldValue) {
            return [
              {
                proposalLines: {
                  some: {
                    executed: {
                      equals: true,
                    },
                    invoiced: {
                      equals: false,
                    },
                  },
                },
                directInvoices: {
                  none: {},
                },
              },
              {
                directInvoices: {
                  some: {
                    eenvouwdigFacturerenID: null,
                  },
                },
              },
            ];
          }

          return;
        },
      },
    ],
  };

  const [proposalInlineFilterState, setProposalInlineFilterState] = useState<InlineFilter>(initFilterPanelState(proposalFilterSchema));

  interface IProposalInvoice {
    proposalID?: number;
    proposalLineID?: number;
    invoiced?: boolean;
    effectivlyInvoiced?: boolean;
  }

  //facturatie state
  const [selectedProposalInvoiceState, setSelectedProposalInvoiceState] = useState<IProposalInvoice[] | undefined>();

  const handleSetProposalInvoiceState = (proposal?: IProposal) => {
    if (proposal?.id) {
      setSelectedProposalInvoiceState(
        proposal?.proposalLines?.map((item: IProposalLine) => {
          return {
            proposalID: proposal?.id,
            proposalLineID: item?.id,
            invoiced: item?.invoiced,
            effectivlyInvoiced: item?.invoiced,
          };
        }),
      );
    } else {
      setSelectedProposalInvoiceState(undefined);
    }
  };

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery, proposalInlineFilterState, sortingProposals]);

  const proposalColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Nummer',
      fieldName: 'proposal.name',
      render: (item: IProposal) => {
        return (
          <>
            <span className='text-ellipsis text-base overflow-hidden block w-18'>
              {item?.documentNo}{' '}
              {item?.invoiceAbleLines === true ? (
                <span className='absolute h-2 w-2'>
                  <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-cego-orange opacity-75'></span>
                  <span className='absolute rounded-full h-2 w-2 bg-cego-orange'></span>
                </span>
              ) : (
                ''
              )}
              {!item?.ownerID ? (
                <span className='absolute h-2 w-2'>
                  <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-cego-lightblue opacity-75'></span>
                  <span className='absolute rounded-full h-2 w-2 bg-cego-lightblue'></span>
                </span>
              ) : (
                ''
              )}
            </span>
          </>
        );
      },
    },
    {
      key: 'column0',
      name: 'Datum',
      fieldName: 'proposal.createdAt',
      render: (item: IProposal) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-18'>{moment(item.createdAt).format(DEFAULT_DATE_FORMAT)}</span>
        );
      },
      sortable: true,
      isSorted: sortingProposals.key === 'createdAt',
      isSortedDescending: sortingProposals.direction === 'desc',
      onHeaderClick: () => {
        setSortingProposals(prevState => {
          if (prevState.key === 'createdAt') {
            return {
              key: 'createdAt',
              direction: prevState.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return {
              key: 'createdAt',
              direction: 'asc',
            };
          }
        });
      },
    },
    {
      key: 'column6',
      name: 'Klant',
      fieldName: 'proposal.customer',
      render: (item: IProposal) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-46'>{item.customer?.institution}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Type',
      fieldName: 'proposal.proposalType.title',
      render: (item: IProposal) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base'>{item?.proposalType?.title}</span>;
      },
    },
    {
      key: 'column222',
      name: 'Entiteit',
      fieldName: 'proposal.proposalType.title',
      render: (item: IProposal) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base capitalize'>{item?.entity}</span>;
      },
      sortable: true,
      isSorted: sortingProposals.key === 'entity',
      isSortedDescending: sortingProposals.direction === 'desc',
      onHeaderClick: () => {
        setSortingProposals(prevState => {
          if (prevState.key === 'entity') {
            return {
              key: 'entity',
              direction: prevState.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return {
              key: 'entity',
              direction: 'asc',
            };
          }
        });
      },
    },
    {
      key: 'column3231',
      name: 'Medewerkers',
      fieldName: 'propsal.employees',
      render: (item: IProposal) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>
            <div className='flex -space-x-0.5'>
              <dt className='sr-only'>Commenters</dt>
              {item.employees &&
                item.employees.map(employee => (
                  <dd key={employee.id}>
                    <img
                      className='h-6 w-6 rounded-full bg-cego-offwhite ring-2 ring-cego-offwhite'
                      src={transformCloudinaryUrl(employee?.image)}
                      alt={employee?.firstName}
                    />
                  </dd>
                ))}
            </div>
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Status',
      fieldName: 'proposal.status',
      render: (item: IProposal) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base'>{item?.status}</span>;
      },
    },
    {
      key: 'column4632',
      name: 'Volledig uitgevoerd',
      fieldName: 'proposal.fullyExecuted',
      render: (item: IProposal) => {
        let color = '#ff8e3b';
        const string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;

        if (item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0) {
          color = '#20A558';
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span className={string} style={{ backgroundColor: color }}>
              <span className={item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0 ? 'pr-2 text-center' : ''}>
                {item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0 ? 'Ja' : 'Nee'}
              </span>
            </span>
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: 'Volledig gefactureerd',
      fieldName: 'proposal.fullyInvoiced',
      render: (item: IProposal) => {
        let color = '#ff8e3b';
        const string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;
        let text = 'Nee';

        if (item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0) {
          color = '#20A558';
        }

        if (item?.directInvoice) {
          color = '#209BA5';
          text = 'GPF';
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span className={string} style={{ backgroundColor: color }}>
              <span className={item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0 ? 'pr-2 text-center' : ''}>
                {item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0 ? 'Ja' : text}
              </span>
            </span>
          </span>
        );
      },
    },
  ];

  const getFilters = useCallback(
    (filterString: string, inlineFilter?: InlineFilter | undefined) => {
      const filterArray = filterString.split(' ');

      const filterObject: any = {
        AND: [],
      };

      if (inlineFilter && inlineFilter.fields && inlineFilter.fields.length > 0) {
        for (let i = 0; i < inlineFilter.fields.length; i++) {
          const field = inlineFilter.fields[i];

          if ((field as any).parsedValue) {
            filterObject.AND.push((field as any).parsedValue);
          }
        }
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [{ name: { contains: filterArray[i] } }, { customer: { institution: { contains: filterArray[i] } } }],
        };

        filterObject.AND.push(filterValue);
      }

      return filterObject;
    },
    [proposalInlineFilterState],
  );

  const getOrderBy = useCallback(
    (sorting: any) => {
      return { [sorting.key]: sorting.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingProposals],
  );

  const {
    data: dataProposals,
    loading: loadingProposals,
    error: errorProposals,
    refetch: refetchProposals,
    fetchMore: fetchMoreProposals,
  } = useQuery(GET_PROPOSALS, {
    variables: {
      filter: getFilters(searchQuery, proposalInlineFilterState),
      orderBy: getOrderBy(sortingProposals),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getProposal, { loading: loadingProposal, error: errorProposal, refetch: refetchProposal }] = useLazyQuery(GET_PROPOSAL);
  const [createProposal] = useMutation(CREATE_PROPOSAL);
  const [updateProposal] = useMutation(UPDATE_PROPOSAL);
  const [deleteProposal] = useMutation(DELETE_PROPOSAL);
  const [cancelProposal] = useMutation(CANCEL_PROPOSAL);
  const [sendNotifications] = useMutation(SEND_NOTIFICATIONS);
  const [invoiceProposalLines] = useMutation(INVOICE_PROPOSAL_LINES);

  const handleSetProposalSelection = (proposal?: IProposal) => {
    if (proposal?.id) {
      getProposal({
        variables: {
          where: {
            id: proposal?.id,
          },
        },
        onCompleted: data => {
          setSelectedProposal({ ...data.findOneProposal });
          handleSetProposalInvoiceState(data.findOneProposal);
          setOpenProposalDetail(true);
        },
      });
    } else {
      setSelectedProposal(undefined);
      handleSetProposalInvoiceState(undefined);
    }

    setOpenProposalDetail(true);
  };

  const handleUpdateProposal = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposal?.id) {
            const updateProductObj: any = {
              name: selectedProposal?.name,
              firstName: selectedProposal?.firstName,
              lastName: selectedProposal?.lastName,
              email: selectedProposal?.email,
              phone: selectedProposal?.phone,
              body: selectedProposal?.body,
              status: selectedProposal?.status,
              poNo: selectedProposal?.poNo,
              proposalType: {
                connect: {
                  id: selectedProposal?.proposalTypeID,
                },
              },
              owner: {
                connect: {
                  id: selectedProposal?.ownerID,
                },
              },
              directInvoice: selectedProposal?.directInvoice,
            };
            updateProposal({
              variables: {
                id: selectedProposal?.id,
                data: updateProductObj,
              },
              onCompleted: data => {
                resolve(data.updateProposal);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );
      const newUpdatedProduct: IProposal = (await res) as any;
      setSelectedProposal(newUpdatedProduct);
      handleSetProposalInvoiceState(newUpdatedProduct);
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('Proposals succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateProposal = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedProposal?.id) {
            const createProductObj: any = {
              name: selectedProposal?.name,
              firstName: selectedProposal?.firstName,
              lastName: selectedProposal?.lastName,
              email: selectedProposal?.email,
              phone: selectedProposal?.phone,
              body: selectedProposal?.body,
              status: 'Nieuw',
              poNo: selectedProposal?.poNo,
              proposalType: {
                connect: {
                  id: selectedProposal?.proposalTypeID,
                },
              },
              customer: {
                connect: {
                  id: 10,
                },
              },
              owner: {
                connect: {
                  id: selectedProposal?.ownerID,
                },
              },
            };
            createProposal({
              variables: {
                data: createProductObj,
              },
              onCompleted: data => {
                resolve(data.createProposal);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProposal = (await res) as any;
      setSelectedProposal(newUpdatedProduct);
      handleSetProposalInvoiceState(newUpdatedProduct);
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('Aanvraag succesvol toegvoegd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleSendNotifications = async (users: number[], proposalID: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          sendNotifications({
            variables: {
              data: {
                users: users,
                proposalID: proposalID,
              },
            },
            onCompleted: data => {
              resolve(data.sendNotifications);
            },
            onError: error => {
              reject(error);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProposal = (await res) as any;
      setSelectedProposal(newUpdatedProduct);
      handleSetProposalInvoiceState(newUpdatedProduct);
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('Notificaties verstuurd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleSendProposal = async (proposal: IProposal) => {
    try {
      const res = await toast.promise(
        new Promise(async (resolve, reject) => {
          if (proposal && proposal.id) {
            const pdfBlob = await pdf(<ProposalDownloadTemplate proposal={proposal} />).toBlob();

            const formData = new FormData();
            formData.append('offerte', pdfBlob);

            const token = await getAccessTokenSilently();
            fetch(`${process.env.REACT_APP_FILE_URI}/files/sendQuote/pdf/${proposal.id}`, {
              method: 'POST',
              body: formData,
              headers: new Headers({
                Authorization: `Bearer ${token}`,
              }),
            })
              .then(response => {
                if (response.ok) {
                  // Handle the response from the backend
                } else {
                  // Handle errors
                  toastError('Failed to upload PDF');
                }
              })
              .catch(error => {
                console.error(error);
              });
          }

          resolve(undefined);
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      // const data = await resend.emails.send({
      //   from: 'onboarding@resend.dev',
      //   to: 'delivered@resend.dev',
      //   subject: 'Hello World',
      //   html: '<strong>It works!</strong>',
      // });

      setSelectedProposal(
        (prevState: any) =>
          ({
            ...prevState,
            status: 'Verstuurd',
          } as any),
      );
      toastSuccess('Offerte succesvol verstuurd');
    } catch (error) {
      toastError('Er is iets misgegaan bij het versturen van de offerte');
    }
  };

  const handleInvoiceProposalLines = async (data: any) => {
    try {
      const res = await toast.promise(
        new Promise(async (resolve, reject) => {
          invoiceProposalLines({
            variables: {
              data,
            },
            onCompleted: data => {
              resolve(data.invoiceProposalLines);
            },
            onError: error => {
              reject(error);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProposal = (await res) as any;
      refetchProposal();
      refetchProposals({
        filter: getFilters(searchQuery, proposalInlineFilterState),
        orderBy: getOrderBy(sortingProposals),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      toastSuccess('lijnen succesvol gefactureerd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  //   const btn: `<div className='my-4'>
  //   <Button
  //     quaternary
  //     onClick={() => {
  //       setOpenProposalPDF(true);
  //     }}
  //   >
  //     {'PDF'}{' '}
  //     <span className='inline ml-1'>
  //       <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
  //     </span>
  //   </Button>
  // </div>,`;

  const [isOpenConfirmationInvoiceProposal, setIsOpenConfirmationInvoiceProposal] = useState(false);
  const [isOpenConfirmationCancelProposal, setIsOpenConfirmationCancelProposal] = useState(false);

  const productDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedProposal,
    permission: '',
    selectedTab: selectedProposalTab,
    setSelectedTab: setSelectedProposalTab,
    handleUpdate: handleUpdateProposal,
    handleCreate: handleCreateProposal,
    handleDelete: handleDeleteProposal,
    extraButtons: [
      ...(userIsAdmin
        ? [
            {
              key: 'invoiceProposalLines',
              name: 'invoiceProposalLines',
              fieldName: 'action',
              render: () => {
                if (!selectedProposal?.actuallyDirectInvoice) {
                  return (
                    <>
                      <SmallModal open={isOpenConfirmationCancelProposal} setOpen={setIsOpenConfirmationCancelProposal}>
                        <ConfirmationForm
                          title={'Aanvraag annuleren'}
                          text={'Weet je zeker dat je de aanvraag wil annuleren. Emails worden verstuurd.'}
                          handleConfirm={async () => {
                            if (selectedProposal?.id) {
                              // handleInvoiceProposalLines({ lineIDs, proposalID: selectedProposal.id });

                              handleCancelProposal();

                              setIsOpenConfirmationCancelProposal(false);
                            }
                          }}
                          setIsOpenDelete={setIsOpenConfirmationCancelProposal}
                        />
                      </SmallModal>
                      <Button quaternary onClick={() => setIsOpenConfirmationCancelProposal(true)}>
                        {'Annuleren'}{' '}
                        <span className='inline ml-1'>
                          <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                        </span>
                      </Button>
                    </>
                  );
                }
                return null;
              },
            },
            {
              key: 'invoiceProposalLines',
              name: 'invoiceProposalLines',
              fieldName: 'action',
              render: () => {
                if (!selectedProposal?.actuallyDirectInvoice) {
                  return (
                    <>
                      <SmallModal open={isOpenConfirmationInvoiceProposal} setOpen={setIsOpenConfirmationInvoiceProposal}>
                        <ConfirmationForm
                          title={'Aanvraag factureren'}
                          text={
                            'Weet je zeker dat je de geselcteerde lijnen wil factureren? dit kan niet ongedaan worden gemaakt. De factuur word meteen verstuurd.'
                          }
                          handleConfirm={() => {
                            if (selectedProposal?.id && selectedProposal!.proposalLines!.length > 0) {
                              const proposalLines = selectedProposal!.proposalLines!.filter(
                                (item: IProposalLine) => item.actuallyInvoiced !== true && item.invoiced,
                              );
                              const lineIDs = proposalLines.map((item: IProposalLine) => item.id);
                              handleInvoiceProposalLines({ lineIDs, proposalID: selectedProposal.id });
                              setIsOpenConfirmationInvoiceProposal(false);
                            }
                          }}
                          setIsOpenDelete={setIsOpenConfirmationInvoiceProposal}
                        />
                      </SmallModal>
                      <Button quaternary onClick={() => setIsOpenConfirmationInvoiceProposal(true)}>
                        {'Factureren'}{' '}
                        <span className='inline ml-1'>
                          <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                        </span>
                      </Button>
                    </>
                  );
                }
                return null;
              },
            },
          ]
        : []),
      {
        key: 'showProposalPDF',
        name: 'showProposalPDF',
        fieldName: 'action',
        render: () => {
          return (
            <Button
              quaternary
              onClick={() => {
                setOpenProposalPDF(true);
              }}
            >
              {'Offerte versturen'}{' '}
              <span className='inline ml-1'>
                <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          );
        },
      },
    ],
    delete: {
      title: 'Aanvraag verwijderen?',
      text: 'Weet je zeker dat je deze aanvraag wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'proposal_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'order_detail_section_x_field_0',
            label: 'Overzicht',
            name: 'overview',
            value: selectedProposal,
            placeholder: '',
            type: 'custom2',
            custom: {
              setObject: setSelectedProposal,
            },
            width: 100,
            callback: (name: string, value: string) => {
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section00_0',
        title: selectedProposal?.id ? 'Info' : 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'proposal_detail_section_1_field_158',
            label: 'Klant',
            name: 'customer',
            required: true,
            value: selectedCustomer
              ? ({ id: selectedCustomer?.id as any, name: selectedCustomer?.institution } as IComboBoxOption)
              : undefined,
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertCustomersToComboBoxOptions(
                dataCustomers && dataCustomers.findManyCustomers && !loadingCustomers && !errorCustomers
                  ? dataCustomers.findManyCustomers
                  : [],
              ),
              query: customerQueryString,
              setQuery: setCustomerQueryString,
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedCustomer(dataCustomers.findManyCustomers.find((item: any) => item.id.toString() === value.id.toString()));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && !selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Klant moet worden ingevuld',
                };
              }
              return { valid: true };
            },
            hideCallback: () => {
              if (selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'proposal_detail_section_1_field_1',
            label: 'Aanvraag type',
            name: 'proposalTypeID',
            required: true,
            value: resolveValueForCombobox(
              selectedProposal?.proposalTypeID ? selectedProposal.proposalTypeID : '',
              convertProposalTypesToComboBoxOptions(
                dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
                  ? dataProposalTypes.findManyProposalTypes
                  : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertProposalTypesToComboBoxOptions(
                dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
                  ? dataProposalTypes.findManyProposalTypes
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && !selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Type moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            hideCallback: () => {
              if (selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'proposal_detail_section_1_field_2',
            label: 'Verantwoordelijke',
            name: 'ownerID',
            required: true,
            value: resolveValueForCombobox(
              selectedProposal?.ownerID ? selectedProposal.ownerID : '',
              convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
                  ? selectedProposal?.id
                    ? dataUsers.findManyUsers
                    : filterComboBoxUserItem(dataUsers.findManyUsers)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && !selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Verantwoordelijke moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            hideCallback: () => {
              if (selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'proposal_detail_section_0_field_0',
            label: 'Voornaam',
            name: 'firstName',
            required: true,
            value: selectedProposal?.firstName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Voornaam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_1',
            label: 'Achternaam',
            name: 'lastName',
            required: true,
            value: selectedProposal?.lastName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Achternaam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_2',
            label: 'Email',
            name: 'email',
            required: true,
            value: selectedProposal?.email,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Email moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_3',
            label: 'Telefoon',
            name: 'phone',
            value: selectedProposal?.phone,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'proposal_detail_section_0_field_poNummer',
            label: 'Bestelbonnummer',
            name: 'poNo',
            value: selectedProposal?.poNo,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'proposal_detail_section_0_field_5',
            label: 'Onderwerp',
            name: 'name',
            required: true,
            value: selectedProposal?.name,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Onderwerp moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_6',
            label: 'Body',
            name: 'body',
            required: true,
            value: selectedProposal?.body,
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_6zazeaaa',
            label: 'Voorgestelde datum',
            name: 'suggestedDate',
            required: false,
            value: selectedProposal?.suggestedDate ? moment(selectedProposal?.suggestedDate).format('DD/MM/YYYY') : '',
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              console.log();
            },
            disableCallback: () => {
              return true;
            },
          },
          {
            id: 'proposal_detail_section_0_field_6sdqdqds',
            label: 'Voorgestelde datum',
            name: 'suggestedDateAlternative',
            required: false,
            value: selectedProposal?.suggestedDateAlternative
              ? moment(selectedProposal?.suggestedDateAlternative).format('DD/MM/YYYY')
              : '',
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              console.log();
            },
            disableCallback: () => {
              return true;
            },
          },
        ],
      },
      {
        id: 'proposal_detail_section_1',
        title: 'Aanvraag',
        width: 100,
        fields: [
          {
            id: 'proposal_detail_section_1_field_0',
            label: 'Status',
            name: 'status',
            required: true,
            value: resolveValueForCombobox(selectedProposal?.status ? selectedProposal.status : '', PROPOSAL_STATUS_OPTIONS),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: PROPOSAL_STATUS_OPTIONS,
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Status moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            hideCallback: () => {
              if (!selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'proposal_detail_section_1_field_1',
            label: 'Aanvraag type',
            name: 'proposalTypeID',
            required: true,
            value: resolveValueForCombobox(
              selectedProposal?.proposalTypeID ? selectedProposal.proposalTypeID : '',
              convertProposalTypesToComboBoxOptions(
                dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
                  ? dataProposalTypes.findManyProposalTypes
                  : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertProposalTypesToComboBoxOptions(
                dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
                  ? dataProposalTypes.findManyProposalTypes
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Type moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_1_field_2',
            label: 'Verantwoordelijked',
            name: 'ownerID',
            required: true,
            value: resolveValueForCombobox(
              selectedProposal?.ownerID ? selectedProposal.ownerID : '',
              convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
                  ? selectedProposal?.id
                    ? dataUsers.findManyUsers
                    : filterComboBoxUserItem(dataUsers.findManyUsers)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Verantwoordelijke moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'activity_detail_section_1_field_3',
            label: 'Aanvraag lijnen',
            name: 'proposalLines',
            value: selectedProposal?.proposalLines,
            table: {
              onCreate: handleSetProposalLineSelection,
              columns: proposalLineColumns,
              items: selectedProposal?.proposalLines ? selectedProposal.proposalLines : [],
              // onSelect: handleSetProposalLineSelection,
              selection: selectedProposalLine,
              showCreateCallback: () => {
                return true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            hideCallback: () => {
              if (!selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'customer_detail_section_1_field_9',
            label: 'Geplande facturatie',
            name: 'directInvoice',
            value: selectedProposal?.directInvoice, //custom attr
            type: 'switch',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                directInvoice: value,
              }));
              return value;
            },
            hideCallback: () => {
              if (!userIsAdmin) {
                return false;
              }
              if (!selectedProposal?.id) {
                return false;
              }
              if (selectedProposal?.actuallyDirectInvoice === true) {
                return false;
              }
              if (selectedProposal?.invoices && selectedProposal?.invoices.length > 0) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'activity_detail_section_1_field_4',
            label: '',
            name: 'detail',
            value: selectedProposal?.proposalLines,
            detail: {
              open: openProposalLineDetail,
              setOpen: setOpenProposalLineDetail,
              title: 'Aanvraag lijn',
              caption: selectedProposalLine?.id ? 'Aanvraag lijn updaten' : 'Aanvraag lijn toevoegen',
              schema: proposalLineDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            hideCallback: () => {
              if (!selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section_3',
        title: 'Facturen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_1_field_3',
            label: 'Facturen',
            name: 'facturen',
            value: selectedProposal?.invoices,
            table: {
              columns: invoiceColumns,
              items: selectedProposal?.invoices ? selectedProposal.invoices : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'mailing_detail_section_1x_field_3x',
            label: 'Creditnotas',
            name: 'facturen',
            value: extractCreditNotes(selectedProposal?.invoices),
            table: {
              columns: creditColumns,
              items: selectedProposal?.invoices ? extractCreditNotes(selectedProposal?.invoices) : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'activity_detail_section_1_field_4',
            label: '',
            name: 'detail',
            value: selectedProposal?.mailings,
            detail: {
              open: openMailingDetail,
              setOpen: setOpenMailingDetail,
              title: 'Mailing',
              caption: selectedMailing?.id ? 'Mailing details' : 'Mailing details',
              schema: mailingDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!userIsAdmin) {
            return false;
          }
          if (!selectedProposal?.id) {
            return false;
          }
          if (selectedProposal?.actuallyDirectInvoice && (!selectedProposal?.invoices || selectedProposal?.invoices.length === 0)) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section_7',
        title: 'Geplande facturen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_7_field_3',
            label: 'Geplande Facturen',
            name: 'directInvoices',
            value: selectedProposal?.directInvoices
              ? selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID === null)
              : [],
            table: {
              onCreate: handleSetDirectInvoiceSelection,
              columns: directInvoiceToSendColumns,
              items: selectedProposal?.directInvoices
                ? selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID === null)
                : [],
              onSelect: handleSetDirectInvoiceSelection,
              selection: selectedDirectInvoice,
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'mailing_detail_section_7_field_3',
            label: 'Verzonde Facturen',
            name: 'directInvoices',
            value: selectedProposal?.directInvoices
              ? selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID !== null)
              : [],
            table: {
              columns: directInvoiceColumns,
              items: selectedProposal?.directInvoices
                ? selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID !== null)
                : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'mailing_detail_section_7x_field_3x',
            label: 'Creditnotas',
            name: 'facturen',
            value: extractCreditNotes(
              selectedProposal?.directInvoices
                ? selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID !== null)
                : [],
            ),
            table: {
              columns: creditColumns,
              items: selectedProposal?.directInvoices
                ? extractCreditNotes(selectedProposal.directInvoices.filter(invoice => invoice.eenvouwdigFacturerenID !== null))
                : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'activity_detail_section_7_field_4',
            label: '',
            name: 'detail',
            value: selectedProposal?.directInvoices,
            detail: {
              open: openDirectInvoiceDetail,
              setOpen: setOpenDirectInvoiceDetail,
              title: 'Directe Factuur',
              caption: 'Geplande factuur',
              schema: directInvoiceDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!userIsAdmin) {
            return false;
          }
          if (selectedProposal?.actuallyDirectInvoice === true) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'proposal_detail_section_2',
        title: 'Mailings',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_1_field_3',
            label: 'Mailings',
            name: 'mailings',
            value: selectedProposal?.mailings,
            table: {
              onCreate: handleSetProposalLineSelection,
              columns: mailingColumns,
              items: selectedProposal?.mailings ? selectedProposal.mailings : [],
              onSelect: handleSetMailingSelection,
              selection: selectedMailing,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'activity_detail_section_1_field_4',
            label: '',
            name: 'detail',
            value: selectedProposal?.mailings,
            detail: {
              open: openMailingDetail,
              setOpen: setOpenMailingDetail,
              title: 'Mailing',
              caption: selectedMailing?.id ? 'Mailing details' : 'Mailing details',
              schema: mailingDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section_2x',
        title: 'Kandidaten',
        width: 100,
        fields: [
          {
            id: 'proposal_detail_section_1_field_2xxxx',
            label: 'Verantwoordelijke',
            name: 'ownerID',
            required: true,
            value: resolveValueForCombobox(
              selectedProposal?.ownerID ? selectedProposal.ownerID : '',
              convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
                  ? selectedProposal?.id
                    ? dataUsers.findManyUsers
                    : filterComboBoxUserItem(dataUsers.findManyUsers)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedProposal?.id) {
                return {
                  valid: false,
                  message: 'Verantwoordelijke moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'mailing_detail_section_1_field_xx3',
            label: 'Kandidaten',
            name: 'Kandidaten',
            value: selectedProposal?.candidates,
            table: {
              onCreate: undefined,
              columns: candidatesColumns,
              items: selectedProposal?.candidates ? selectedProposal.candidates : [],
              onSelect: undefined,
              selection: undefined,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section_2sdx',
        title: 'Notificaties',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_1_field_xdsx3',
            label: 'Entiteiten',
            name: 'Kandidaten',
            value: selectedProposal?.id,
            onClick: handleSendNotifications,
            type: 'custom-3',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposalLine((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Aanvragen</h1>
      </header>
      <div>
        <div className='my-4'>
          <div className='mb-4'>
            <FilterForm
              schema={proposalFilterSchema}
              filterState={proposalInlineFilterState}
              setFilterState={setProposalInlineFilterState}
            />
          </div>
          {/* <Button
            quaternary
            onClick={() => {
              handleSetProposalSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button> */}
        </div>
        {errorProposals ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingProposals ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataProposals ? dataProposals.findManyProposals : []}
                columns={proposalColumns}
                loading={loadingProposals}
                onSelect={handleSetProposalSelection}
                selection={selectedProposal}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  const skip = pagedState.skip + pagedState.take;

                  fetchMoreProposals({
                    variables: {
                      filter: getFilters(searchQuery, proposalInlineFilterState),
                      orderBy: getOrderBy(sortingProposals),
                      skip: skip,
                      take: pagedState.take,
                    },
                  });

                  setPagedState(prevState => ({
                    ...prevState,
                    skip: skip,
                  }));
                }}
              />
            </div>

            <Modal open={openProposalDetail} setOpen={setOpenProposalDetail}>
              <>
                {openProposalDetail === true ? (
                  <>
                    {!loadingProposal && !errorProposal && (
                      <DetailForm
                        schema={productDetailSchema}
                        title={'Aanvraag'}
                        caption={selectedProposal?.id ? 'Aanvraag updaten' : 'Aanvraag toevoegen'}
                      />
                    )}
                    {loadingProposal && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorProposal && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>

            <FullScreenModal open={openProposalPDF} setOpen={setOpenProposalPDF}>
              <>
                {openProposalPDF && selectedProposal && selectedProposal.id && (
                  <div className='h-full px-9 pt-16 pb-9'>
                    <PDFViewer height={'94%'} width={'100%'}>
                      <ProposalDownloadTemplate proposal={selectedProposal} />
                    </PDFViewer>
                    <div className='pt-4'>
                      {' '}
                      <Button
                        quaternary
                        onClick={() => {
                          handleSendProposal(selectedProposal);
                          setOpenProposalPDF(false);
                        }}
                      >
                        {'Definitief versturen'}{' '}
                        <span className='inline ml-1'>
                          <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                        </span>
                      </Button>
                    </div>
                  </div>
                )}
              </>
            </FullScreenModal>
          </div>
        )}
      </div>
    </div>
  );
};

// =======================  PDF PROPOSAL GENERATOR ==========================

Font.register({
  family: 'Poppins',
  fonts: [{ src: fontPoppins }, { src: fontPoppinsSemiBold, fontWeight: 500 }, { src: fontPoppinsBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 40,
    fontFamily: 'Poppins',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    // color: 'grey',
  },
  pageHeader: {
    fontSize: 28,
  },
  footerNote: {
    fontSize: 9,
  },
});

const ProposalDownloadTemplate = ({ proposal }: { proposal: IProposal }) => (
  <Document>
    <Page size='A4' style={styles.page} wrap>
      {/* Header repeated on every page */}
      <View style={styles.pageHeader} fixed>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 25,
          }}
        >
          <View>
            <Image
              src={CEGO_LOGO}
              style={{
                width: 150,
              }}
            />
          </View>

          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <View>
              <Text
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                {'OFFERTE'} - {proposal.documentNo}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 25,
          marginBottom: 25,
          fontSize: 12,
        }}
      >
        <View>
          <View
            style={{
              marginBottom: 12,
            }}
          >
            <Text
              style={{
                fontFamily: 'Poppins',
                fontWeight: 700,
                fontStyle: 'normal',
              }}
            >
              {CEGO_LEGAL_NAME.toLocaleUpperCase()}
            </Text>
            <Text>{CEGO_LEGAL_ADDRESS.address}</Text>
            <Text>
              {CEGO_LEGAL_ADDRESS.zipcode} {CEGO_LEGAL_ADDRESS.city}
            </Text>
            <Text>{CEGO_LEGAL_ADDRESS.country}</Text>
          </View>

          <View
            style={{
              marginBottom: 12,
            }}
          >
            <Text>{CEGO_LEGAL_PHONE}</Text>
            <Text>{CEGO_LEGAL_EMAIL}</Text>
            <Text>{CEGO_LEGAL_WEBSITE}</Text>
          </View>

          <View>
            <Text>BTW {CEGO_LEGAL_VAT}</Text>
            <Text>IBAN {CEGO_LEGAL_IBAN}</Text>
            <Text>BIC {CEGO_LEGAL_BIC}</Text>
          </View>
        </View>
        <View>
          <View
            style={{
              marginBottom: 12,
            }}
          >
            <Text
              style={{
                fontFamily: 'Poppins',
                fontWeight: 700,
                fontStyle: 'normal',
              }}
            >
              {proposal.customer?.institution?.toLocaleUpperCase()}
            </Text>
            <Text>{proposal.customer?.address.address}</Text>
            <Text>
              {proposal.customer?.address.zipcode} {proposal.customer?.address.city}
            </Text>
            <Text>{proposal.customer?.address.country}</Text>
          </View>

          <View
            style={{
              marginBottom: 12,
            }}
          >
            <Text>
              {'Offerte:'} {proposal.documentNo}
            </Text>
            {proposal?.poNo && (
              <Text>
                {'Referentie:'} {proposal?.poNo}
              </Text>
            )}
            <Text>
              {'Datum:'} {moment().format('LL')}
            </Text>
            <Text>
              {'T.a.v.'} {proposal.firstName} {proposal.lastName}
            </Text>
          </View>

          <View
            style={{
              marginBottom: 12,
            }}
          >
            <Text>
              {'Geldig tot'} {moment().add(CEGO_LEGAL_QUOTE_DAYS_VALID, 'days').format('LL')}
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 25,
          fontSize: 12,
        }}
      >
        <Text
          style={{
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontStyle: 'normal',
          }}
        >
          {'Aanvraag:'.toUpperCase()}{' '}
        </Text>
        <Text>{proposal.name}</Text>
        <Text
          style={{
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontStyle: 'normal',
            paddingLeft: 10,
          }}
        >
          {' '}
          {'Datum:'.toUpperCase()}{' '}
        </Text>
        <Text>{proposal.date ? moment(proposal.date).format(DEFAULT_DATE_FORMAT_LONG) : 'Nog te bepalen'}</Text>
      </View>

      {/* Table */}
      <View>
        {/* row */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            borderRight: '1px solid #DAE5E8',
            borderLeft: '1px solid #DAE5E8',
            borderTop: '1px solid #DAE5E8',
            borderBottom: '1px solid #DAE5E8',
            padding: 10,
            fontSize: 12,
            backgroundColor: '#DAE5E8',
          }}
        >
          <View
            style={{
              width: '50%',
            }}
          >
            <Text>Omschrijving</Text>
          </View>
          <View
            style={{
              width: '15%',
            }}
          >
            <Text>Aantal</Text>
          </View>
          <View
            style={{
              width: '15%',
            }}
          >
            <Text>Bedrag</Text>
          </View>
          <View
            style={{
              width: '15%',
            }}
          >
            <Text>Totaal</Text>
          </View>
          <View
            style={{
              width: '5%',
              textAlign: 'right',
            }}
          >
            <Text>BTW</Text>
          </View>
        </View>
      </View>
      {proposal.proposalLines?.map((proposalLine: IProposalLine) => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            fontSize: 10,
            padding: 10,
            borderRight: '1px solid #DAE5E8',
            borderLeft: '1px solid #DAE5E8',
            borderTop: '1px solid #DAE5E8',
          }}
          key={proposalLine.id}
        >
          <View
            style={{
              width: '50%',
            }}
          >
            <Text>
              {proposalLine.date && `${moment(proposalLine.date).format('DD/MM/YYYY')} - `} {proposalLine.title}{' '}
              {proposalLine.description && ` - ${proposalLine.description}`}{' '}
              {proposalLine.proposalLineUsers &&
                proposalLine.proposalLineUsers.length > 0 &&
                `- ${resolveStringOfUsers(proposalLine.proposalLineUsers)}`}
            </Text>
          </View>

          <View
            style={{
              width: '15%',
            }}
          >
            <Text>{proposalLine.quantity}</Text>
          </View>

          <View
            style={{
              width: '15%',
            }}
          >
            <Text>{resolveMoney(proposalLine.price)}</Text>
          </View>

          <View
            style={{
              width: '15%',
            }}
          >
            <Text>{resolveMoney(proposalLine.price * proposalLine.quantity)}</Text>
          </View>

          <View
            style={{
              width: '5%',
              textAlign: 'right',
            }}
          >
            <Text>{proposalLine.vatPercentage && `${proposalLine.vatPercentage}%`}</Text>
          </View>
        </View>
      ))}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          fontSize: 10,
          padding: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          borderRight: '1px solid #DAE5E8',
          borderLeft: '1px solid #DAE5E8',
          borderTop: '1px solid #DAE5E8',
          borderBottom: '1px solid #DAE5E8',
        }}
      >
        <View
          style={{
            width: '80%',
          }}
        >
          <Text></Text>
        </View>

        <View
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>{`Totaal`}</Text>
          <Text
            style={{
              fontWeight: 500,
            }}
          >
            {resolveMoney(
              proposal.proposalLines?.reduce((acc: number, proposalLine: IProposalLine) => {
                return acc + proposalLine.price * proposalLine.quantity;
              }, 0) || 0,
            )}
          </Text>
        </View>
      </View>
      {/* Body */}
      <View
        style={{
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <Text style={styles.footerNote}>{CEGO_LEGAL_QUOTE_ENDING}</Text>
      </View>
      {/* Footer repeated on every page */}
      <View style={styles.pageFooter} fixed>
        <Text render={({ pageNumber, totalPages }) => `Pagina ${pageNumber} van ${totalPages}`} />
      </View>
    </Page>
  </Document>
);

export default Proposals;
