import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { PaperAirplaneIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { orderBy } from 'lodash';
import { GET_PROPOSAL, GET_PROPOSALS, GET_PROPOSALS_CANDIDATES, IProposal } from '../../utils/proposal';
import {
  classNames,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
} from '../../utils';
import { IColumn, Table } from '../../components/tailwind/table';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { IProposalLine } from '../../utils/proposalLine';
import Button from '../../components/tailwind/button';
import { CREATE_CANDIDATE, DELETE_CANDIDATE } from '../../utils/candidate';
import { toastError, toastSuccess } from '../../utils/toast';

export const Candidates: React.FC = ({ ...props }) => {
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      handleSetProposalSelection({ id: parseInt(id) } as any);
    }
  }, []);

  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();

  const [selectedProposal, setSelectedProposal] = useState<IProposal | undefined>();
  const [selectedProposalTab, setSelectedProposalTab] = useState(DEFAULT_SELECTED_TAB);
  const [openProposalDetail, setOpenProposalDetail] = useState(DEFAULT_DETAIL_OPEN);

  const [createCandidate] = useMutation(CREATE_CANDIDATE);
  const [deleteCandidate] = useMutation(DELETE_CANDIDATE);

  const {
    data: dataProposals,
    loading: loadingProposals,
    error: errorProposals,
    refetch: refetchProposals,
    fetchMore: fetchMoreProposals,
  } = useQuery(GET_PROPOSALS_CANDIDATES, {
    variables: {
      filter: {
        status: {
          contains: 'Open',
        },
      },
      orderBy: {
        createdAt: 'desc',
      },
      //   orderBy: getOrderBy(sorxtingProposals),
      //   skip: pagedState.skip,
      //   take: pagedState.take,
    },
  });

  const [getProposal, { loading: loadingProposal, error: errorProposal, refetch: refetchProposal }] = useLazyQuery(GET_PROPOSAL);

  const handleSetProposalSelection = (proposal?: IProposal) => {
    console.log('executed', proposal);
    if (proposal?.id) {
      getProposal({
        variables: {
          where: {
            id: proposal?.id,
          },
        },
        onCompleted: data => {
          setSelectedProposal({ ...data.findOneProposal });
          setOpenProposalDetail(true);
        },
      });
    } else {
      setSelectedProposal(undefined);
      setOpenProposalDetail(false);
    }
  };

  const handleCreateCandidate = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProposal) {
            const createObj: any = {
              auth0ID: user?.sub,
              proposal: {
                connect: {
                  id: selectedProposal?.id,
                },
              },
            };

            createCandidate({
              variables: {
                data: createObj,
              },
              onCompleted: data => {
                resolve(data.createCandidate);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProposal = (await res) as any;
      //setSelectedProposal(undefined);

      //setOpenProposalDetail(false);
      toastSuccess('Verstuurd');
      refetchProposals({
        filter: {
          status: {
            contains: 'Open',
          },
        },
      });
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteCandidate = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            deleteCandidate({
              variables: {
                where: {
                  id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProposal = (await res) as any;

      // setOpenProposalDetail(false);
      toastSuccess('Candidatuur ingetrokken');
      refetchProposals({
        filter: {
          status: {
            contains: 'Open',
          },
        },
      });
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const proposalColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Nummer',
      fieldName: 'proposal.name',
      render: (item: IProposal) => {
        return (
          <>
            <span
              className={classNames(
                'text-ellipsis text-base overflow-hidden block w-18',
                item.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? 'text-cego-green' : '',
              )}
            >
              {item?.documentNo}{' '}
              {item?.invoiceAbleLines === true ? (
                <span className='absolute h-2 w-2'>
                  <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-cego-orange opacity-75'></span>
                  <span className='absolute rounded-full h-2 w-2 bg-cego-orange'></span>
                </span>
              ) : (
                ''
              )}
              {!item?.ownerID ? <span className='absolute h-2 w-2'></span> : ''}
            </span>
          </>
        );
      },
    },
    {
      key: 'column0',
      name: 'Datum',
      fieldName: 'proposal.createdAt',
      render: (item: IProposal) => {
        return (
          <span
            className={classNames(
              'text-ellipsis text-base overflow-hidden block w-18',
              item.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? 'text-cego-green' : '',
            )}
          >
            {moment(item.createdAt).format(DEFAULT_DATE_FORMAT)}
          </span>
        );
      },
    },
    {
      key: 'column6',
      name: 'Klant',
      fieldName: 'proposal.customer',
      render: (item: IProposal) => {
        return (
          <span
            className={classNames(
              'text-ellipsis text-base overflow-hidden block w-46',
              item.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? 'text-cego-green' : '',
            )}
          >
            {item.customer?.institution}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Type',
      fieldName: 'proposal.proposalType.title',
      render: (item: IProposal) => {
        return (
          <span
            className={classNames(
              'text-ellipsis overflow-hidden block w-18 text-base',
              item.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? 'text-cego-green' : '',
            )}
          >
            {item?.proposalType?.title}
          </span>
        );
      },
    },
    {
      key: 'column42',
      name: 'Entiteit',
      fieldName: 'proposal.entity',
      render: (item: IProposal) => {
        return (
          <span
            className={classNames(
              'text-ellipsis overflow-hidden block w-18 text-base capitalize',
              item.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? 'text-cego-green' : '',
            )}
          >
            {item?.entity ? item?.entity : ''}
          </span>
        );
      },
    },

    // {
    //   key: 'column4',
    //   name: 'Volledig uitgevoerd',
    //   fieldName: 'proposal.fullyExecuted',
    //   render: (item: IProposal) => {
    //     let color = '#ff8e3b';
    //     const string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;

    //     if (item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0) {
    //       color = '#20A558';
    //     }

    //     return (
    //       <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
    //         <span className={string} style={{ backgroundColor: color }}>
    //           <span className={item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0 ? 'pr-2 text-center' : ''}>
    //             {item?.fullyExecuted && item?.proposalLines && item?.proposalLines?.length > 0 ? 'Ja' : 'Nee'}
    //           </span>
    //         </span>
    //       </span>
    //     );
    //   },
    // },
    // {
    //   key: 'column4',
    //   name: 'Volledig gefactureerd',
    //   fieldName: 'proposal.fullyInvoiced',
    //   render: (item: IProposal) => {
    //     let color = '#ff8e3b';
    //     const string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;
    //     let text = 'Nee';

    //     if (item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0) {
    //       color = '#20A558';
    //     }

    //     if (item?.directInvoice) {
    //       color = '#209BA5';
    //       text = 'GPF';
    //     }

    //     return (
    //       <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
    //         <span className={string} style={{ backgroundColor: color }}>
    //           <span className={item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0 ? 'pr-2 text-center' : ''}>
    //             {item?.fullyInvoiced && item?.proposalLines && item?.proposalLines?.length > 0 ? 'Ja' : text}
    //           </span>
    //         </span>
    //       </span>
    //     );
    //   },
    // },
  ];

  const proposalLineColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'propsalLine.title',
      render: (item: IProposalLine) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{item?.title}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Aantal',
      fieldName: 'propsalLine.amount',
      render: (item: IProposalLine) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.quantity}</span>;
      },
    },
  ];

  const productDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedProposal,
    permission: '',
    selectedTab: selectedProposalTab,
    setSelectedTab: setSelectedProposalTab,
    handleUpdate: undefined,
    handleCreate: undefined,
    extraButtons: [
      {
        key: 'invoiceProposalLines',
        name: 'invoiceProposalLines',
        fieldName: 'action',
        render: () => {
          return selectedProposal?.candidates ? (
            selectedProposal.candidates.some(candidate => candidate.user && candidate.user.auth0ID === user?.sub) ? (
              <Button
                quaternary
                onClick={async (e: any) => {
                  e.preventDefault();
                  const candidateId = selectedProposal.candidates.find(candidate => candidate.user.auth0ID === user?.sub)?.id;
                  if (candidateId) {
                    await handleDeleteCandidate(candidateId);
                    //setOpenProposalDetail(false);
                  }
                }}
              >
                {'Kandidatuur intrekken'}{' '}
                <span className='inline ml-1'>
                  <XCircleIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                </span>
              </Button>
            ) : (
              <Button
                quaternary
                onClick={async (e: any) => {
                  e.preventDefault();
                  await handleCreateCandidate();
                  //setOpenProposalDetail(false);
                }}
              >
                {'Kandidatuur versturen'}{' '}
                <span className='inline ml-1'>
                  <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                </span>
              </Button>
            )
          ) : null;
        },
      },
    ],

    sections: [
      {
        id: 'proposal_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'order_detail_section_x_field_0',
            label: 'Overzicht',
            name: 'overview',
            value: selectedProposal,
            placeholder: '',
            type: 'custom4',
            custom: {
              setObject: setSelectedProposal,
            },
            width: 100,
            callback: (name: string, value: string) => {
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section00_0',
        title: selectedProposal?.id ? 'Info' : 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'proposal_detail_section_0_field_0',
            label: 'Voornaam',
            name: 'firstName',
            required: true,
            value: selectedProposal?.firstName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Voornaam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_1',
            label: 'Achternaam',
            name: 'lastName',
            required: true,
            value: selectedProposal?.lastName,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Achternaam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },

          {
            id: 'proposal_detail_section_0_field_5',
            label: 'Onderwerp',
            name: 'name',
            required: true,
            value: selectedProposal?.name,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Onderwerp moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'proposal_detail_section_0_field_6',
            label: 'Body',
            name: 'body',
            required: true,
            value: selectedProposal?.body,
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Body moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
        ],
      },
      {
        id: 'proposal_detail_section_1',
        title: 'Kandidatuur',
        width: 100,
        fields: [
          //   {
          //     id: 'proposal_detail_section_1_field_0',
          //     label: 'Status',
          //     name: 'status',
          //     required: true,
          //     value: resolveValueForCombobox(selectedProposal?.status ? selectedProposal.status : '', PROPOSAL_STATUS_OPTIONS),
          //     placeholder: 'Selecteer',
          //     type: 'combobox',
          //     combobox: {
          //       items: PROPOSAL_STATUS_OPTIONS,
          //     },
          //     width: 32.5,
          //     callback: (name: string, value: any) => {
          //       setSelectedProposal((prevState: any) => ({
          //         ...prevState,
          //         [name]: value.id,
          //       }));
          //       return value;
          //     },
          //     validateCallback: (value?: string): { valid: boolean; message?: string } => {
          //       if (!value && selectedProposal?.id) {
          //         return {
          //           valid: false,
          //           message: 'Status moet worden ingevuld',
          //         };
          //       }

          //       return { valid: true };
          //     },
          //     hideCallback: () => {
          //       if (!selectedProposal?.id) {
          //         return false;
          //       }
          //       return true;
          //     },
          //   },
          //   {
          //     id: 'proposal_detail_section_1_field_1',
          //     label: 'Aanvraag type',
          //     name: 'proposalTypeID',
          //     required: true,
          //     value: resolveValueForCombobox(
          //       selectedProposal?.proposalTypeID ? selectedProposal.proposalTypeID : '',
          //       convertProposalTypesToComboBoxOptions(
          //         dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
          //           ? dataProposalTypes.findManyProposalTypes
          //           : [],
          //       ),
          //     ),
          //     placeholder: 'Selecteer',
          //     type: 'combobox',
          //     combobox: {
          //       items: convertProposalTypesToComboBoxOptions(
          //         dataProposalTypes && dataProposalTypes.findManyProposalTypes && !loadingProposalTypes && !errorProposalTypes
          //           ? dataProposalTypes.findManyProposalTypes
          //           : [],
          //       ),
          //     },
          //     width: 32.5,
          //     callback: (name: string, value: any) => {
          //       setSelectedProposal((prevState: any) => ({
          //         ...prevState,
          //         [name]: value.id,
          //       }));
          //       return value;
          //     },
          //     validateCallback: (value?: string): { valid: boolean; message?: string } => {
          //       if (!value && selectedProposal?.id) {
          //         return {
          //           valid: false,
          //           message: 'Type moet worden ingevuld',
          //         };
          //       }

          //       return { valid: true };
          //     },
          //   },
          //   {
          //     id: 'proposal_detail_section_1_field_2',
          //     label: 'Verantwoordelijke',
          //     name: 'ownerID',
          //     required: true,
          //     value: resolveValueForCombobox(
          //       selectedProposal?.ownerID ? selectedProposal.ownerID : '',
          //       convertUsersToComboBoxOptions(
          //         dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
          //       ),
          //     ),
          //     placeholder: 'Selecteer',
          //     type: 'combobox',
          //     combobox: {
          //       items: convertUsersToComboBoxOptions(
          //         dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
          //           ? selectedProposal?.id
          //             ? dataUsers.findManyUsers
          //             : filterComboBoxUserItem(dataUsers.findManyUsers)
          //           : [],
          //       ),
          //     },
          //     width: 32.5,
          //     callback: (name: string, value: any) => {
          //       setSelectedProposal((prevState: any) => ({
          //         ...prevState,
          //         [name]: value.id,
          //       }));
          //       return value;
          //     },
          //     validateCallback: (value?: string): { valid: boolean; message?: string } => {
          //       if (!value && selectedProposal?.id) {
          //         return {
          //           valid: false,
          //           message: 'Verantwoordelijke moet worden ingevuld',
          //         };
          //       }

          //       return { valid: true };
          //     },
          //   },
          {
            id: 'activity_detail_section_1_field_3',
            label: 'Aanvraag lijnen',
            name: 'proposalLines',
            value: selectedProposal?.proposalLines,
            table: {
              onCreate: undefined,
              columns: proposalLineColumns,
              items: selectedProposal?.proposalLines ? selectedProposal.proposalLines : [],
              // onSelect: handleSetProposalLineSelection,
              selection: undefined,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProposal((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            hideCallback: () => {
              if (!selectedProposal?.id) {
                return false;
              }
              return true;
            },
          },
          //   {
          //     id: 'customer_detail_section_1_field_9',
          //     label: 'Geplande facturatie',
          //     name: 'directInvoice',
          //     value: selectedProposal?.directInvoice, //custom attr
          //     type: 'switch',
          //     width: 48.5,
          //     callback: (name: string, value: string) => {
          //       setSelectedProposal((prevState: any) => ({
          //         ...prevState,
          //         directInvoice: value,
          //       }));
          //       return value;
          //     },
          //     hideCallback: () => {
          //       if (!selectedProposal?.id) {
          //         return false;
          //       }
          //       if (selectedProposal?.actuallyDirectInvoice === true) {
          //         return false;
          //       }
          //       if (selectedProposal?.invoices && selectedProposal?.invoices.length > 0) {
          //         return false;
          //       }
          //       return true;
          //     },
          //   },
          //   {
          //     id: 'activity_detail_section_1_field_4',
          //     label: '',
          //     name: 'detail',
          //     value: selectedProposal?.proposalLines,
          //     detail: {
          //       open: openProposalLineDetail,
          //       setOpen: setOpenProposalLineDetail,
          //       title: 'Aanvraag lijn',
          //       caption: selectedProposalLine?.id ? 'Aanvraag lijn updaten' : 'Aanvraag lijn toevoegen',
          //       schema: proposalLineDetailSchema,
          //     },
          //     type: 'detail',
          //     width: 100,
          //     callback: (name: string, value: string) => {
          //       setSelectedProposal((prevState: any) => ({
          //         ...prevState,
          //         [name]: value,
          //       }));
          //       return value;
          //     },
          //     hideCallback: () => {
          //       if (!selectedProposal?.id) {
          //         return false;
          //       }
          //       return true;
          //     },
          //   },
        ],
        hideCallback: () => {
          if (!selectedProposal?.id) {
            return false;
          }
          return true;
        },
      },
    ],
  };

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Kandidaturen</h1>
      </header>
      <div>
        {errorProposals ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingProposals ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataProposals ? dataProposals.findManyProposalsCandidates : []}
                columns={proposalColumns}
                loading={loadingProposals}
                onSelect={handleSetProposalSelection}
                selection={selectedProposal}
                lazyLoading={false}
              />
            </div>
          </div>
        )}
      </div>

      <Modal open={openProposalDetail} setOpen={setOpenProposalDetail}>
        <>
          <>
            {!loadingProposal && !errorProposal && (
              <DetailForm
                schema={productDetailSchema}
                title={'Aanvraag'}
                caption={selectedProposal?.id ? 'Aanvraag updaten' : 'Aanvraag toevoegen'}
              />
            )}
            {loadingProposal && (
              <div className='h-5/6 flex items-center'>
                <div className={classNames('sweet-loading text-center w-full')}>
                  <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                </div>
              </div>
            )}
            {errorProposal && <p>Error</p>}
          </>
        </>
      </Modal>
    </div>
  );
};

export default Candidates;
