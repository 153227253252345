import React, { useState } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import Button from './button';

// Define your mutation

// Define types for the checkbox data
interface Checkbox {
  id: number;
  label: string;
  value: string;
}

const CheckboxComponent: React.FC<any> = ({ onClick, value }) => {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  // const [sendCheckedIds] = useMutation(SEND_CHECKED_IDS_MUTATION);

  // Function to handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      // If checked, add the value to the checkedIds array
      setCheckedIds(prevCheckedIds => [...prevCheckedIds, Number(value)]);
    } else {
      // If unchecked, remove the value from the checkedIds array
      setCheckedIds(prevCheckedIds => prevCheckedIds.filter(id => id !== Number(value)));
    }
  };

  // Sample data for checkboxes
  const checkboxes: Checkbox[] = [
    { id: 4, label: 'Kinderopvang', value: '4' },
    { id: 2, label: 'Kleuteronderwijs', value: '2' },
    { id: 3, label: 'Lager onderwijs', value: '3' },
    { id: 5, label: 'Secundair onderwijs', value: '5' },
  ];

  return (
    <div>
      {/* Render checkboxes horizontally */}
      <div className='flex space-x-4'>
        {checkboxes.map(checkbox => (
          <div key={checkbox.id}>
            <div className='relative flex items-start'>
              <div className='flex h-6 items-center'>
                <input
                  type='checkbox'
                  value={checkbox.value}
                  checked={checkedIds.includes(Number(checkbox.value))}
                  onChange={handleCheckboxChange}
                  id={checkbox.value}
                  className='h-4 w-4 rounded border-cego-lightbrown text-cego-black focus:ring-cego-black cursor-pointer'
                />
              </div>
              <div className='ml-3 text-sm leading-6'>
                <label htmlFor={checkbox.value} className='font-medium text-gray-900'>
                  {checkbox.label}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Render button to execute mutation */}
      <br />
      <Button
        quaternary
        onClick={e => {
          e.preventDefault();
          onClick(checkedIds, value);
          setCheckedIds([]);
        }}
      >
        Notificaties versturen{' '}
        <span className='inline ml-1'>
          <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
        </span>
      </Button>
    </div>
  );
};

export default CheckboxComponent;
