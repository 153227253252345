import { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface ComboboxItem {
  id: number | string;
  name: string;
  active?: boolean;
}

interface Props {
  selected?: ComboboxItem;
  setSelected?: (item: ComboboxItem) => void;
  query?: string;
  setQuery?: (value: string) => void;
  items: ComboboxItem[];
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
}

const ComboboxComponent: React.FC<Props> = ({ selected, setSelected, query, setQuery, items, placeholder, autoComplete, disabled }) => {
  const [localQuery, setLocalQuery] = useState<string>('');

  const parsedItems = setQuery ? items : items.filter(item => item.name.toLocaleLowerCase().includes(localQuery.toLocaleLowerCase()));

  return (
    <Combobox value={selected} onChange={setSelected} disabled={disabled}>
      <div className='relative'>
        <div className='relative w-full '>
          <Combobox.Input
            className={classNames(
              'w-full shadow-sm focus:ring-cego-lightbrown focus:border-cego-lightbrown text-base font-light border-cego-lightbrown rounded-sm py-2.5',
              disabled ? 'bg-gray-100 text-gray-500' : '',
            )}
            displayValue={(item: ComboboxItem) => item.name}
            onChange={event => (setQuery ? setQuery(event.target.value) : setLocalQuery(event.target.value))}
            placeholder={placeholder}
            autoComplete={autoComplete}
            aria-disabled={disabled}
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon className='h-4 w-4 text-cego-darkgray' aria-hidden='true' />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => (setQuery ? setQuery('') : setLocalQuery)}
        >
          <Combobox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-sm shadow-lg max-h-60 ring-1 ring-cego-lightbrown ring-opacity-5 focus:outline-none z-30'>
            {parsedItems.length === 0 && query !== '' ? (
              <div className='cursor-default select-none relative py-2 px-4 text-cego-black'>Niets gevonden.</div>
            ) : (
              parsedItems.map(person => (
                <Combobox.Option
                  key={person.id}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? ' bg-cego-lightgray' : 'text-cego-black'}`
                  }
                  value={person}
                >
                  {({ selected: nestedSelected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        <span className={!person.active ? 'text-cego-semigray' : ''}>{person.name}</span>
                      </span>
                      {nestedSelected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-cego-lightgreen'}`}
                        >
                          <CheckIcon className='w-5 h-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export { ComboboxComponent as Combobox };
