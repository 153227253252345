/* eslint-disable react-hooks/rules-of-hooks */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';

interface FileUploadProps {
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
  value: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ selectedFile, setSelectedFile, value }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [imageString, setImageString] = useState(value);

  const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    setImageString(URL.createObjectURL(file));
  }, []);

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setImageString('');
  }, []);

  return (
    <div
      className={`dropzone ${isDragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={event => event.preventDefault()}
      onDrop={handleDrop}
    >
      <button
        type='button'
        className='relative block w-full rounded-sm border border-dashed border-cego-lightbrown p-5 text-center hover:border-cego-lightbrown focus:outline-none focus:ring-2 focus:ring-cego-lightbrown focus:ring-offset-2'
      >
        <div
          className='p-24'
          style={{
            backgroundImage: `url(${imageString})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          {!imageString && (
            <div
              className='absolute text-center'
              style={{
                left: '0',
                right: '0',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '50%',
                height: '50%',
                marginTop: 'auto',
                marginBottom: 'auto',
                top: '0',
                bottom: '0',
              }}
            >
              <div
                className='absolute'
                style={{
                  left: '0',
                  right: '0',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '100%',
                  height: '64%',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  top: '0',
                  bottom: '0',
                }}
              >
                <PhotoIcon className='mx-auto h-12 w-12 text-cego-lightbrown' aria-hidden='true' />
                <p className='text-cego-lightbrown font-light text-sm'>Darg en drop je afbeelding</p>
              </div>
            </div>
          )}
        </div>
      </button>

      {imageString && imageString !== '' ? (
        <p
          className='hover:cursor-pointer underline text-cego-darkgray pt-1 text-sm font-normal hover:text-cego-lightbrown mb-1'
          onClick={handleRemoveFile}
        >
          Verwijder
        </p>
      ) : (
        <>
          {isDragging ? (
            <p className='text-cego-darkgray pt-1 text-sm font-normal mb-1'>Drop hier je bestand</p>
          ) : (
            <p className='text-cego-darkgray pt-1 text-sm font-normal mb-1'>Sleep hier je bestand naartoe</p>
          )}
        </>
      )}
    </div>
  );
};

export { FileUpload };
