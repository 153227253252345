import { Dialog } from '@headlessui/react';
import { TrashIcon, PlayIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { classNames } from '../utils';
import Button from './tailwind/button';
import { Input } from './tailwind/input';

interface Props {
  title: string;
  text: string;
  handleConfirm: () => void;
  setIsOpenDelete: (isOpen: boolean) => void;
}

interface Props2 {
  title: string;
  text: string;
  handleConfirm: (subject: string, body: string) => void;
  setIsOpenDelete: (isOpen: boolean) => void;
}

export const ConfirmationForm: React.FC<Props> = ({ title, text, handleConfirm, setIsOpenDelete }) => {
  return (
    <div className='self-center px-8'>
      <div className='sm:flex sm:items-start mb-6'>
        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
          <ExclamationTriangleIcon className='h-6 w-6 text-cego-orange' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <Dialog.Title as='h3' className='text-lg font-semibold leading-6 mb-4 text-gray-900'>
            {title}
          </Dialog.Title>
          <div className='mt-2'>
            <p className='text-base text-cego-darkgray'>{text}</p>
          </div>
        </div>
      </div>

      <div className='flex justify-end space-x-2'>
        <Button
          tertiary
          onClick={() => {
            setIsOpenDelete(false);
          }}
        >
          {'Annuleren'}{' '}
        </Button>

        <Button
          primary
          onClick={() => {
            handleConfirm();
          }}
        >
          {'Bevestigen'}{' '}
          <span className='inline ml-1'>
            <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
          </span>
        </Button>
      </div>
    </div>
  );
};

export const ConfirmationAnulationForm: React.FC<Props2> = ({ title, text, handleConfirm, setIsOpenDelete }) => {
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');
  return (
    <div className='self-center px-8 pt-8'>
      <div className='sm:flex sm:items-start mb-6'>
        <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
          <ExclamationTriangleIcon className='h-6 w-6 text-cego-orange' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <Dialog.Title as='h3' className='text-lg font-semibold leading-6 mb-4 text-gray-900'>
            {title}
          </Dialog.Title>
          <div className='mt-2'>
            <p className='text-base text-cego-darkgray'>{text}</p>
          </div>
        </div>
      </div>
      <div className='pt-4'>
        <label htmlFor={'random78'} className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>
          Onderwerp
        </label>
        <Input
          type={'text'}
          id={'random78'}
          value={subject}
          className=''
          onChange={(e: any) => {
            setSubject(e.target.value);
          }}
        />
      </div>
      <div className='pt-4'>
        <label className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>Body</label>
        <Editor
          apiKey='y8v65wfkd6uxajrh03buxibzkyrxwywwe3ggcccybjg1ry4d'
          init={{
            height: 317,
            menubar: true,
            menu: {
              file: { title: 'File', items: 'newdocument' },
              edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
              insert: { title: 'Insert', items: 'link emplate' },
              format: { title: 'Format', items: 'bold italic underline' },
            },
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
          }}
          value={body}
          onEditorChange={e => {
            setBody(e);
          }}
        />
      </div>

      <div className='flex justify-end space-x-2 pt-4'>
        <Button
          tertiary
          onClick={() => {
            setIsOpenDelete(false);
          }}
        >
          {'Annuleren'}{' '}
        </Button>

        <Button
          primary
          onClick={() => {
            handleConfirm(subject, body);
          }}
        >
          {'Bevestigen'}{' '}
          <span className='inline ml-1'>
            <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
          </span>
        </Button>
      </div>
    </div>
  );
};
