import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IEntitiy } from './entity';

export interface IActivityType {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  title?: string;
}

export const GET_ACTIVITY_TYPES = gql`
  query getActivityTypes($filter: ActivityTypeFilterInput, $take: Int, $skip: Int, $orderBy: ActivityTypeOrderByInput) {
    findManyActivityTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      title
    }
  }
`;

export const convertActivityTypesToComboBoxOptions = (activityTypes: IActivityType[]) => {
  if (activityTypes) {
    const result: IComboBoxOption[] = activityTypes.map(
      (activityType: IActivityType): IComboBoxOption => ({
        id: activityType.id as any,
        name: `${activityType.title}`,
      }),
    );
    return result;
  }

  return [];
};
