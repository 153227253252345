import { useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { classNames } from '../../utils';
import Button from '../../components/tailwind/button';
import { Input } from '../../components/tailwind/input';

export const Prestaties: React.FC = ({ ...props }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [eventFilter, setEventFilter] = useState({
    id: '',
    type: 'event',
    startTime: '',
    endTime: '',
  });

  const [proposalFilter, setProposalFilter] = useState({
    id: '',
    type: 'proposal',
    startTime: '',
    endTime: '',
  });

  const handleDownloadEventPrestatiesExcel = async () => {
    const token = await getAccessTokenSilently();
    const fileName = `evenement_prestaties_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(`${process.env.REACT_APP_FILE_URI}/files/eventprestaties/xls`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        ...eventFilter,
        id: user?.sub,
      }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    setEventFilter({
      id: '',
      type: 'event',
      startTime: '',
      endTime: '',
    });
  };

  const handleDownloadAanvraagPrestatiesExcel = async () => {
    const token = await getAccessTokenSilently();
    const fileName = `aanvraag_prestaties_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(`${process.env.REACT_APP_FILE_URI}/files/proposalprestaties/xls`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        ...proposalFilter,
        id: user?.sub,
      }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    setProposalFilter({
      id: '',
      type: 'proposal',
      startTime: '',
      endTime: '',
    });
  };

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Prestaties</h1>
      </header>
      <div className='bg-cego-lightgray rounded-sm p-4 my-2'>
        <h2 className='text-md mb-2 font-normal'>Evenementen</h2>
        <div className='flex'>
          <div className='prs-2'>
            <label htmlFor={'date1'} className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>
              {'Start datum'}
            </label>
            <Input
              type={'date'}
              id={'date1'}
              value={eventFilter.startTime}
              onChange={e => setEventFilter({ ...eventFilter, startTime: e.target.value })}
            />
          </div>
          <div className='pl-2'>
            <label htmlFor={'date2'} className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>
              {'Eind datum'}
            </label>
            <Input
              type={'date'}
              id={'date2'}
              value={eventFilter.endTime}
              onChange={e => setEventFilter({ ...eventFilter, endTime: e.target.value })}
            />
          </div>
          <div className='pl-2'>
            <Button
              primary
              className='mt-7'
              onClick={() => {
                handleDownloadEventPrestatiesExcel();
              }}
              disabled={eventFilter.startTime === '' || eventFilter.endTime === '' || isLoading || !isAuthenticated}
            >
              {'Download'}{' '}
              <span className='inline ml-1'>
                <FaFileExcel className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          </div>
        </div>
      </div>

      <div className='bg-cego-lightgray rounded-sm p-4 mt-6'>
        <h2 className='text-md mb-2 font-normal'>Aanvragen</h2>
        <div className='flex'>
          <div className='prs-2'>
            <label htmlFor={'date3'} className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>
              {'Start datum'}
            </label>
            <Input
              type={'date'}
              id={'date3'}
              value={proposalFilter.startTime}
              onChange={e => setProposalFilter({ ...proposalFilter, startTime: e.target.value })}
            />
          </div>
          <div className='pl-2'>
            <label htmlFor={'date4'} className={classNames('text-cego-darkgray', 'block ml-0.5 mb-0.5 text-xs font-medium leading-6')}>
              {'Eind datum'}
            </label>
            <Input
              type={'date'}
              id={'date4'}
              value={proposalFilter.endTime}
              onChange={e => setProposalFilter({ ...proposalFilter, endTime: e.target.value })}
            />
          </div>
          <div className='pl-2'>
            <Button
              className='mt-7'
              primary
              onClick={() => {
                handleDownloadAanvraagPrestatiesExcel();
              }}
              disabled={proposalFilter.startTime === '' || proposalFilter.endTime === '' || isLoading || !isAuthenticated}
            >
              {'Download'}{' '}
              <span className='inline ml-1'>
                <FaFileExcel className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prestaties;
