import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';

export interface IAccount {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  code?: string;
  name?: string;
  active?: boolean;
  proposals?: any[];
  products?: any[];
}

export const GET_ACCOUNTS = gql`
  query getAccounts($filter: AccountFilterInput, $skip: Int, $take: Int) {
    findManyAccounts(filter: $filter, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      code
      name
      active
    }
  }
`;

// full object for detail page
export const GET_ACCOUNT = gql`
  query getAccount($where: AccountWhereInput) {
    findOneAccount(where: $where) {
      createdAt
      updatedAt
      id
      code
      name
      active
      products {
        id
        name
        vatPercentage
        price
        active
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($data: AccountCreateInput!) {
    createAccount(data: $data) {
      createdAt
      updatedAt
      id
      code
      name
      active
      products {
        id
        name
        vatPercentage
        price
        active
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($id: Int!, $data: AccountUpdateInput) {
    updateAccount(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      code
      active
      name
      products {
        id
        name
        vatPercentage
        price
        active
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($where: AccountWhereInput) {
    deleteAccount(where: $where) {
      id
    }
  }
`;

export const convertAccountsToComboBoxOptions = (products: IAccount[]) => {
  if (!products) return [];

  const uniqueProductIds = new Set();
  return products
    .filter(product => {
      if (uniqueProductIds.has(product.id)) return false;
      uniqueProductIds.add(product.id);
      return true;
    })
    .map(
      (product: IAccount): IComboBoxOption => ({
        id: product.id as any,
        name: `${product.name}`,
        active: product.active,
      }),
    );
};
