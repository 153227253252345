import { gql } from '@apollo/client';
import { IEvent } from './event';
import { ITicket } from './ticket';
import { IAttendee } from './attendee';
import { IOrder } from './orders';
import { ISessionType } from './sessionType';

export interface ISession {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  event: IEvent;
  eventID: number;
  attendee: IAttendee;
  attendeeID: number;
  order: IOrder;
  orderID: number;
  price: number;
  status: string;
  sessionType: ISessionType;
  sessionTypeID: number;
}

export const UPDATE_SESSION = gql`
  mutation updateSession($id: Int!, $data: SessionUpdateInput) {
    updateSession(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      status

      sessionType {
        id
        name
        price
      }

      eventID
    }
  }
`;
