import { gql } from '@apollo/client';
import { IEvent } from './event';

export interface ISessionType {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eventID?: number;
  event?: IEvent;

  price: number;
  stock: number;
  actualStock: number;
  amountSold: number;
  name: string;
  code: string;

  criticalStockLevel?: number;
  availableFrom?: Date;
  availableTill?: Date;
}

export interface ICreateSessionType {
  price?: number;
  stock?: number;
  name?: string;
  code?: string;
}

export interface IUpdateSessionType {
  price?: number;
  stock?: number;
  name?: string;
  code?: string;
}

export const GET_SESSIONTYPES = gql`
  query getSessionTypes($filter: SessionTypeFilterInput, $take: Int, $skip: Int, $orderBy: SessionTypeOrderByInput) {
    findManySessionTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const GET_SESSIONTYPE = gql`
  query getSessionType($where: SessionTypeWhereInput) {
    findOneSessionType(where: $where) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      eventID

      event {
        id
      }

      price
      stock
      actualStock
      amountSold
      name
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const CREATE_SESSIONTYPE = gql`
  mutation createSessionType($data: SessionTypeCreateInput!) {
    createSessionType(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const UPDATE_SESSIONTYPE = gql`
  mutation updateSessionType($id: Int!, $data: SessionTypeUpdateInput) {
    updateSessionType(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      stock
      actualStock
      amountSold
      name
      code

      criticalStockLevel
      availableFrom
      availableTill
    }
  }
`;

export const DELETE_SESSIONTYPE = gql`
  mutation deleteSessionType($where: SessionTypeWhereInput) {
    deleteSessionType(where: $where) {
      id
    }
  }
`;
