import { get } from 'http';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { set } from 'lodash';
import { CheckIcon, PlayIcon, TicketIcon, HomeModernIcon, CurrencyEuroIcon, UserIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { GET_EVENTS, IEvent, convertEventsToComboBoxOptions } from '../utils/event';
import {
  DEFAULT_DATE_FORMAT_LONG,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TAKE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  resolveMoney,
  resolveValueForCombobox,
} from '../utils';
import { convertEntitiesToComboBoxOptions } from '../utils/entity';
import { GET_TICKETTYPES, ITicketType } from '../utils/ticketType';
import { GET_CUSTOMERS, ICustomer, convertCustomersToComboBoxOptions } from '../utils/customer';
import { GET_SESSIONTYPES, ISessionType } from '../utils/sessionType';
import { GET_ROOMTYPES, IRoomType } from '../utils/roomType';
import { CREATE_ORDER_WEBSITE } from '../utils/orders';
import { toastError, toastSuccess } from '../utils/toast';
import { IComboBoxOption } from '../types';
import { IAttendee } from '../utils/attendee';
import { Input } from './tailwind/input';
import Button from './tailwind/button';
import { IColumn, Table } from './tailwind/table';
import { Textarea } from './tailwind/textarea';
import { Combobox } from './tailwind/comboBox';
import useDebounce from './tailwind/hooks/useDebounce';
import { Switch } from './tailwind/switch';

interface Props {
  schema: any;
}

export const OrderCreateForm: React.FC<any> = ({ refetchOrders, setOpenOrderCreate, date }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [disableInvoice, setDisableInvoice] = useState(false);
  const [selectedContact, setSelectedContact] = useState<
    { firstName?: string; lastName?: string; email?: string; phone?: string; comments?: string } | undefined
  >(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | undefined>(undefined);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | undefined>(undefined);

  const [attendeesToCreate, setAttendeesToCreate] = useState<
    {
      firstName?: string;
      lastName?: string;
      email?: string;
      ticketType?: ITicketType;
      sessionTypes?: ISessionType[];
      phone?: string;
      sessions?: number[];
      extraAttendees?: any[];
    }[]
  >([]);

  const [roomsToCreate, setRoomsToCreate] = useState<IRoomType[]>([]);

  const [steps, setSteps] = useState<any[]>([
    { id: 1, name: 'Evenement', href: '#', status: 'current' },
    { id: 2, name: 'Deelnemers ', href: '#', status: 'upcoming' },
    { id: 3, name: 'Kamers', href: '#', status: 'upcoming' },
    { id: 4, name: 'Overzicht', href: '#', status: 'upcoming' },
  ]);

  const [createOrder] = useMutation(CREATE_ORDER_WEBSITE);

  // -------------------------------------- DEBOUNCE COMBOBOX --------------------------------------
  const [eventQueryString, setEventQueryString] = useState<string>('');
  const [debounceEventQueryString, setDebounceEventQueryString] = useState<string>('');
  const debouncedEventQuery = useDebounce(eventQueryString, 500);

  useEffect(() => {
    setDebounceEventQueryString(debouncedEventQuery);
  }, [debouncedEventQuery]);

  const getFiltersEventQuery = (filterString: string, selectedEvent: IEvent | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [
        {
          startTime: {
            gte: date,
          },
          closed: {
            not: true,
          },
        },
      ],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        AND: [
          {
            activity: {
              title: { contains: filterArray[i] },
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedEvent && selectedEvent.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedEvent.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataEvents,
    loading: loadingEvents,
    error: errorEvents,
    refetch: refetchEvents,
    fetchMore: fetchMoreEvents,
  } = useQuery(GET_EVENTS, {
    variables: {
      filter: getFiltersEventQuery(debounceEventQueryString, selectedEvent),
      take: DEFAULT_TAKE,
    },
  });

  // ------------------------------------------------------------------------------------------------

  // -------------------------------------- DEBOUNCE COMBOBOX --------------------------------------
  const [customerQueryString, setCustomerQueryString] = useState<string>('');
  const [debounceCustomerQueryString, setDebounceCustomerQueryString] = useState<string>(' ');
  const debouncedCustomerQuery = useDebounce(customerQueryString, 500);

  useEffect(() => {
    setDebounceCustomerQueryString(debouncedCustomerQuery);
  }, [debouncedCustomerQuery]);

  const getFiltersCustomerQuery = (filterString: string, selectedCustomer: ICustomer | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ institution: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedCustomer && selectedCustomer.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedCustomer.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataCustomers,
    loading: loadingCustomers,
    error: errorCustomers,
    refetch: refetchCustomers,
    fetchMore: fetchMoreCustomers,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      filter: getFiltersCustomerQuery(debounceCustomerQueryString, selectedCustomer),
      take: DEFAULT_TAKE,
    },
  });
  // ------------------------------------------------------------------------------------------------

  return (
    <>
      <div className='flex justify-around py-4'>
        <nav aria-label='Progress'>
          <ol role='list' className='flex items-center'>
            {steps.map((step, stepIdx) => (
              <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
                {step.status === 'complete' ? (
                  <>
                    <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                      <div className='h-0.5 w-full bg-cego-darkgray' />
                    </div>
                    <span
                      onClick={() => {
                        setSteps(
                          steps.map(s => {
                            if (s.id === step.id) {
                              return { ...s, status: 'current' };
                            } else if (s.id < step.id) {
                              return { ...s, status: 'complete' };
                            } else {
                              return { ...s, status: 'upcoming' };
                            }
                          }),
                        );
                      }}
                      className='relative flex h-10 w-10 items-center justify-center rounded-full bg-cego-darkgray hover:bg-cego-darkgray'
                    >
                      <CheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      <span className='sr-only'>{step.name}</span>
                    </span>
                  </>
                ) : step.status === 'current' ? (
                  <>
                    <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                      <div className='h-0.5 w-full bg-cego-lightbrown' />
                    </div>
                    <span
                      className='relative flex h-10 w-10 items-center justify-center rounded-full border-2 border-cego-darkgray bg-white'
                      aria-current='step'
                    >
                      <span className='h-4 w-4 rounded-full bg-cego-darkgray' aria-hidden='true' />
                      <span className='sr-only'>{step.name}</span>
                    </span>
                  </>
                ) : (
                  <>
                    <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                      <div className='h-0.5 w-full bg-cego-lightbrown' />
                    </div>
                    <span className='group relative flex h-10 w-10 items-center justify-center rounded-full border-2 border-cego-lightbrown bg-white hover:border-cego-lightdarkgray'>
                      <span className='h-4 w-4 rounded-full bg-transparent group-hover:bg-cego-lightbrown' aria-hidden='true' />
                      <span className='sr-only'>{step.name}</span>
                    </span>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      {steps.some(step => step.id === 1 && step.status === 'current') && (
        <>
          <Dialog.Title className='mb-6 mt-6'>
            <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4 text-center'>Stap 1</h1>
            <p className='mb-4 text-center'>Selecteer een vorming en een klant</p>
          </Dialog.Title>
          {!errorEvents && !errorCustomers && (
            <>
              <div className='flex flex-wrap justify-between'>
                <div className='py-2.5' style={{ width: `100%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Evenementen'}
                  </label>

                  <Combobox
                    items={convertEventsToComboBoxOptions(
                      dataEvents && dataEvents.findManyEvents && dataEvents.findManyEvents.length > 0 ? dataEvents.findManyEvents : [],
                    )}
                    query={eventQueryString}
                    setQuery={setEventQueryString}
                    selected={
                      selectedEvent
                        ? {
                            id: selectedEvent?.id as any,
                            name: `${selectedEvent?.activity?.title}  | ${selectedEvent?.location.name} [${moment(
                              selectedEvent?.startTime,
                            ).format('DD/MM/YYYY')}]`,
                          }
                        : undefined
                    }
                    setSelected={(e: any) => {
                      setSelectedEvent(dataEvents.findManyEvents.find((item: any) => item.id.toString() === e.id.toString()));
                    }}
                  />
                </div>
                <div className='py-2.5' style={{ width: `100%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Klanten'}
                  </label>

                  <Combobox
                    items={convertCustomersToComboBoxOptions(
                      dataCustomers && dataCustomers.findManyCustomers && dataCustomers.findManyCustomers.length > 0
                        ? dataCustomers.findManyCustomers
                        : [],
                    )}
                    query={customerQueryString}
                    setQuery={setCustomerQueryString}
                    selected={
                      selectedCustomer
                        ? ({ id: selectedCustomer?.id as any, name: selectedCustomer?.institution } as IComboBoxOption)
                        : undefined
                    }
                    setSelected={(e: any) => {
                      setSelectedCustomer(dataCustomers.findManyCustomers.find((item: any) => item.id.toString() === e.id.toString()));
                    }}
                  />
                </div>
                <div style={{ width: `48.5%` }}>
                  {selectedEvent && (
                    <div className='p-2.5 border border-cego-lightbrown rounded-sm'>
                      <dt className='font-medium text-gray-900'>Vorming</dt>
                      <dd className='mt-2 text-gray-500'>
                        <span className='block font-medium'>{selectedEvent?.activity?.title}</span>
                        <span className='block text-sm'>
                          {selectedEvent?.location.address} {selectedEvent?.location.zipcode} {selectedEvent?.location.city}
                        </span>
                        <span className='block text-sm'>
                          {selectedEvent?.startTime ? moment(selectedEvent.startTime).format(DEFAULT_DATE_FORMAT_LONG) : ''}
                          {' - '}
                          {selectedEvent?.endTime ? moment(selectedEvent.endTime).format(DEFAULT_DATE_FORMAT_LONG) : ''}
                        </span>
                      </dd>
                    </div>
                  )}
                </div>
                <div style={{ width: `48.5%` }}>
                  {selectedCustomer && (
                    <div className='p-2.5 border border-cego-lightbrown rounded-sm'>
                      <dt className='font-medium text-gray-900'>Klant</dt>
                      <dd className='mt-2 text-gray-500'>
                        <span className='block font-medium'>
                          {selectedCustomer?.institution} ({selectedCustomer?.institutionID})
                        </span>
                        <span className='block text-sm'>
                          {selectedCustomer?.address.address} {selectedCustomer?.address.zipcode} {selectedCustomer?.address.city}
                        </span>
                        <span className='block text-sm'>{selectedCustomer?.email}</span>
                      </dd>
                    </div>
                  )}
                </div>
                <div className='py-2.5' style={{ width: `48.5%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Voornaam'}
                  </label>
                  <Input
                    type={'text'}
                    id={'firstName'}
                    value={selectedContact?.firstName ? selectedContact?.firstName : ''}
                    onChange={e => {
                      setSelectedContact({ ...selectedContact, firstName: e.target.value });
                    }}
                  />
                </div>

                <div className='py-2.5' style={{ width: `48.5%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Achternaam'}
                  </label>
                  <Input
                    type={'text'}
                    id={'lastName'}
                    value={selectedContact?.lastName ? selectedContact?.lastName : ''}
                    onChange={e => {
                      setSelectedContact({ ...selectedContact, lastName: e.target.value });
                    }}
                  />
                </div>

                <div className='py-2.5' style={{ width: `48.5%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Email'}
                  </label>
                  <Input
                    type={'text'}
                    id={'email'}
                    value={selectedContact?.email ? selectedContact?.email : ''}
                    onChange={e => {
                      setSelectedContact({ ...selectedContact, email: e.target.value });
                    }}
                  />
                </div>

                <div className='py-2.5' style={{ width: `48.5%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Telefoon'}
                  </label>
                  <Input
                    type={'text'}
                    id={'phone'}
                    value={selectedContact?.phone ? selectedContact?.phone : ''}
                    onChange={e => {
                      setSelectedContact({ ...selectedContact, phone: e.target.value });
                    }}
                  />
                </div>

                <div className='py-2.5' style={{ width: `100%` }}>
                  <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                    {'Opmerkingen'}
                  </label>
                  <Textarea
                    id={'comments'}
                    value={selectedContact?.comments ? selectedContact?.comments : ''}
                    onChange={e => {
                      setSelectedContact({ ...selectedContact, comments: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={classNames('py-4 flex justify-end')}>
                <Button
                  disabled={
                    selectedCustomer &&
                    selectedContact &&
                    selectedContact.firstName &&
                    selectedContact.lastName &&
                    selectedContact.email &&
                    selectedEvent
                      ? false
                      : true
                  }
                  quaternary
                  onClick={() => {
                    setSteps([
                      { id: 1, name: 'Evenement', href: '#', status: 'complete' },
                      { id: 2, name: 'Deelnemers ', href: '#', status: 'current' },
                      { id: 3, name: 'Kamers', href: '#', status: 'upcoming' },
                      { id: 4, name: 'Overzicht', href: '#', status: 'upcoming' },
                    ]);
                  }}
                >
                  {'Volgende'}{' '}
                  <span className='inline ml-1'>
                    <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                  </span>
                </Button>
              </div>
            </>
          )}
          {loadingEvents && (
            <div className='h-5/6 flex items-center'>
              <div className={classNames('sweet-loading text-center w-full')}>
                <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
              </div>
            </div>
          )}
          {errorEvents && <p>Error</p>}
        </>
      )}
      {steps.some(step => step.id === 2 && step.status === 'current') && (
        <>
          <Dialog.Title className='mb-6 mt-6'>
            <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4 text-center'>Stap 2</h1>
            <p className='mb-4 text-center'>Voeg deelnemers, tickets en optioneel sessies toe.</p>
          </Dialog.Title>
          <StepTwo
            setSteps={setSteps}
            steps={steps}
            selectedEvent={selectedEvent}
            setAttendeesToCreate={setAttendeesToCreate}
            attendeesToCreate={attendeesToCreate}
          ></StepTwo>
        </>
      )}
      {steps.some(step => step.id === 3 && step.status === 'current') && (
        <>
          <Dialog.Title className='mb-6 mt-6'>
            <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4 text-center'>Stap 3</h1>
            <p className='mb-4 text-center'>Voeg optioneel kamers toe.</p>
          </Dialog.Title>
          <StepThree
            setSteps={setSteps}
            steps={steps}
            selectedEvent={selectedEvent}
            setRoomsToCreate={setRoomsToCreate}
            roomsToCreate={roomsToCreate}
          ></StepThree>
        </>
      )}
      {steps.some(step => step.id === 4 && step.status === 'current') && (
        <>
          <Dialog.Title className='mb-6 mt-6'>
            <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4 text-center'>Stap 4</h1>
            <p className='mb-4 text-center'>Finaal overzicht, maak bestelling definitief.</p>
          </Dialog.Title>
          <div className=''>
            <div className='flex justify-between'>
              <div className='' style={{ width: '48.5%' }}>
                <div className='p-2.5 border border-cego-lightbrown rounded-sm'>
                  <dt className='font-medium text-gray-900'>Vorming</dt>
                  <dd className='mt-2 text-gray-500'>
                    <span className='block font-medium'>{selectedEvent?.activity?.title}</span>
                    <span className='block text-sm'>
                      {selectedEvent?.location.address} {selectedEvent?.location.zipcode} {selectedEvent?.location.city}
                    </span>
                    <span className='block text-sm'>
                      {selectedEvent?.startTime ? moment(selectedEvent.startTime).format(DEFAULT_DATE_FORMAT_LONG) : ''}
                      {' - '}
                      {selectedEvent?.endTime ? moment(selectedEvent.endTime).format(DEFAULT_DATE_FORMAT_LONG) : ''}
                    </span>
                  </dd>
                </div>
              </div>
              <div className='' style={{ width: '48.5%' }}>
                <div className='p-2.5 border border-cego-lightbrown rounded-sm'>
                  <dt className='font-medium text-gray-900'>Klant</dt>
                  <dd className='mt-2 text-gray-500'>
                    <span className='block font-medium'>
                      {selectedCustomer?.institution} ({selectedCustomer?.institutionID})
                    </span>
                    <span className='block text-sm'>
                      {selectedCustomer?.address.Address} {selectedCustomer?.address.zipcode} {selectedCustomer?.address.city}
                    </span>
                    <span className='block text-sm'>{selectedCustomer?.email}</span>
                  </dd>
                </div>
              </div>
            </div>
          </div>
          <div>
            <br />
            <p>Facturatie uitschakelen</p>
            <Switch
              checked={disableInvoice}
              onChange={(e: any) => {
                setDisableInvoice(e);
              }}
            />
          </div>

          <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium mt-3 leading-6'>
            {'Deelnemers'}
          </label>
          <div className='rounded-sm border border-cego-lightbrown px-4 py-2'>
            {attendeesToCreate.map((attendee, index) => (
              <>
                <div key={JSON.stringify(attendee)} className='flex justify-between py-2'>
                  <div style={{ width: '32%' }}>
                    <UserIcon className='h-5 w-5 text-cego-semigray inline mr-1.5' aria-hidden='true' /> {attendee.firstName}{' '}
                    {attendee.lastName}{' '}
                    {attendee.extraAttendees && attendee.extraAttendees.length > 0
                      ? attendee.extraAttendees.map((person: any) => `, ${person.firstName} ${person.lastName}`)
                      : ''}
                  </div>

                  <div style={{ width: '32%' }} className='flex justify-around'>
                    <div>
                      <TicketIcon className='h-5 w-5 text-cego-semigray inline mr-1.5' aria-hidden='true' /> {attendee?.ticketType?.name}
                    </div>
                  </div>

                  <div style={{ width: '32%' }} className='text-right'>
                    {attendee?.ticketType?.price ? resolveMoney(attendee?.ticketType?.price) : ''}
                  </div>
                </div>
                <div className='px-8'>
                  {attendee?.sessionTypes?.map((sessionType, index) => (
                    <div key={JSON.stringify(sessionType)} className='text-sm py-1.5 text-cego-darkgray'>
                      - {sessionType.name}
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>

          {roomsToCreate.length > 0 && (
            <>
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium mt-3 leading-6'>
                {'Kamers'}
              </label>
              <div className='rounded-sm border border-cego-lightbrown px-4 py-2'>
                {roomsToCreate.map((room, index) => (
                  <div key={JSON.stringify(room)} className='flex justify-between py-2'>
                    <div style={{ width: '32%' }}>
                      <HomeModernIcon className='h-5 w-5 text-cego-semigray inline mr-1.5' aria-hidden='true' /> {room?.name}
                    </div>

                    <div style={{ width: '32%' }} className='text-right'>
                      {room.price ? resolveMoney(room?.price) : ''}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className='text-right text-cego-darkgray py-3'>
            <div>
              <span className='font-bold'>Totaal:</span>{' '}
              <span className='text-cego-black'>
                {resolveMoney(
                  attendeesToCreate.reduce((a, b) => a + (b.ticketType?.price || 0), 0) +
                    roomsToCreate.reduce((a, b) => a + (b.price || 0), 0),
                )}
              </span>
            </div>
          </div>

          <div className={classNames('flex justify-end py-4')}>
            <Button
              primary
              onClick={async () => {
                const orderObj: any = {
                  firstName: selectedContact?.firstName ? selectedContact?.firstName : '',
                  lastName: selectedContact?.lastName ? selectedContact?.lastName : '',
                  email: selectedContact?.email ? selectedContact?.email : '',
                  phone: selectedContact?.phone ? selectedContact?.phone : '',
                  comments: selectedContact?.comments ? selectedContact?.comments : '',
                  customer: {
                    connect: {
                      id: selectedCustomer?.id,
                    },
                  },
                  event: {
                    connect: {
                      id: selectedEvent?.id, //
                    },
                  },
                  rooms: [],
                  attendees: [],
                };

                for (let i = 0; i < roomsToCreate.length; i++) {
                  const element: any = roomsToCreate[i];

                  orderObj.rooms.push({
                    create: {
                      event: {
                        connect: {
                          id: selectedEvent?.id,
                        },
                      },
                      roomType: {
                        connect: {
                          id: element?.id,
                        },
                      },
                    },
                  });
                }

                for (let i = 0; i < attendeesToCreate.length; i++) {
                  const element: any = attendeesToCreate[i];

                  const attendeeObj: any = {
                    create: {
                      firstName: element.firstName,
                      lastName: element.lastName,
                      email: element.email,
                      phone: element.phone,
                      event: {
                        connect: {
                          id: selectedEvent?.id,
                        },
                      },
                      tickets: [
                        {
                          create: {
                            event: {
                              connect: {
                                id: selectedEvent?.id,
                              },
                            },
                            ticketType: {
                              connect: {
                                id: element.ticketType?.id,
                              },
                            },
                          },
                        },
                      ],
                      extraAttendees: element.extraAttendees ? element.extraAttendees : [],
                    },
                  };

                  if (element.sessionTypes && element.sessionTypes.length > 0) {
                    attendeeObj.create.sessions = [];

                    for (let j = 0; j < element.sessionTypes.length; j++) {
                      const sessionType: any = element.sessionTypes[j];

                      attendeeObj.create.sessions.push({
                        create: {
                          event: {
                            connect: {
                              id: selectedEvent?.id,
                            },
                          },
                          sessionType: {
                            connect: {
                              id: sessionType.id,
                            },
                          },
                        },
                      });
                    }
                  }

                  orderObj.attendees.push(attendeeObj);
                }

                try {
                  const res = await toast.promise(
                    new Promise((resolve, reject) => {
                      if (orderObj) {
                        console.log(orderObj);

                        createOrder({
                          variables: {
                            data: orderObj,
                            disableInvoice: disableInvoice,
                          },
                          onCompleted: (data: any) => {
                            resolve(data);
                          },
                          onError: (error: any) => {
                            reject(error);
                          },
                        });
                      }
                    }),
                    {
                      pending: {
                        position: DEFAULT_TOAST_POSITION,
                        render() {
                          return DEFAULT_LOADING_MESSAGE;
                        },
                      },
                      error: {
                        position: DEFAULT_TOAST_POSITION,
                        render() {
                          return DEFAULT_ERROR_MESSAGE;
                        },
                        icon: FiAlertCircle,
                      },
                    },
                    {
                      autoClose: DEFAULT_TOAST_DURATION,
                    },
                  );
                  // refetchEvent();
                  refetchOrders();
                  toastSuccess('Bestelling succesvol aangemaakt');
                  setOpenOrderCreate(false);
                } catch (error) {
                  const message =
                    error &&
                    (error as any).graphQLErrors &&
                    (error as any).graphQLErrors.length > 0 &&
                    (error as any).graphQLErrors[0].message
                      ? (error as any).graphQLErrors[0].message
                      : (error as any).message
                      ? (error as any).message
                      : DEFAULT_ERROR_MESSAGE;
                  toastError(message);
                }
              }}
            >
              {'Opslaan'}{' '}
              <span className='inline ml-1'>
                <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export const StepTwo: React.FC<{
  setSteps: (steps: any[]) => void;
  steps: any[];
  selectedEvent: IEvent | undefined;
  setAttendeesToCreate?: (attendees: any) => void;
  attendeesToCreate?: any;
}> = ({ setSteps, steps, selectedEvent, setAttendeesToCreate, attendeesToCreate }) => {
  const [selectedTicketType, setSelectedTicketType] = useState<ITicketType | undefined>(undefined);
  const [selectedSessionType, setSelectedSessionType] = useState<ISessionType | undefined>(undefined);

  const [ticketTypes, setTicketTypes] = useState<ITicketType[]>([]);
  const [sessionTypes, setSessionTypes] = useState<ISessionType[]>([]);

  const [selectedAttendee, setSelectedAttendee] = useState<
    | {
        firstName?: string;
        lastName?: string;
        email?: string;
        ticketType?: ITicketType;
        sessionTypes?: ISessionType[];
        phone?: string;
        sessions?: number[];
        extraAttendees?: any[];
      }
    | undefined
  >(undefined);

  const [selectedAttendees, setSelectedAttendees] = useState<
    | {
        firstName?: string;
        lastName?: string;
        email?: string;
        ticketType?: ITicketType;
        sessionTypes?: ISessionType[];
        phone?: string;
        sessions?: number[];
      }[]
    | undefined
  >(attendeesToCreate);

  const {
    data: dataTicketTypes,
    loading: loadingTicketTypes,
    error: errorTicketTypes,
    refetch: refetchTicketTypes,
  } = useQuery(GET_TICKETTYPES, {
    variables: {
      filter: {
        eventID: {
          equals: selectedEvent?.id,
        },
      },
    },
    onCompleted: data => {
      const ticketTypes = data.findManyTicketTypes ? data.findManyTicketTypes : [];
      setTicketTypes(ticketTypes);
    },
  });

  const {
    data: dataSessionTypes,
    loading: loadingSessionTypes,
    error: errorSessionTypes,
    refetch: refetchSessionTypes,
  } = useQuery(GET_SESSIONTYPES, {
    variables: {
      filter: {
        eventID: {
          equals: selectedEvent?.id,
        },
      },
    },
    onCompleted: data => {
      setSessionTypes(data.findManySessionTypes ? data.findManySessionTypes : []);
    },
  });

  const attendeeColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Deelnemer',
      fieldName: 'activity.title',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item?.firstName} {item?.lastName}{' '}
            {item.extraAttendees && item.extraAttendees.length > 0
              ? item.extraAttendees.map((person: any) => `, ${person.firstName} ${person.lastName}`)
              : ''}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Ticket Type',
      fieldName: 'activity.entity.title',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.ticketType.name}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Prijs',
      fieldName: 'price',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item?.ticketType.price)}</span>;
      },
    },
    {
      key: 'column4',
      name: '',
      fieldName: 'action',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <TrashIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                const newSelectedAttendees = selectedAttendees?.filter(attendee => JSON.stringify(attendee) !== JSON.stringify(item));

                // set updated state for ticket type that was removed
                const newTicketTypes = ticketTypes.map(ticketType => {
                  if (ticketType.id === item?.ticketType.id) {
                    return {
                      ...ticketType,
                      actualStock: ticketType.actualStock + 1,
                    };
                  }
                  return ticketType;
                });
                setTicketTypes(newTicketTypes);

                if (item && item.sessionTypes) {
                  // set updated state for session types that were removed
                  const newSessionTypes = sessionTypes.map(sessionType => {
                    const ifFound = item.sessionTypes.find((type: any) => type.id === sessionType.id);

                    if (ifFound) {
                      return {
                        ...sessionType,
                        actualStock: sessionType.actualStock + 1,
                      };
                    }
                    return sessionType;
                  });

                  setSessionTypes(newSessionTypes);
                }

                setSelectedAttendees(newSelectedAttendees);
              }}
            />
          </span>
        );
      },
    },
  ];

  const [attendees, setAttendees] = useState<any[]>([]);

  return (
    <>
      {!loadingTicketTypes && !errorTicketTypes && !loadingSessionTypes && !errorSessionTypes && (
        <>
          <div className='flex flex-wrap justify-between'>
            <div className='py-2.5' style={{ width: `100% ` }}>
              <Table items={selectedAttendees ? selectedAttendees : []} columns={attendeeColumns} />
            </div>
            <div className='py-2.5' style={{ width: `48.5%` }}>
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                {'Voornaam'}
              </label>
              <Input
                type={'text'}
                id={'firstName'}
                value={selectedAttendee?.firstName ? selectedAttendee?.firstName : ''}
                onChange={e => {
                  setSelectedAttendee({ ...selectedAttendee, firstName: e.target.value });
                }}
              />
            </div>

            <div className='py-2.5' style={{ width: `48.5%` }}>
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                {'Achternaam'}
              </label>
              <Input
                type={'text'}
                id={'lastName'}
                value={selectedAttendee?.lastName ? selectedAttendee?.lastName : ''}
                onChange={e => {
                  setSelectedAttendee({ ...selectedAttendee, lastName: e.target.value });
                }}
              />
            </div>

            <div className='py-2.5' style={{ width: `48.5%` }}>
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                {'Email'}
              </label>
              <Input
                type={'text'}
                id={'email'}
                value={selectedAttendee?.email ? selectedAttendee?.email : ''}
                onChange={e => {
                  setSelectedAttendee({ ...selectedAttendee, email: e.target.value });
                }}
              />
            </div>

            <div className='py-2.5' style={{ width: `48.5%` }}>
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                {'Telefoon'}
              </label>
              <Input
                type={'text'}
                id={'phone'}
                value={selectedAttendee?.phone ? selectedAttendee?.phone : ''}
                onChange={e => {
                  setSelectedAttendee({ ...selectedAttendee, phone: e.target.value });
                }}
              />
            </div>

            {selectedAttendee?.ticketType &&
            selectedAttendee?.ticketType?.numberAdditionalAttendees &&
            selectedAttendee?.ticketType?.numberAdditionalAttendees > 1
              ? (() => {
                  const desiredLength = selectedAttendee?.ticketType?.numberAdditionalAttendees - 1;

                  if (selectedAttendee?.extraAttendees?.length !== desiredLength) {
                    setSelectedAttendee({
                      ...selectedAttendee,
                      extraAttendees: Array.from({ length: desiredLength }).map(() => ({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                      })),
                    });
                  }

                  const arrayToMap = Array.from({ length: desiredLength });
                  return arrayToMap.map((_, index) => (
                    <div
                      className='flex flex-wrap justify-between w-full border-t border-cego-lightbrown mt-4 pt-2'
                      key={`${index}EXTRAATTENDEES`}
                    >
                      <div className='py-2.5' style={{ width: `48.5%` }}>
                        <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                          {`Voornaam [Extra ${index + 1}]`}
                        </label>
                        <Input
                          type={'text'}
                          id={'firstName'}
                          value={(selectedAttendee?.extraAttendees && selectedAttendee?.extraAttendees[index]?.firstName) || ''}
                          onChange={e => {
                            const updatedAttendees = selectedAttendee?.extraAttendees ? [...selectedAttendee?.extraAttendees] : [];
                            updatedAttendees[index].firstName = e.target.value;
                            setAttendees(updatedAttendees);
                          }}
                        />
                      </div>

                      <div className='py-2.5' style={{ width: `48.5%` }}>
                        <label
                          htmlFor={`Achternaam [Extra ${index + 1}]`}
                          className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                        >
                          {`Achternaam [Extra ${index + 1}]`}
                        </label>
                        <Input
                          type={'text'}
                          id={`Achternaam [Extra ${index + 1}]`}
                          value={(selectedAttendee?.extraAttendees && selectedAttendee?.extraAttendees[index]?.lastName) || ''}
                          onChange={e => {
                            const updatedAttendees = selectedAttendee?.extraAttendees ? [...selectedAttendee?.extraAttendees] : [];
                            updatedAttendees[index].lastName = e.target.value;
                            setAttendees(updatedAttendees);
                          }}
                        />
                      </div>

                      <div className='py-2.5' style={{ width: `48.5%` }}>
                        <label
                          htmlFor={`Email [Extra ${index + 1}]`}
                          className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                        >
                          {`Email [Extra ${index + 1}]`}
                        </label>
                        <Input
                          type={'text'}
                          id={`Email [Extra ${index + 1}]`}
                          value={(selectedAttendee?.extraAttendees && selectedAttendee?.extraAttendees[index]?.email) || ''}
                          onChange={e => {
                            const updatedAttendees = selectedAttendee?.extraAttendees ? [...selectedAttendee?.extraAttendees] : [];
                            updatedAttendees[index].email = e.target.value;
                            setAttendees(updatedAttendees);
                          }}
                        />
                      </div>

                      <div className='py-2.5' style={{ width: `48.5%` }}>
                        <label
                          htmlFor={`Telefoon [Extra ${index + 1}]`}
                          className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                        >
                          {`Telefoon [Extra ${index + 1}]`}
                        </label>
                        <Input
                          type={'text'}
                          id={`Telefoon [Extra ${index + 1}]`}
                          value={(selectedAttendee?.extraAttendees && selectedAttendee?.extraAttendees[index]?.phone) || ''}
                          onChange={e => {
                            const updatedAttendees = selectedAttendee?.extraAttendees ? [...selectedAttendee?.extraAttendees] : [];
                            updatedAttendees[index].phone = e.target.value;
                            setAttendees(updatedAttendees);
                          }}
                        />
                      </div>
                    </div>
                  ));
                })()
              : null}

            {selectedEvent?.sessionTypes && selectedEvent.sessionTypes.length > 0 && (
              <div className='py-2.5' style={{ width: `48.5%` }}>
                <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                  {'Sessies'}
                </label>
                <ul className='items-center w-full text-sm font-medium text-cego-black bg-white border border-cego-lightbrown rounded-sm flex cursor-pointer'>
                  {sessionTypes.map((item: any, index: number) => (
                    <>
                      <li
                        className={classNames(
                          'w-full border-b border-cego-lightbrown sm:border-b-0',
                          index + 1 !== ticketTypes.length && 'sm:border-r',
                        )}
                      >
                        <div className='flex items-center pl-3'>
                          <input
                            id={`horizontal-list-checkbox-${item.name}`}
                            type='checkbox'
                            value={JSON.stringify(item)}
                            name='list-checkbox'
                            checked={selectedAttendee?.sessionTypes && selectedAttendee?.sessionTypes.some(type => type.name === item.name)}
                            className='w-4 h-4 text-cego-darkgray border-cego-lightbrown focus:ring-cego-darkgray'
                            onChange={e => {
                              const value: any = JSON.parse(e.target.value);
                              const isChecked = e.target.checked;

                              setSelectedAttendee(prevAttendee => {
                                if (prevAttendee) {
                                  if (prevAttendee.sessionTypes) {
                                    if (isChecked) {
                                      return {
                                        ...prevAttendee,
                                        sessionTypes: [...prevAttendee.sessionTypes, value],
                                      };
                                    } else {
                                      return {
                                        ...prevAttendee,
                                        sessionTypes: prevAttendee.sessionTypes.filter(type => type.code !== value.code),
                                      };
                                    }
                                  } else {
                                    return {
                                      ...prevAttendee,
                                      sessionTypes: [value],
                                    };
                                  }
                                } else {
                                  return {
                                    sessionTypes: [value],
                                  };
                                }
                              });
                            }}
                            disabled={item.actualStock < 1}
                          />
                          <label
                            htmlFor={`horizontal-list-checkbox-${item.name}`}
                            className='w-full py-3 ml-2 text-sm font-medium text-cego-black cursor-pointer'
                          >
                            {item.name} ({item.actualStock})
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            )}

            <div
              className='py-2.5'
              style={{ width: `${classNames(selectedEvent?.sessionTypes && selectedEvent?.sessionTypes.length > 0 ? '48.5%' : '100%')}` }}
            >
              <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                {'Tickets'}
              </label>
              <ul className='items-center w-full text-sm font-medium text-cego-black bg-white border border-cego-lightbrown rounded-sm flex cursor-pointer'>
                {ticketTypes.map((item: any, index: number) => (
                  <>
                    <li
                      className={classNames(
                        'w-full border-b border-cego-lightbrown sm:border-b-0',
                        index + 1 !== ticketTypes.length && 'sm:border-r',
                      )}
                    >
                      <div className='flex items-center pl-3'>
                        <input
                          id={`horizontal-list-radio-${item.name}`}
                          type='radio'
                          value={item}
                          name='list-radio'
                          checked={selectedAttendee?.ticketType?.name === item.name}
                          className='w-4 h-4 text-cego-darkgray border-cego-lightbrown focus:ring-cego-darkgray'
                          onChange={e => {
                            setSelectedAttendee(prevAttendee => {
                              if (prevAttendee) {
                                return {
                                  ...prevAttendee,
                                  ticketType: item,
                                };
                              } else {
                                return {
                                  ticketType: item,
                                };
                              }
                            });
                          }}
                          disabled={item.actualStock < 1}
                        />
                        <label
                          htmlFor={`horizontal-list-radio-${item.name}`}
                          className='w-full py-3 ml-2 text-sm font-medium text-cego-black cursor-pointer'
                        >
                          {item.name} [{resolveMoney(item.price)}] ({item.actualStock})
                        </label>
                      </div>
                    </li>
                  </>
                ))}
              </ul>
            </div>

            <div className='py-2.5 mt-7' style={{ width: `48.5%` }}>
              <div>
                {selectedAttendee &&
                  selectedAttendee.firstName &&
                  selectedAttendee.lastName &&
                  selectedAttendee.email &&
                  selectedAttendee.ticketType && (
                    <Button
                      quaternary
                      onClick={() => {
                        if (selectedAttendees && selectedAttendee) {
                          setSelectedAttendees([...selectedAttendees, { ...selectedAttendee }]);
                        } else if (selectedAttendee) {
                          setSelectedAttendees([selectedAttendee]);
                        }

                        setTicketTypes(prevTicketTypes =>
                          prevTicketTypes.map(ticket => {
                            if (ticket.id === selectedAttendee.ticketType?.id) {
                              return {
                                ...ticket,
                                actualStock: ticket.actualStock - 1,
                              };
                            }
                            return ticket;
                          }),
                        );

                        setSessionTypes(prevSessionTypes =>
                          prevSessionTypes.map(session => {
                            if (selectedAttendee.sessionTypes) {
                              const ifFound = selectedAttendee.sessionTypes.find(type => type.id === session.id);
                              if (ifFound) {
                                return {
                                  ...session,
                                  actualStock: session.actualStock - 1,
                                };
                              } else {
                                return session;
                              }
                            }
                            return session;
                          }),
                        );

                        setSelectedAttendee(undefined);
                        setSelectedTicketType(undefined);
                      }}
                    >
                      {'Toevoegen'}{' '}
                      <span className='inline ml-1'>
                        <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                      </span>
                    </Button>
                  )}
              </div>
            </div>
          </div>

          <div className={classNames('flex justify-end py-4')}>
            <Button
              quaternary
              disabled={selectedAttendees && selectedAttendees.length <= 0}
              onClick={() => {
                if (selectedAttendees && selectedAttendees.length > 0) {
                  setAttendeesToCreate && setAttendeesToCreate(selectedAttendees);
                }

                setSteps([
                  { id: 1, name: 'Evenement', href: '#', status: 'complete' },
                  { id: 2, name: 'Deelnemers ', href: '#', status: 'complete' },
                  { id: 3, name: 'Kamers', href: '#', status: 'current' },
                  { id: 4, name: 'Overzicht', href: '#', status: 'upcoming' },
                ]);
              }}
            >
              {'Volgende'}{' '}
              <span className='inline ml-1'>
                <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          </div>
        </>
      )}
      {loadingTicketTypes && (
        <div className='h-5/6 flex items-center'>
          <div className={classNames('sweet-loading text-center w-full')}>
            <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
          </div>
        </div>
      )}
      {errorTicketTypes && <p>Error</p>}
    </>
  );
};

export const StepThree: React.FC<{
  setSteps: (steps: any[]) => void;
  steps: any[];
  selectedEvent: IEvent | undefined;
  setRoomsToCreate: (roomTypes: any) => void;
  roomsToCreate: any[];
}> = ({ setSteps, steps, selectedEvent, setRoomsToCreate, roomsToCreate }) => {
  const [selectedRoomType, setSelectedRoomType] = useState<IRoomType | undefined>(undefined);

  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([]);

  const [selectedRoomTypes, setSelectedRoomTypes] = useState<IRoomType[]>(roomsToCreate ? roomsToCreate : []);

  const {
    data: dataRoomTypes,
    loading: loadingRoomTypes,
    error: errorRoomTypes,
    refetch: refetchRoomTypes,
  } = useQuery(GET_ROOMTYPES, {
    variables: {
      filter: {
        eventID: {
          equals: selectedEvent?.id,
        },
      },
    },
    onCompleted: data => {
      setRoomTypes(data.findManyRoomTypes ? data.findManyRoomTypes : []);
    },
  });

  const attendeeColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.name}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Prijs',
      fieldName: 'price',
      render: (item: any) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{resolveMoney(item?.price)}</span>;
      },
    },
    {
      key: 'column4',
      name: '',
      fieldName: 'action',
      render: (item: any) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <TrashIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                const newSelectedRoomTypes = selectedRoomTypes?.filter(roomType => JSON.stringify(roomType) !== JSON.stringify(item));

                // set updated state for ticket type that was removed
                const newRoomTypes = roomTypes.map(roomType => {
                  if (roomType.id === item?.id) {
                    return {
                      ...roomType,
                      actualStock: roomType.actualStock + 1,
                    };
                  }
                  return roomType;
                });

                setRoomTypes(newRoomTypes);
                setSelectedRoomTypes(newSelectedRoomTypes);
              }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <>
      {!loadingRoomTypes && !errorRoomTypes && (
        <>
          {roomTypes && roomTypes.length > 0 ? (
            <div className='flex flex-wrap justify-between'>
              <div className='py-2.5' style={{ width: `100% ` }}>
                <Table items={selectedRoomTypes ? selectedRoomTypes : []} columns={attendeeColumns} />
              </div>

              <div className='py-2.5' style={{ width: `100%` }}>
                <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                  {'Kamer types'}
                </label>
                <ul className='items-center w-full text-sm font-medium text-cego-black bg-white border border-cego-lightbrown rounded-sm flex cursor-pointer'>
                  {roomTypes.map((item: any, index: number) => (
                    <>
                      <li
                        className={classNames(
                          'w-full border-b border-cego-lightbrown sm:border-b-0',
                          index + 1 !== roomTypes.length && 'sm:border-r',
                        )}
                      >
                        <div className='flex items-center pl-3'>
                          <input
                            id={`horizontal-list-radio-${item.name}`}
                            type='radio'
                            value={JSON.stringify(item)}
                            name='list-radio'
                            checked={JSON.stringify(selectedRoomType) === JSON.stringify(item)}
                            className='w-4 h-4 text-cego-darkgray border-cego-lightbrown focus:ring-cego-darkgray'
                            onChange={e => {
                              const value: any = JSON.parse(e.target.value);
                              setSelectedRoomType(value);
                            }}
                            disabled={item.actualStock < 1}
                          />
                          <label
                            htmlFor={`horizontal-list-radio-${item.name}`}
                            className='w-full py-3 ml-2 text-sm font-medium text-cego-black cursor-pointer'
                          >
                            {item.name} [{resolveMoney(item.price)}] ({item.actualStock})
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
              </div>

              <div className='py-2.5 mt-7' style={{ width: `48.5%` }}>
                <div>
                  {selectedRoomType && (
                    <Button
                      quaternary
                      onClick={() => {
                        if (selectedRoomType && selectedRoomTypes) {
                          setSelectedRoomTypes([...selectedRoomTypes, selectedRoomType]);
                        } else if (selectedRoomType && !selectedRoomTypes) {
                          setSelectedRoomTypes([selectedRoomType]);
                        }

                        setRoomTypes(prevRoomTypes =>
                          prevRoomTypes.map(room => {
                            if (room.id === selectedRoomType?.id) {
                              return {
                                ...room,
                                actualStock: room.actualStock - 1,
                              };
                            }
                            return room;
                          }),
                        );

                        setSelectedRoomType(undefined);
                      }}
                    >
                      {'Toevoegen'}{' '}
                      <span className='inline ml-1'>
                        <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className='flex flex-wrap justify-around py-4'>
              <p className='text-center'>Voor deze vorming zijn geen kamers geconfigureerd. Ga naar de volgende stap.</p>
            </div>
          )}

          <div className={classNames('flex justify-end', 'py-4')}>
            <Button
              quaternary
              onClick={() => {
                setRoomsToCreate(selectedRoomTypes ? selectedRoomTypes : []);

                setSteps([
                  { id: 1, name: 'Evenement', href: '#', status: 'complete' },
                  { id: 2, name: 'Deelnemers ', href: '#', status: 'complete' },
                  { id: 3, name: 'Kamers', href: '#', status: 'complete' },
                  { id: 4, name: 'Overzicht', href: '#', status: 'current' },
                ]);
              }}
            >
              {'Volgende'}{' '}
              <span className='inline ml-1'>
                <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
              </span>
            </Button>
          </div>
        </>
      )}
      {loadingRoomTypes && (
        <div className='h-5/6 flex items-center'>
          <div className={classNames('sweet-loading text-center w-full')}>
            <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
          </div>
        </div>
      )}
      {errorRoomTypes && <p>Error</p>}
    </>
  );
};

export default OrderCreateForm;
