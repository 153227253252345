import { gql } from '@apollo/client';
import { IProposalLine } from './proposalLine';
import { IUser } from './user';

export interface IProposalLineUser {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  proposalLineID?: number;
  proposalLine?: IProposalLine;
  userID?: number;
  user?: IUser;
}

export const GET_PROPOSAL_LINE_USERS = gql`
  query getProposalLineUsers($filter: ProposalLineUserFilterInput) {
    findManyProposalLineUsers(filter: $filter) {
      createdAt
      updatedAt
      id
      proposalLineID
      userID
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_PROPOSAL_LINE_USER = gql`
  mutation createProposalLineUser($data: ProposalLineUserCreateInput!) {
    createProposalLineUser(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_PROPOSAL_LINE_USER = gql`
  mutation deleteProposalLineUser($where: ProposalLineUserWhereInput) {
    deleteProposalLineUser(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
