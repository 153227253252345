import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  filterComboBoxItem,
  resolveMoney,
  resolveValueForCombobox,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import Button from '../../components/tailwind/button';
import { toastError, toastSuccess } from '../../utils/toast';
import { CREATE_PRODUCT, DELETE_PRODUCT, GET_PRODUCT, GET_PRODUCTS, IProduct, UPDATE_PRODUCT } from '../../utils/product';
import { GET_ACCOUNTS, convertAccountsToComboBoxOptions } from '../../utils/account';
import { FullScreenModal } from '../../components/tailwind/fullscreenmodal';
import { GET_ENTITIES, convertEntitiesToComboBoxOptions } from '../../utils/entity';

export const Products: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const {
    loading: loadingAccounts,
    error: errorAccounts,
    data: dataAccounts,
  } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingEntities,
    error: errorEntities,
    data: dataEntities,
  } = useQuery(GET_ENTITIES, {
    variables: {
      filter: {
        OR: [
          {
            title: {
              not: {
                contains: 'onderwijs',
              },
            },
          },
          {
            title: {
              equals: 'onderwijs',
            }, // This ensures entities with exactly 'onderwijs' are included
          },
        ],
      },
    },
  });

  // ===========================================
  // ============== CUSTOMER STATE ==============

  // ===========================================
  // ========== BILLING CUSTOMER STATE =========

  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
  const [selectedProductTab, setSelectedProductTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingProducts, setSortingProducts] = useState<ISorting>({
    key: 'name',
    direction: 'asc',
  });
  const [openProductDetail, setOpenProductDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openProductCreate, setOpenProductCreate] = useState(DEFAULT_DETAIL_OPEN);
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery]);

  const productColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'product.name',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-18', !item.active && 'text-cego-darkgray')}>
            {item?.name}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Prijs',
      fieldName: 'product.price',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46', !item.active && 'text-cego-darkgray')}>
            {resolveMoney(item?.price ? item?.price : 0)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'BTW Percentage',
      fieldName: 'product.vatPercentage',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis overflow-hidden block w-18 text-base', !item.active && 'text-cego-darkgray')}>
            {item?.vatPercentage}%
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: 'Entiteit',
      fieldName: 'product.entity',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis overflow-hidden block w-18 text-base', !item.active && 'text-cego-darkgray')}>
            {item?.entity?.title}
          </span>
        );
      },
    },
  ];

  const getOrderBy = useCallback(
    (sortingOrders: any) => {
      return { [sortingOrders.key]: sortingOrders.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingProducts],
  );

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ name: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataProducts,
    loading: loadingProducts,
    error: errorProducts,
    refetch: refetchProducts,
    fetchMore: fetchMoreProducts,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      filter: getFilters(searchQuery),
      orderBy: getOrderBy(sortingProducts),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getProduct, { loading: loadingProduct, error: errorProduct, refetch: refetchProduct }] = useLazyQuery(GET_PRODUCT);
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const handleSetProductSelection = (product?: IProduct) => {
    if (product?.id) {
      getProduct({
        variables: {
          where: {
            id: product?.id,
          },
        },
        onCompleted: data => {
          setSelectedProduct(data.findOneProduct);
          setOpenProductDetail(true);
        },
      });
    } else {
      setSelectedProduct(undefined);
    }

    setOpenProductDetail(true);
  };

  const handleUpdateProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProduct?.id) {
            const updateProductObj: any = {
              name: selectedProduct?.name,
              price: selectedProduct?.price && parseFloat(selectedProduct?.price.toString()),
              vatPercentage: selectedProduct?.vatPercentage && parseFloat(selectedProduct?.vatPercentage.toString()),
              active: selectedProduct?.active,
              account: {
                connect: {
                  id: selectedProduct.accountID,
                },
              },
              entity: selectedProduct?.entityID
                ? {
                    connect: {
                      id: selectedProduct?.entityID,
                    },
                  }
                : undefined,
              availableForProposals: selectedProduct?.availableForProposals,
              service: selectedProduct?.service,
            };

            updateProduct({
              variables: {
                id: selectedProduct?.id,
                data: updateProductObj,
              },
              onCompleted: data => {
                resolve(data.updateProduct);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProduct = (await res) as any;

      setSelectedProduct(newUpdatedProduct);
      refetchProducts({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingProducts),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Product succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedProduct?.id) {
            const createProductObj: any = {
              name: selectedProduct?.name,
              price: selectedProduct?.price && parseFloat(selectedProduct?.price.toString()),
              vatPercentage: selectedProduct?.vatPercentage && parseFloat(selectedProduct?.vatPercentage.toString()),
              account: {
                connect: {
                  id: selectedProduct?.accountID,
                },
              },
              entity: selectedProduct?.entityID
                ? {
                    connect: {
                      id: selectedProduct?.entityID,
                    },
                  }
                : undefined,
              availableForProposals: selectedProduct?.availableForProposals,
              service: selectedProduct?.service,
            };

            createProduct({
              variables: {
                data: createProductObj,
              },
              onCompleted: data => {
                resolve(data.createProduct);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const product: IProduct = (await res) as any;
      setSelectedProduct(product);
      refetchProducts({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingProducts),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Product succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProduct?.id) {
            deleteProduct({
              variables: {
                where: {
                  id: selectedProduct?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedProduct(undefined);
      refetchProducts({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingProducts),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });
      setOpenProductDetail(false);

      toastSuccess('Product succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const productDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedProduct,
    permission: '',
    selectedTab: selectedProductTab,
    setSelectedTab: setSelectedProductTab,
    handleUpdate: handleUpdateProduct,
    handleCreate: handleCreateProduct,
    handleDelete: handleDeleteProduct,
    delete: {
      title: 'Product verwijderen?',
      text: 'Weet je zeker dat je dit product wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'customer_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'product_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedProduct?.name,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_1',
            label: 'Prijs',
            name: 'price',
            required: true,
            value: selectedProduct?.price,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Prijs moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_3',
            label: 'BTW Percentage',
            name: 'vatPercentage',
            required: true,
            value: selectedProduct?.vatPercentage,
            placeholder: '',
            type: 'number',
            max: 100,
            min: 0,
            step: 1,
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && (value as any) !== 0) {
                return {
                  valid: false,
                  message: 'BTW moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_4',
            label: 'Rekening',
            name: 'accountID',
            value: resolveValueForCombobox(
              selectedProduct?.accountID ? selectedProduct.accountID : '',
              convertAccountsToComboBoxOptions(
                dataAccounts && dataAccounts.findManyAccounts && !loadingAccounts && !errorAccounts ? dataAccounts.findManyAccounts : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            required: true,
            combobox: {
              items: convertAccountsToComboBoxOptions(
                dataAccounts && dataAccounts.findManyAccounts && !loadingAccounts && !errorAccounts
                  ? selectedProduct?.id
                    ? dataAccounts.findManyAccounts
                    : filterComboBoxItem(dataAccounts.findManyAccounts)
                  : [],
              ),
            },
            width: 49,
            callback: (name: string, value: any) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Account moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'activity_detail_section_0_field_6',
            label: 'Entiteit',
            name: 'entityID',
            value: resolveValueForCombobox(
              selectedProduct?.entityID ? selectedProduct.entityID : '',
              convertEntitiesToComboBoxOptions(
                dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities ? dataEntities.findManyEntities : [],
              ),
            ),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertEntitiesToComboBoxOptions(
                dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities ? dataEntities.findManyEntities : [],
              ),
            },
            width: 49,
            callback: (name: string, value: any) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
          },
          {
            id: 'product_detail_section_0_field_5',
            label: 'Actief',
            name: 'active',
            value: selectedProduct?.active,
            placeholder: '',
            type: 'switch',
            width: 31.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'product_detail_section_0_field_7',
            label: 'Beschikbaar voor aanvraag',
            name: 'availableForProposals',
            value: selectedProduct?.availableForProposals,
            placeholder: '',
            type: 'switch',
            width: 31.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'product_detail_section_0_field_90',
            label: 'Dienst',
            name: 'service',
            value: selectedProduct?.service,
            placeholder: '',
            type: 'switch',
            width: 31.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Producten</h1>
      </header>
      <div>
        <div className='my-4'>
          <Button
            quaternary
            onClick={() => {
              handleSetProductSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorProducts ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingProducts ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataProducts ? dataProducts.findManyProducts : []}
                columns={productColumns}
                loading={loadingProducts}
                onSelect={handleSetProductSelection}
                selection={selectedProduct}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  const skip = pagedState.skip + pagedState.take;

                  fetchMoreProducts({
                    variables: {
                      filter: getFilters(searchQuery),
                      orderBy: getOrderBy(sortingProducts),
                      skip: skip,
                      take: pagedState.take,
                    },
                  });

                  setPagedState(prevState => ({
                    ...prevState,
                    skip: skip,
                  }));
                }}
              />
            </div>
            <Modal open={openProductDetail} setOpen={setOpenProductDetail}>
              <>
                {openProductDetail === true ? (
                  <>
                    {!loadingProduct && !errorProduct && (
                      <DetailForm
                        schema={productDetailSchema}
                        title={'Product'}
                        caption={selectedProduct?.id ? 'Product updaten' : 'Product toevoegen'}
                      />
                    )}
                    {loadingProduct && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorProduct && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
