import { gql } from '@apollo/client';
import { IOrder } from './orders';

export interface ICoupon {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  createdByID?: number;
  updatedByID?: number;
  versioning?: string;

  code?: string;
  value?: number;
  left?: number;
  usage?: number;
  validUntil?: string;
  description?: string;
  order?: IOrder;
}

export const GET_COUPONS = gql`
  query getCoupons($filter: CouponFilterInput, $skip: Int, $take: Int) {
    findManyCoupons(filter: $filter, skip: $skip, take: $take) {
      id
      createdAt
      updatedAt
      code
      value
      left
      usage
      validUntil
      description
    }
  }
`;

export const GET_COUPON = gql`
  query getCoupon($where: CouponWhereInput) {
    findOneCoupon(where: $where) {
      id
      createdAt
      updatedAt
      code
      value
      usage
      validUntil
      description
      left
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation createCoupon($data: CouponCreateInput!) {
    createCoupon(data: $data) {
      id
      createdAt
      updatedAt
      code
      value
      usage
      validUntil
      description
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon($id: Int!, $data: CouponUpdateInput) {
    updateCoupon(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      code
      value
      usage
      validUntil
      description
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($where: CouponWhereInput) {
    deleteCoupon(where: $where) {
      id
    }
  }
`;
