/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DocumentCheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { FaSave, FaTrash } from 'react-icons/fa';
import { classNames, DEFAULT_DATE_FORMAT_LONG } from '../../utils';
import { Input } from './input'; // Assuming DateTimeInput is a component for date-time input

interface ExtraDate {
  startTime?: Date;
  endTime?: Date;
  location?: {
    address?: string;
    city?: string;
    zipcode?: string;
  };
}

interface Props {
  list: ExtraDate[];
  callback: any;
  name: string;
  disabled: boolean;
}

const ExtraDateList: React.FC<Props> = ({ list, callback, name, disabled }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [items, setItems] = useState(list);

  const allFieldsFilled = (extraDate: ExtraDate) => {
    return extraDate.startTime && extraDate.endTime;
  };

  const handleOnListItemClick = (index: number) => {
    if (index !== selectedItem) {
      setSelectedItem(index);
    }
  };

  const handleOnNewListItemClick = () => {
    if (!disabled) {
      if (selectedItem !== null && !allFieldsFilled(items[selectedItem])) {
        return;
      }

      if (selectedItem !== null) {
        handleOnListItemChange(selectedItem, items[selectedItem]);
      }

      const newList = [...items, { startTime: new Date(), endTime: new Date() }];
      setItems(newList);
      setSelectedItem(newList.length - 1);
      callback(name, newList);
    }
  };

  const handleOnListItemChange = (index: number, value: Partial<ExtraDate>) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], ...value };
    setItems(updatedItems);
    callback(name, updatedItems);
  };

  const handleOnRemoveListItemClick = (index: number) => {
    const newList = [...items];
    newList.splice(index, 1);
    setItems(newList);
    setSelectedItem(null);
    callback(name, newList);
  };

  const moveItem = (dragId: number, hoverId: number) => {
    const dragIndex = items.findIndex((_, index) => index === dragId);
    const hoverIndex = items.findIndex((_, index) => index === hoverId);

    if (dragIndex < 0 || hoverIndex < 0) {
      return;
    }

    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);

    setItems(newItems);
    callback(name, newItems);
  };

  const DraggableItem: React.FC<{
    id: number;
    extraDate: ExtraDate;
    moveItem: any;
    index: number;
  }> = ({ id, extraDate, moveItem, index }) => {
    const [localExtraDate, setLocalExtraDate] = useState<ExtraDate>(extraDate);
    const ref = useRef<HTMLDivElement>(null);

    const [, dragRef] = useDrag({
      type: 'item',
      item: { id },
    });

    const [, dropRef] = useDrop({
      accept: 'item',
      hover(item: any, monitor: any) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.id;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        item.id = hoverIndex;
      },
    });

    dragRef(dropRef(ref));

    const handleSave = () => {
      if (!allFieldsFilled(localExtraDate)) {
        return;
      }

      handleOnListItemChange(index, localExtraDate);
      setSelectedItem(null);
    };

    return (
      <li key={index} ref={ref as any} onClick={() => handleOnListItemClick(index)}>
        <span className={classNames('block hover:bg-cego-offwhite', index === selectedItem && 'bg-cego-offwhite')}>
          <div className='flex relative items-center font-bold px-4 py-4 font- sm:px-6'>
            <div className='flex min-w-0 flex-1 items-center'>
              <div className={classNames('min-w-0 flex-1 px-4')}>
                <div>
                  {index === selectedItem ? (
                    <>
                      <FaSave
                        className='w-5 h-5 absolute left-3.5 top-8 hover:cursor-pointer text-cego-black hover:text-cego-darkgray'
                        onClick={() => {
                          if (!disabled) {
                            handleSave();
                            setSelectedItem(null);
                          }
                        }}
                      />
                      <div className='pr-2'>
                        <Input
                          type={'datetime-local'}
                          value={localExtraDate.startTime && moment(localExtraDate?.startTime).format('YYYY-MM-DDTHH:mm:ss')}
                          className='mb-2'
                          disabled={disabled}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            setLocalExtraDate({ ...localExtraDate, startTime: e.target.value as any });
                          }}
                        />
                        <Input
                          type={'datetime-local'}
                          value={localExtraDate.endTime && moment(localExtraDate?.endTime).format('YYYY-MM-DDTHH:mm:ss')}
                          className='mb-2'
                          disabled={disabled}
                          onClick={e => e.stopPropagation()}
                          onChange={e => {
                            e.stopPropagation();
                            setLocalExtraDate({ ...localExtraDate, endTime: e.target.value as any });
                          }}
                        />
                        {/* Additional inputs for location fields */}
                      </div>
                      <FaTrash
                        className='w-5 h-5 absolute right-5 top-8 hover:cursor-pointer text-cego-black hover:text-cego-darkgray'
                        onClick={() => {
                          if (!disabled) {
                            handleOnRemoveListItemClick(index);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p className='text-sm text-gray-900 mb-1'>
                        Start: {extraDate.startTime && moment(extraDate.startTime).format(DEFAULT_DATE_FORMAT_LONG)} Einde: {'-'}{' '}
                        {extraDate.endTime && moment(extraDate.endTime).format(DEFAULT_DATE_FORMAT_LONG)}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </span>
      </li>
    );
  };

  return (
    <>
      {items && items.length > 0 && (
        <div className='overflow-hidden bg-white shadow-cego-lightbrown border border-cegolightbrown rounded-sm'>
          <ul role='list' className='divide-y divide-cego-lightbrown'>
            {items &&
              items.length > 0 &&
              items.map((extraDate, index) => (
                <DraggableItem key={index} id={index} extraDate={extraDate} moveItem={moveItem} index={index} />
              ))}
          </ul>
        </div>
      )}
      <p
        className='hover:cursor-pointer underline text-cego-darkgray pt-1 text-sm font-normal hover:text-cego-lightbrown mb-1'
        onClick={handleOnNewListItemClick}
      >
        Voeg extra datum toe
      </p>
    </>
  );
};

export { ExtraDateList };
