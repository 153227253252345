import { gql } from '@apollo/client';
import moment from 'moment';
import { IComboBoxOption } from '../types';
import { IActivity } from './activity';
import { ITicketType } from './ticketType';
import { IRoomType } from './roomType';
import { ISessionType } from './sessionType';
import { IAttendee } from './attendee';
import { IEventUser } from './eventUser';
import { IMailing } from './mailing';
export interface IEvent {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  activityID?: number;
  activity?: IActivity;
  startTime?: Date;
  endTime?: Date;
  location?: any;
  online?: boolean;
  status?: string;
  closed?: boolean;
  advancedPricing?: boolean;
  ticketTypes?: ITicketType[];
  roomTypes?: IRoomType[];
  sessionTypes?: ISessionType[];
  attendees?: IAttendee[];
  waitingList?: IAttendee[];
  eventUsers?: IEventUser[];
  mailings?: IMailing[];
  extraDates?: {
    startTime?: Date;
    endTime?: Date;
    location?: any;
    __typename?: any;
  }[];
}

export interface ICreateEvent {
  activityID?: number;
  startTime?: Date;
  endTime?: Date;
  location?: any;
  online?: boolean;
}

export interface IUpdateEvent {
  activityID?: number;
  startTime?: Date;
  endTime?: Date;
  location?: any;
  online?: boolean;
}

export const GET_EVENTS = gql`
  query getEvents($filter: EventFilterInput, $take: Int, $skip: Int, $orderBy: EventOrderByInput) {
    findManyEvents(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      status
      activityID
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      activity {
        id

        createdAt
        updatedAt
        createdByID
        updatedByID
        versioning

        title
        activityType {
          id

          createdAt
          updatedAt
          createdByID
          updatedByID
          versioning

          title
        }
      }
      startTime
      endTime
      location {
        name
        address
        zipcode
        city
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          image
        }
      }
      online
      closed
      advancedPricing
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      activity {
        title
      }
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($where: EventWhereInput) {
    findOneEvent(where: $where) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      status
      location {
        name
        address
        city
        zipcode
        locationUrl
      }
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          jobTitle
          image
        }
      }
      extraDates {
        startTime
        endTime
      }
      activity {
        id

        createdAt
        updatedAt
        createdByID
        updatedByID
        versioning

        title
      }
      startTime
      endTime
      online
      closed
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
      }
      roomTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
        amountPersons
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      attendees {
        firstName
        lastName
        id
        hasAttended
        active
        email
        tickets {
          orderID
          ticketType {
            id
            name
            numberAdditionalAttendees
            hasAdditionalAttendees
          }
        }
        event {
          closed
        }
        sessions {
          sessionType {
            id
            name
          }
        }
      }
      mailings {
        id
        createdAt
        updatedAt
        createdByID
        type
        subject
        body
        sender
        recipients
        attachments
        resendResponse
        hasError
        mailingLogs {
          id
          createdAt
          updatedAt
          isResolved
          isError
          recipient
          sender
          resendResponse
        }
      }
      advancedPricing
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($data: EventCreateInput!) {
    createEvent(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      status
      updatedByID
      versioning
      location {
        name
        address
        city
        zipcode
        locationUrl
      }
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          image
        }
      }
      startTime
      endTime
      online
      closed
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      roomTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
        amountPersons
      }
      attendees {
        firstName
        lastName
        id
        hasAttended
        active
        email
      }
      advancedPricing
      mailings {
        id
        createdAt
        updatedAt
        createdByID
        type
        subject
        body
        sender
        recipients
        attachments
        resendResponse
        hasError
        mailingLogs {
          id
          createdAt
          updatedAt
          isResolved
          isError
          recipient
          sender
          resendResponse
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($id: Int!, $data: EventUpdateInput) {
    updateEvent(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      status
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      location {
        name
        address
        city
        zipcode
        locationUrl
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          image
        }
      }
      startTime
      endTime
      online
      closed
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      roomTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
        amountPersons
      }
      attendees {
        firstName
        lastName
        id
        hasAttended
        active
        email
      }
      advancedPricing
      mailings {
        id
        createdAt
        updatedAt
        createdByID
        type
        subject
        body
        sender
        recipients
        attachments
        resendResponse
        hasError
        mailingLogs {
          id
          createdAt
          updatedAt
          isResolved
          isError
          recipient
          sender
          resendResponse
        }
      }
    }
  }
`;

export const CANCEL_EVENT = gql`
  mutation cancelEvent($data: EventCancelInput!) {
    cancelEvent(data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      location {
        name
        address
        city
        zipcode
        locationUrl
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          image
        }
      }
      startTime
      endTime
      online
      closed
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      roomTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
        amountPersons
      }
      attendees {
        firstName
        lastName
        id
        hasAttended
        active
        email
      }
      advancedPricing
      mailings {
        id
        createdAt
        updatedAt
        createdByID
        type
        subject
        body
        sender
        recipients
        attachments
        resendResponse
        hasError
        mailingLogs {
          id
          createdAt
          updatedAt
          isResolved
          isError
          recipient
          sender
          resendResponse
        }
      }
    }
  }
`;

export const CLOSE_EVENT = gql`
  mutation closeEvent($id: Int!) {
    closeEvent(id: $id) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning
      waitingList {
        firstName
        lastName
        email
        phone
      }
      extraDates {
        startTime
        endTime
      }
      status
      location {
        name
        address
        city
        zipcode
        locationUrl
      }
      eventUsers {
        id
        userID
        user {
          id
          firstName
          lastName
          image
        }
      }
      startTime
      endTime
      online
      closed
      ticketTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
      }
      sessionTypes {
        id
        price
        stock
        actualStock
        amountSold
        code
        name
      }
      roomTypes {
        id
        price
        stock
        actualStock
        amountSold
        name
        amountPersons
      }
      attendees {
        firstName
        lastName
        id
        hasAttended
        active
        email
      }
      advancedPricing
      mailings {
        id
        createdAt
        updatedAt
        createdByID
        type
        subject
        body
        sender
        recipients
        attachments
        resendResponse
        hasError
        mailingLogs {
          id
          createdAt
          updatedAt
          isResolved
          isError
          recipient
          sender
          resendResponse
        }
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($where: EventWhereInput) {
    deleteEvent(where: $where) {
      id
    }
  }
`;

export const convertEventsToComboBoxOptions = (events: IEvent[]) => {
  if (events) {
    const result: IComboBoxOption[] = events.map(
      (event: IEvent): IComboBoxOption => ({
        id: event.id as any,
        name: `${event?.activity?.title} | ${event.location.name} [${moment(event.startTime).format('DD-MM-YYYY')}]`,
        active: true, //no attribute active in event
      }),
    );
    return result;
  }

  return [];
};
