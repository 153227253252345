import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { PermissionProvider } from './context/permissionContext';

const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const providerConfig = {
  domain: auth0_domain as string,
  clientId: auth0_client_id as string,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(auth0_audience ? { audience: auth0_audience } : null),
  },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <PermissionProvider>
        <App />
      </PermissionProvider>
    </Auth0Provider>
  </React.StrictMode>,
);

reportWebVitals();
