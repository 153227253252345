import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import { CREATE_CUSTOMER, GET_CUSTOMER, GET_CUSTOMER_DEBOUNCE, ICustomer, UPDATE_CUSTOMER } from '../../utils/customer';
import Button from '../../components/tailwind/button';
import { toastError, toastSuccess } from '../../utils/toast';
import {
  CREATE_BILLINGCUSTOMER,
  GET_BILLINGCUSTOMER,
  GET_BILLINGCUSTOMERS,
  GET_BILLINGCUSTOMER_DEBOUNCE,
  IBillingCustomer,
  UPDATE_BILLINGCUSTOMER,
} from '../../utils/billingCustomer';
import useDebounce from '../../components/tailwind/hooks/useDebounce';

export const BillingCustomers: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  // ============================================
  // ============== CUSTOMER STATE ==============

  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | undefined>();
  const [selectedCustomerTab, setSelectedCustomerTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingCustomers, setSortingCustomers] = useState<ISorting>(DEFAULT_SORTING);
  const [openCustomerDetail, setOpenCustomerDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openCustomerCreate, setOpenCustomerCreate] = useState(DEFAULT_DETAIL_OPEN);

  const [institutionID, setInstitutionID] = useState('');
  const debouncedInstitutionIDQuery = useDebounce(institutionID, 500);

  useEffect(() => {
    getDebounceCustomer({
      variables: {
        institutionID: debouncedInstitutionIDQuery,
      },
      onCompleted: data => {
        if (data.findOneCustomerByInstitutionID && data.findOneCustomerByInstitutionID !== null) {
          setSelectedCustomer((prevState: any) => ({
            ...prevState,
            institution: data.findOneCustomerByInstitutionID?.institution,
            phone: data.findOneCustomerByInstitutionID?.phone,
            email: data.findOneCustomerByInstitutionID?.email,
            address: {
              address: data.findOneCustomerByInstitutionID?.address?.address,
              zipcode: data.findOneCustomerByInstitutionID?.address?.zipcode,
              city: data.findOneCustomerByInstitutionID?.address?.city,
              country: data.findOneCustomerByInstitutionID?.address?.country,
            },
          }));
        }
      },
    });
  }, [debouncedInstitutionIDQuery]);

  const customerColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Klant',
      fieldName: 'customer.institution',
      render: (item: ICustomer) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-18'>{item?.institution}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Email',
      fieldName: 'event.activity.title',
      render: (item: ICustomer) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-46'>{item?.email}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Plaats',
      fieldName: 'event.location.name',
      render: (item: ICustomer) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base'>{item?.address.city}</span>;
      },
    },
  ];

  const [getCustomer, { loading: loadingCustomer, error: errorCustomer, refetch: refetchCustomer }] = useLazyQuery(GET_CUSTOMER);
  const [getDebounceCustomer, { loading: loadingDebounceCustomer, error: errorDebounceCustomer, refetch: refetchDebounceCustomer }] =
    useLazyQuery(GET_CUSTOMER_DEBOUNCE);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const handleSetCustomerSelection = (customer?: ICustomer) => {
    if (customer?.id) {
      getCustomer({
        variables: {
          where: {
            id: customer?.id,
          },
        },
        onCompleted: data => {
          setSelectedCustomer({ ...data.findOneCustomer, hasBillingCustomer: data.findOneCustomer.billingCustomer ? true : false });
          setOpenCustomerDetail(true);
        },
      });
    } else {
      setSelectedCustomer(undefined);
    }

    setOpenCustomerDetail(true);
  };

  const handleUpdateCustomer = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedCustomer?.id) {
            const updateCustomerObj: any = {
              billingID: selectedCustomer?.billingID,
              institution: selectedCustomer?.institution,
              institutionID: selectedCustomer?.institutionID,
              address: {
                address: selectedCustomer?.address?.address,
                zipcode: selectedCustomer?.address?.zipcode,
                city: selectedCustomer?.address?.city,
                country: selectedCustomer?.address?.country,
              },
              email: selectedCustomer?.email,
              phone: selectedCustomer?.phone,
              billingCustomerID: selectedBillingCustomer?.id,
            };

            updateCustomer({
              variables: {
                id: selectedCustomer?.id,
                data: updateCustomerObj,
              },
              onCompleted: data => {
                resolve(data.updateCustomer);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedCustomer: ICustomer = (await res) as any;

      setSelectedCustomer(newUpdatedCustomer);
      refetchBillingCustomer();

      toastSuccess('Klant succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateCustomer = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedCustomer?.id) {
            const createCustomerObj: any = {
              billingID: selectedCustomer?.billingID,
              institution: selectedCustomer?.institution,
              institutionID: selectedCustomer?.institutionID,
              address: {
                address: selectedCustomer?.address?.address,
                zipcode: selectedCustomer?.address?.zipcode,
                city: selectedCustomer?.address?.city,
                country: selectedCustomer?.address?.country,
              },
              email: selectedCustomer?.email,
              phone: selectedCustomer?.phone,
              billingCustomerID: selectedBillingCustomer?.id,
            };

            createCustomer({
              variables: {
                data: createCustomerObj,
              },
              onCompleted: data => {
                resolve(data.createCustomer);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const customer: ICustomer = (await res) as any;
      setSelectedCustomer(customer);
      refetchBillingCustomer();

      toastSuccess('Klant succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const customerDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedCustomer,
    permission: '',
    selectedTab: selectedCustomerTab,
    setSelectedTab: setSelectedCustomerTab,
    handleUpdate: handleUpdateCustomer,
    handleCreate: handleCreateCustomer,
    delete: {
      title: 'Bestelling verwijderen?',
      text: 'Weet je zeker dat je deze bestelling wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'customer_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'customer_detail_section_0_field_0',
            label: 'Naam',
            name: 'institution',
            required: true,
            value: selectedCustomer?.institution,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_1',
            label: 'Instellingsnummer',
            name: 'institutionID',
            value: selectedCustomer?.institutionID,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              setInstitutionID(value);
              return value;
            },
          },
          {
            id: 'customer_detail_section_0_field_3',
            label: 'Telefoonnummer',
            name: 'phone',
            required: true,
            value: selectedCustomer?.phone,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Telefoon moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_2',
            label: 'Email',
            name: 'email',
            required: true,
            value: selectedCustomer?.email,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Email moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_4',
            label: 'Address',
            name: 'address',
            required: true,
            value: selectedCustomer?.address?.address,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Address moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_5',
            label: 'Postcode',
            name: 'zipcode',
            required: true,
            value: selectedCustomer?.address?.zipcode,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Postcode moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_6',
            label: 'Plaats',
            name: 'city',
            required: true,
            value: selectedCustomer?.address?.city,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Plaats moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_7',
            label: 'Land',
            name: 'country',
            required: true,
            value: selectedCustomer?.address?.country,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Land moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // ========== BILLING CUSTOMER STATE =========

  const [selectedBillingCustomer, setSelectedBillingCustomer] = useState<IBillingCustomer | undefined>();
  const [selectedBillingCustomerTab, setSelectedBillingCustomerTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingBillingCustomers, setSortingBillingCustomers] = useState<ISorting>({
    key: 'institution',
    direction: 'asc',
  });
  const [openBillingCustomerDetail, setOpenBillingCustomerDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openBillingCustomerCreate, setOpenBillingCustomerCreate] = useState(DEFAULT_DETAIL_OPEN);

  const [billingID, setBillingID] = useState('');
  const debouncedBillingIDQuery = useDebounce(billingID, 500);

  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery]);

  useEffect(() => {
    getDebounceBillingCustomer({
      variables: {
        billingID: debouncedBillingIDQuery,
      },
      onCompleted: data => {
        if (data.findOneBillingCustomerByBillingID && data.findOneBillingCustomerByBillingID !== null) {
          setSelectedBillingCustomer((prevState: any) => ({
            ...prevState,
            institution: data.findOneBillingCustomerByBillingID?.institution,
            phone: data.findOneBillingCustomerByBillingID?.phone,
            email: data.findOneBillingCustomerByBillingID?.email,
            address: {
              address: data.findOneBillingCustomerByBillingID?.address?.address,
              zipcode: data.findOneBillingCustomerByBillingID?.address?.zipcode,
              city: data.findOneBillingCustomerByBillingID?.address?.city,
              country: data.findOneBillingCustomerByBillingID?.address?.country,
            },
          }));
        }
      },
    });
  }, [debouncedBillingIDQuery]);

  const billingCustomerColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'billingCustomer.institution',
      render: (item: IBillingCustomer) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-18'>{item?.institution}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Email',
      fieldName: 'billingCustomer.email',
      render: (item: IBillingCustomer) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-46'>{item?.email}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Plaats',
      fieldName: 'billingCustomer.address.city',
      render: (item: IBillingCustomer) => {
        return <span className='text-ellipsis overflow-hidden block w-18 text-base'>{item?.address.city}</span>;
      },
    },
  ];

  const getOrderBy = useCallback(
    (sortingOrders: any) => {
      return { [sortingOrders.key]: sortingOrders.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingBillingCustomers],
  );

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ institution: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataBillingCustomers,
    loading: loadingBillingCustomers,
    error: errorBillingCustomers,
    refetch: refetchBillingCustomers,
    fetchMore: fetchMoreBillingCustomers,
  } = useQuery(GET_BILLINGCUSTOMERS, {
    variables: {
      filter: getFilters(searchQuery),
      orderBy: getOrderBy(sortingBillingCustomers),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getBillingCustomer, { loading: loadingBillingCustomer, error: errorBillingCustomer, refetch: refetchBillingCustomer }] =
    useLazyQuery(GET_BILLINGCUSTOMER);
  const [createBillingCustomer] = useMutation(CREATE_BILLINGCUSTOMER);
  const [updateBillingCustomer] = useMutation(UPDATE_BILLINGCUSTOMER);
  const [getDebounceBillingCustomer] = useLazyQuery(GET_BILLINGCUSTOMER_DEBOUNCE);

  const handleSetBillingCustomerSelection = (billingCustomer?: IBillingCustomer) => {
    if (billingCustomer?.id) {
      getBillingCustomer({
        variables: {
          where: {
            id: billingCustomer?.id,
          },
        },
        onCompleted: data => {
          setSelectedBillingCustomer({ ...data.findOneBillingCustomer });
          setOpenBillingCustomerDetail(true);
        },
      });
    } else {
      setSelectedBillingCustomer(undefined);
    }

    setOpenBillingCustomerDetail(true);
  };

  const handleUpdateBillingCustomer = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedBillingCustomer?.id) {
            const updateCustomerObj: any = {
              billingID: selectedBillingCustomer?.billingID,
              institution: selectedBillingCustomer?.institution,
              address: {
                address: selectedBillingCustomer?.address?.address,
                zipcode: selectedBillingCustomer?.address?.zipcode,
                city: selectedBillingCustomer?.address?.city,
                country: selectedBillingCustomer?.address?.country,
              },
              email: selectedBillingCustomer?.email,
              phone: selectedBillingCustomer?.phone,
            };

            updateBillingCustomer({
              variables: {
                id: selectedBillingCustomer?.id,
                data: updateCustomerObj,
              },
              onCompleted: data => {
                resolve(data.updateBillingCustomer);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedCustomer: IBillingCustomer = (await res) as any;

      setSelectedBillingCustomer(newUpdatedCustomer);
      refetchBillingCustomers({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingCustomers),
        skip: 0,
        take: pagedState.take,
      });

      toastSuccess('Factuur klant succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateBillingCustomer = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedBillingCustomer?.id) {
            const createCustomerObj: any = {
              billingID: selectedBillingCustomer?.billingID,
              institution: selectedBillingCustomer?.institution,
              address: {
                address: selectedBillingCustomer?.address?.address,
                zipcode: selectedBillingCustomer?.address?.zipcode,
                city: selectedBillingCustomer?.address?.city,
                country: selectedBillingCustomer?.address?.country,
              },
              email: selectedBillingCustomer?.email,
              phone: selectedBillingCustomer?.phone,
            };

            createBillingCustomer({
              variables: {
                data: createCustomerObj,
              },
              onCompleted: data => {
                resolve(data.createBillingCustomer);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const billingCustomer: IBillingCustomer = (await res) as any;
      setSelectedBillingCustomer(billingCustomer);
      refetchBillingCustomers({
        filter: getFilters(searchQuery),
        orderBy: getOrderBy(sortingCustomers),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      });

      toastSuccess('Factuur klant succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const billingCustomerDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedBillingCustomer,
    permission: '',
    selectedTab: selectedBillingCustomerTab,
    setSelectedTab: setSelectedBillingCustomerTab,
    handleUpdate: handleUpdateBillingCustomer,
    handleCreate: handleCreateBillingCustomer,
    delete: {
      title: 'Bestelling verwijderen?',
      text: 'Weet je zeker dat je deze bestelling wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'customer_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'customer_detail_section_0_field_0',
            label: 'Naam',
            name: 'institution',
            required: true,
            value: selectedBillingCustomer?.institution,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_1',
            label: 'BTW / ondernemingsnummer',
            name: 'billingID',
            required: true,
            value: selectedBillingCustomer?.billingID,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              setBillingID(value);
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_3',
            label: 'Telefoonnummer',
            name: 'phone',
            required: true,
            value: selectedBillingCustomer?.phone,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Telefoon moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_2',
            label: 'Email',
            name: 'email',
            required: true,
            value: selectedBillingCustomer?.email,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Email moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_4',
            label: 'Address',
            name: 'address',
            required: true,
            value: selectedBillingCustomer?.address?.address,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Address moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_5',
            label: 'Postcode',
            name: 'zipcode',
            required: true,
            value: selectedBillingCustomer?.address?.zipcode,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Postcode moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_6',
            label: 'Plaats',
            name: 'city',
            required: true,
            value: selectedBillingCustomer?.address?.city,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Plaats moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'customer_detail_section_0_field_7',
            label: 'Land',
            name: 'country',
            required: true,
            value: selectedBillingCustomer?.address?.country,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                address: {
                  ...prevState.address,
                  [name]: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Land moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
        ],
      },
      {
        id: 'customer_detail_section_1',
        title: 'Klanten',
        width: 100,
        fields: [
          {
            id: 'customer_detail_section_1_field_0',
            label: 'Klanten',
            name: 'customers',
            value: selectedBillingCustomer?.customers ? selectedBillingCustomer?.customers : [],
            table: {
              onCreate: handleSetCustomerSelection,
              columns: customerColumns,
              items: selectedBillingCustomer?.customers ? selectedBillingCustomer?.customers : [],
              onSelect: handleSetCustomerSelection,
              selection: selectedCustomer,
              showCreateCallback: () => {
                return true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'customer_detail_section_1_field_1',
            label: '',
            name: 'detail_customers',
            value: selectedBillingCustomer?.customers ? selectedBillingCustomer?.customers : [],
            detail: {
              open: openCustomerDetail,
              setOpen: setOpenCustomerDetail,
              title: 'Klant',
              caption: selectedCustomer?.id ? 'Klant updaten' : 'Klant toevoegen',
              schema: customerDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedBillingCustomer((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedBillingCustomer?.id) {
            return true;
          }
          return false;
        },
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Factuur klanten</h1>
      </header>
      <div>
        <div className='my-4'>
          <Button
            quaternary
            onClick={() => {
              handleSetBillingCustomerSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorBillingCustomers ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingBillingCustomers ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataBillingCustomers ? dataBillingCustomers.findManyBillingCustomers : []}
                columns={billingCustomerColumns}
                loading={loadingBillingCustomers}
                onSelect={handleSetBillingCustomerSelection}
                selection={selectedBillingCustomer}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  const skip = pagedState.skip + pagedState.take;

                  fetchMoreBillingCustomers({
                    variables: {
                      filter: getFilters(searchQuery),
                      orderBy: getOrderBy(sortingBillingCustomers),
                      skip: skip,
                      take: pagedState.take,
                    },
                  });

                  setPagedState(prevState => ({
                    ...prevState,
                    skip: skip,
                  }));
                }}
              />
            </div>

            <Modal open={openBillingCustomerDetail} setOpen={setOpenBillingCustomerDetail}>
              <>
                {openBillingCustomerDetail === true ? (
                  <>
                    {!loadingBillingCustomer && !errorBillingCustomer && (
                      <DetailForm
                        schema={billingCustomerDetailSchema}
                        title={'Factuur klant'}
                        caption={selectedBillingCustomer?.id ? 'Factuur klant updaten' : 'Factuur klant toevoegen'}
                      />
                    )}
                    {loadingBillingCustomer && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorBillingCustomer && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingCustomers;
