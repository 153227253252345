import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';
import { IAccount } from './account';
import { IEntitiy } from './entity';

export interface IProduct {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  active?: boolean;
  name?: string;
  accountID?: number;
  account?: IAccount;
  price?: number;
  availableForProposals?: boolean;
  entityID?: number;
  entity?: IEntitiy;
  vatPercentage?: number;
  service?: boolean;
}

export const GET_PRODUCTS = gql`
  query getProducts($filter: ProductFilterInput, $orderBy: ProductOrderByInput, $skip: Int, $take: Int) {
    findManyProducts(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      accountID
      name
      price
      vatPercentage
      active
      entityID
      entity {
        id
        title
      }
      availableForProposals
      service
    }
  }
`;

// full object for detail page
export const GET_PRODUCT = gql`
  query getProduct($where: ProductWhereInput) {
    findOneProduct(where: $where) {
      createdAt
      updatedAt
      id
      accountID
      account {
        id
        name
      }
      entityID
      entity {
        id
        title
      }
      availableForProposals
      service
      active
      name
      price
      vatPercentage
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductCreateInput!) {
    createProduct(data: $data) {
      createdAt
      updatedAt
      id
      accountID
      account {
        id
        name
      }
      entityID
      entity {
        id
        title
      }
      availableForProposals
      service
      active
      name
      price
      vatPercentage
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: Int!, $data: ProductUpdateInput) {
    updateProduct(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      accountID
      account {
        id
        name
      }
      entityID
      entity {
        id
        title
      }
      availableForProposals
      service
      name
      price
      active
      vatPercentage
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($where: ProductWhereInput) {
    deleteProduct(where: $where) {
      id
      name
    }
  }
`;

export const convertProductsToComboBoxOptions = (products: IProduct[]) => {
  if (!products) return [];

  const uniqueProductIds = new Set();
  return products
    .filter(product => {
      if (uniqueProductIds.has(product.id)) return false;
      uniqueProductIds.add(product.id);
      return true;
    })
    .map(
      (product: IProduct): IComboBoxOption => ({
        id: product.id as any,
        name: `${product.name}`,
        active: product.active,
      }),
    );
};
