import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { PlusIcon } from '@heroicons/react/24/solid';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import Button from '../../components/tailwind/button';
import { Modal } from '../../components/tailwind/modal';
import { SearchContext } from '../../context/searchContext';
import { CREATE_COUPON, DELETE_COUPON, GET_COUPON, GET_COUPONS, ICoupon, UPDATE_COUPON } from '../../utils/coupon';
import { ISorting } from '../../types';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  resolveMoney,
} from '../../utils';
import { IColumn, Table } from '../../components/tailwind/table';
import { toastError, toastSuccess } from '../../utils/toast';
import DetailForm, { DetailSchema } from '../../components/detailForm';

export const Coupons: React.FC = ({ ...props }) => {
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const [selectedCoupon, setSelectedCoupon] = useState<ICoupon | undefined>();
  const [selectedCouponTab, setSelectedCouponTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingCoupons, setSortingCoupons] = useState<ISorting>(DEFAULT_SORTING);
  const [openCouponDetail, setOpenCouponDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openCouponxCreate, setOpenCouponCreate] = useState(DEFAULT_DETAIL_OPEN);

  const couponColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Code',
      fieldName: 'product.name',
      render: (item: ICoupon) => {
        return <span className={classNames('text-ellipsis text-base overflow-hidden block w-18')}>{item?.code}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Waarde',
      fieldName: 'product.price',
      render: (item: ICoupon) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46')}>
            {item?.value && resolveMoney(item?.value)}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Resterend',
      fieldName: 'product.price',
      render: (item: ICoupon) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46')}>
            {item?.value && resolveMoney(item?.left ? item?.left : 0)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Geldig tot',
      fieldName: 'product.usage',
      render: (item: ICoupon) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46')}>
            {item?.validUntil ? moment(item.validUntil).format('DD/MM/YY') : ''}
          </span>
        );
      },
    },
  ];

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ description: { contains: filterArray[i] } }, { code: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataCoupons,
    loading: loadingCoupons,
    error: errorCoupons,
    refetch: refetchCoupons,
    fetchMore: fetchMoreCoupons,
  } = useQuery(GET_COUPONS, {
    variables: {
      filter: getFilters(searchQuery),
    },
  });

  const [getCoupon, { loading: loadingCoupon, error: errorCoupon, refetch: refetchCoupon }] = useLazyQuery(GET_COUPON);
  const [createCoupon] = useMutation(CREATE_COUPON);
  const [updateCoupon] = useMutation(UPDATE_COUPON);
  const [deleteCoupon] = useMutation(DELETE_COUPON);

  const handleSetCouponSelection = (coupon?: ICoupon) => {
    if (coupon?.id) {
      getCoupon({
        variables: {
          where: {
            id: coupon?.id,
          },
        },
        onCompleted: data => {
          setSelectedCoupon(data.findOneCoupon);
          setOpenCouponDetail(true);
        },
      });
    } else {
      setSelectedCoupon(undefined);
    }

    setOpenCouponDetail(true);
  };

  const handleUpdateCoupon = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedCoupon?.id) {
            const updateProductObj: any = {
              validUntil: moment(selectedCoupon?.validUntil).toISOString(),
              value: selectedCoupon?.value ? parseFloat(selectedCoupon?.value.toString()) : 0,
              description: selectedCoupon?.description,
            };

            updateCoupon({
              variables: {
                id: selectedCoupon?.id,
                data: updateProductObj,
              },
              onCompleted: data => {
                resolve(data.updateCoupon);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: ICoupon = (await res) as any;

      setSelectedCoupon(newUpdatedProduct);
      refetchCoupons();

      toastSuccess('Waardebon succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateCoupon = async () => {
    try {
      console.log(selectedCoupon);
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedCoupon?.id) {
            const createProductObj: any = {
              validUntil: moment(selectedCoupon?.validUntil).toISOString(),
              value: selectedCoupon?.value ? parseFloat(selectedCoupon?.value.toString()) : 0,
              description: selectedCoupon?.description,
            };

            createCoupon({
              variables: {
                data: createProductObj,
              },
              onCompleted: data => {
                resolve(data.createCoupon);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const coupon: ICoupon = (await res) as any;
      setSelectedCoupon(coupon);
      refetchCoupons();

      toastSuccess('Coupon succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };
  const currentDate = new Date();
  const handleDeleteCoupon = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedCoupon?.id) {
            deleteCoupon({
              variables: {
                where: {
                  id: selectedCoupon?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedCoupon(undefined);
      refetchCoupons();
      setOpenCouponDetail(false);

      toastSuccess('Coupon succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const accountDetailSchema: DetailSchema = {
    id: 'account_detail',
    object: selectedCoupon,
    permission: '',
    selectedTab: selectedCouponTab,
    setSelectedTab: setSelectedCouponTab,
    handleUpdate: handleUpdateCoupon,
    handleCreate: handleCreateCoupon,
    handleDelete: handleDeleteCoupon,
    delete: {
      title: 'Waardebon verwijderen?',
      text: 'Weet je zeker dat je deze waardebon wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'coupon_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'account_detail_section_0_field_1c',
            label: 'Code',
            name: 'value',
            required: true,
            value: selectedCoupon?.code,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              return;
            },

            disableCallback: (value?: string) => {
              return selectedCoupon?.id ? true : false;
            },
            hideCallback: (value?: string) => {
              return selectedCoupon?.id ? true : false;
            },
          },
          {
            id: 'account_detail_section_0_field_1',
            label: 'Waarde',
            name: 'value',
            required: true,
            value: selectedCoupon?.value,
            placeholder: '',
            type: 'number',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCoupon((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Code moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'event_detail_section_0_field_2',
            label: 'Geldig tot',
            name: 'validUntil',
            required: true,
            value: selectedCoupon?.validUntil ? moment(selectedCoupon?.validUntil).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedCoupon((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Geldig tot moet worden ingevuld',
                };
              }

              if (moment(value).isBefore(moment().startOf('day'))) {
                return {
                  valid: false,
                  message: 'Datum mag niet in het verleden zijn', // "Date cannot be in the past"
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return false;
            },
          },
          {
            id: 'event_detail_section_0_field_7',
            label: 'Beschrijving',
            name: 'description',
            required: false,
            value: selectedCoupon?.description ? selectedCoupon?.description : '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedCoupon((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              return { valid: true };
            },
            disableCallback: () => {
              return false;
            },
          },
        ],
      },
    ],
  };

  return (
    <div id='coupons'>
      <header>
        <h1 className='font-bold text-2xl'>Waardebonnen</h1>
      </header>
      <div>
        <div className='my-4'>
          <Button
            quaternary
            onClick={() => {
              handleSetCouponSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorCoupon ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingCoupons ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={(dataCoupons?.findManyCoupons || []).filter(
                  (coupon: any) => new Date(coupon.validUntil) > currentDate && coupon.left > 0,
                )}
                columns={couponColumns}
                loading={loadingCoupons}
                onSelect={handleSetCouponSelection}
                selection={selectedCoupon}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  fetchMoreCoupons({
                    variables: {
                      filter: getFilters(searchQuery),
                      // orderBy: getOrderBy(sortingOrders),
                      skip: dataCoupons ? dataCoupons.findManyCoupons.length : 0,
                      take: DEFAULT_TAKE_LOADMORE,
                    },
                  });
                }}
              />
            </div>

            <Modal open={openCouponDetail} setOpen={setOpenCouponDetail}>
              <>
                {openCouponDetail === true ? (
                  <>
                    {!loadingCoupon && !errorCoupons && (
                      <DetailForm
                        schema={accountDetailSchema}
                        title={'Waardebonnen'}
                        caption={selectedCoupon?.id ? 'Waardebon updaten' : 'Waardebon toevoegen'}
                      />
                    )}
                    {loadingCoupon && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorCoupon && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Coupons;
