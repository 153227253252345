import { gql } from '@apollo/client';
import { IAccount } from './account';
import { IProduct } from './product';
import { IProposalLineUser } from './proposalLineUser';

export interface IProposalLine {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  product: IProduct;
  productID: number;
  title: string;
  description: string;
  price: number;
  vatPercentage: number;
  quantity: number;
  account: IAccount;
  accountID: number;
  date: string;
  proposalLineUsers?: IProposalLineUser[];

  executed?: boolean;
  actuallyInvoiced?: boolean;
  invoiced?: boolean;
  service?: boolean;
}

export const GET_PROPOSAL_LINES = gql`
  query getProposalLines($filter: ProposalLineFilterInput) {
    findManyProposalLines(filter: $filter) {
      createdAt
      updatedAt
      id
      service
      product {
        id
        price
        service
      }
      title
      description
      price
      vatPercentage
      quantity
      account {
        id
      }
      accountID
      date
      invoiced
      executed
    }
  }
`;

// full object for detail page
export const GET_PROPOSAL_LINE = gql`
  query getProposalLine($where: ProposalLineWhereInput) {
    findOneProposalLine(where: $where) {
      createdAt
      updatedAt
      service
      id
      product {
        id
        price
        service
      }
      productID
      title
      description
      price
      vatPercentage
      quantity
      account {
        id
      }
      accountID
      date
      proposalLineUsers {
        id
        userID
        user {
          firstName
          lastName
          jobTitle
        }
      }

      invoiced
      executed
    }
  }
`;

export const CREATE_PROPOSAL_LINE = gql`
  mutation createProposalLine($data: ProposalLineCreateInput!) {
    createProposalLine(data: $data) {
      createdAt
      updatedAt
      id
      service
      product {
        id
        price
        service
      }
      productID
      title
      description
      price
      vatPercentage
      quantity
      account {
        id
      }
      accountID
      date
      proposalLineUsers {
        id
        userID
        user {
          firstName
          lastName
          jobTitle
        }
      }

      invoiced
      executed
    }
  }
`;

export const UPDATE_PROPOSAL_LINE = gql`
  mutation updateProposalLine($id: Int!, $data: ProposalLineUpdateInput) {
    updateProposalLine(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      product {
        id
        price
        service
      }
      productID
      service
      title
      description
      price
      vatPercentage
      quantity
      account {
        id
      }
      proposalLineUsers {
        id
        userID
        user {
          firstName
          lastName
          jobTitle
        }
      }
      accountID
      date

      invoiced
      executed
    }
  }
`;

export const UPDATE_PROPOSAL_LINE_EXECUTED = gql`
  mutation updateProposalLineExecuted($id: Int!, $data: ProposalLineUpdateInput) {
    updateProposalLineExecuted(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      product {
        id
        price
        service
      }
      service
      productID
      title
      description
      price
      vatPercentage
      quantity
      account {
        id
      }
      proposalLineUsers {
        id
        userID
        user {
          firstName
          lastName
          jobTitle
        }
      }
      accountID
      date

      invoiced
      executed
    }
  }
`;

export const DELETE_PROPOSAL_LINE = gql`
  mutation deleteProposalLine($where: ProposalLineWhereInput) {
    deleteProposalLine(where: $where) {
      id
      title
      description
      price
      vatPercentage
      quantity
      service
    }
  }
`;
