import { gql } from '@apollo/client';
import { ICustomer } from './customer';

export interface IBillingCustomer {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  billingID?: string;
  institution?: string;

  address?: any;

  email?: string;
  phone?: string;

  customers?: ICustomer[];
}

export const GET_BILLINGCUSTOMERS = gql`
  query getBillingCustomer($filter: BillingCustomerFilterInput, $skip: Int, $take: Int) {
    findManyBillingCustomers(filter: $filter, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      institution
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
    }
  }
`;

// full object for detail page
export const GET_BILLINGCUSTOMER = gql`
  query getBillingCustomers($where: BillingCustomerWhereInput) {
    findOneBillingCustomer(where: $where) {
      createdAt
      updatedAt
      id
      institution
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      customers {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const GET_BILLINGCUSTOMER_DEBOUNCE = gql`
  query getBillingCustomers($billingID: String) {
    findOneBillingCustomerByBillingID(billingID: $billingID) {
      institution
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
    }
  }
`;

export const CREATE_BILLINGCUSTOMER = gql`
  mutation createBillingCustomer($data: BillingCustomerCreateInput!) {
    createBillingCustomer(data: $data) {
      createdAt
      updatedAt
      id
      institution
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      customers {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;

export const UPDATE_BILLINGCUSTOMER = gql`
  mutation updateBillingCustomer($id: Int!, $data: BillingCustomerUpdateInput) {
    updateBillingCustomer(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      institution
      billingID
      address {
        address
        city
        zipcode
        country
      }
      email
      phone
      customers {
        id
        createdAt
        updatedAt
        billingID
        institution
        address {
          address
          city
          zipcode
          country
        }
        email
        phone
      }
    }
  }
`;
