import { RefObject } from 'react';
import { IComboBoxOption } from '../types';

export const CEGO_LOGO = 'https://res.cloudinary.com/dzu3bqpsr/image/upload/v1688140107/website%20assets/cego_logo_iqt6px.png';
export const CEGO_ROLES = [
  {
    name: 'Administrator',
    description: '',
    key: 'admin',
  },
  {
    name: 'Docent',
    description: '',
    key: 'docent',
  },
  {
    name: 'Freelance',
    description: '',
    key: 'freelance',
  },
];

export const CEGO_LEGAL_NAME = 'CEGO VZW';
export const CEGO_LEGAL_ADDRESS = {
  address: 'Schapenstraat 34 bus 3776',
  city: 'Leuven',
  zipcode: '3000',
  country: 'Belgie',
};
export const CEGO_LEGAL_VAT = 'BE 0421.083.235';
export const CEGO_LEGAL_IBAN = 'BE90 7765 9491 3932';
export const CEGO_LEGAL_BIC = 'GKCCBEBB';
export const CEGO_LEGAL_EMAIL = 'info@cego.be';
export const CEGO_LEGAL_PHONE = '+32 16 32 57 40';
export const CEGO_LEGAL_WEBSITE = 'www.cego.be';

export const CEGO_LEGAL_QUOTE_DAYS_VALID = 14;
export const CEGO_LEGAL_QUOTE_ENDING = `Deze offerte blijft ${CEGO_LEGAL_QUOTE_DAYS_VALID} dagen geldig. Fouten in de offerte kunnen herroepen worden en nadien door ${CEGO_LEGAL_NAME} rechtgezet worden.`;

export const DEFAULT_TOAST_DURATION = 800;
export const DEFAULT_TOAST_POSITION = 'top-left';

export const DEFAULT_LOADING_MESSAGE = 'laden...';
export const DEFAULT_ERROR_MESSAGE = 'Er is iets misgelopen.';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_FORMAT_LONG = 'DD/MM/YYYY HH:mm';

export const DEFAULT_SELECTED_TAB = 'Algemeen';
export const DEFAULT_DETAIL_OPEN = false;

export const DEFAULT_SKIP = 0;
export const DEFAULT_TAKE = 10;
export const DEFAULT_TAKE_LOADMORE = 4;

export const PROPOSAL_STATUS_OPTIONS = [
  {
    id: 'Nieuw',
    name: 'Nieuw',
    active: true,
  },
  {
    id: 'Verstuurd',
    name: 'Verstuurd',
    active: true,
  },
  {
    id: 'In behandeling',
    name: 'In behandeling',
    active: true,
  },
  {
    id: 'Goedgekeurd',
    name: 'Goedgekeurd',
    active: true,
  },
  {
    id: 'Voltooid',
    name: 'Voltooid',
    active: true,
  },
  {
    id: 'Open',
    name: 'Open',
    active: true,
  },
  {
    id: 'Geannuleerd',
    name: 'Geannuleerd',
    active: true,
  },
];

export const DEFAULT_SORTING = {
  key: 'startTime',
  direction: 'asc',
};

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const resolveDay = (day: number): string => {
  const defaultDay = 'maandag';

  switch (day) {
    case 0:
      return 'zondag';
    case 1:
      return 'maandag';
    case 2:
      return 'dinsdag';
    case 3:
      return 'woensdag';
    case 4:
      return 'donderdag';
    case 5:
      return 'vrijdag';
    case 6:
      return 'zaterdag';
    default:
      return defaultDay;
  }
};

export const resolveValueForCombobox = (id: string | number, list: IComboBoxOption[]) => {
  const item = list.find((item: IComboBoxOption) => item.id.toString() === id.toString());
  return item;
};

export const resolveEntityColor = (title: string): string => {
  const defaultColor = '#1D1D1B';

  switch (title) {
    case 'Kinderopvang':
      return '#ff8e3b';
    case 'Onderwijs':
      return '#20A558';
    case 'Kleuteronderwijs':
      return '#FF4536';
    case 'Lager onderwijs':
      return '#0972D8';
    case 'Secundair onderwijs':
      return '#9bc835';
    default:
      return defaultColor;
  }
};

export const resolveMoney = (amount: number): string => {
  if (amount) {
    return '€' + amount.toFixed(2).replace('.', ',');
  }
  return '€0,00';
};

export const resolveEndOfPeriod = (day: number, duration: number): string | undefined => {
  if (day && duration) {
    let end = day + duration - 1;
    if (end > 6) {
      end -= 7;
    }

    return resolveDay(end);
  }
  return undefined;
};

export const resolveStringOfUsers = (users: any[]): string => {
  if (users && users.length > 0) {
    return users.map((user: any) => `${user.user.firstName} ${user.user.lastName}`).join(', ');
  }
  return '';
};

export const getRefElement = <T>(element?: RefObject<Element> | T): Element | T | undefined | null => {
  if (element && typeof element === 'object' && 'current' in element) {
    return element.current;
  }

  return element;
};

export const isSSR = !(typeof window !== 'undefined' && window.document?.createElement);

export const filterComboBoxItem = (items: any[]) => {
  return items.filter(item => item.active === true);
};

export const filterComboBoxUserItem = (items: any[]) => {
  const t = items.filter(item => item.active === true);

  return t.filter(item => item.blocked === false);
};

export const filterComboBoxEntityItem = (items: any[]) => {
  const t = items.filter(item => item.active === true);

  return t;
};

export function hasPermission(permission: string[], perm: string) {
  return permission.includes(perm);
}

export function stringToSlug(str: string) {
  return (
    str
      // Normalize the string to NFD Unicode normal form (decomposing characters into their basic components)
      .normalize('NFD')
      // Replace characters that are not alphanumeric or spaces with an empty string
      .replace(/[\u0300-\u036f]/g, '')
      // Convert the string to lowercase
      .toLowerCase()
      // Replace all spaces with hyphens
      .replace(/\s+/g, '-')
      // Remove all remaining non-word characters except for hyphens
      .replace(/[^\w\-]+/g, '')
      // Replace multiple hyphens with a single hyphen
      .replace(/\-\-+/g, '-')
  );
}

// Example usage:
const slug = stringToSlug('Hello, World! This is a test?');
console.log(slug); // Output: "hello-world-this-is-a-test"

export const transformCloudinaryUrl = (url?: string) => {
  const paramsToAdd = 'f_auto,q_auto,fl_lossy';

  if (!url) {
    return '';
  }

  // Check if the URL is a Cloudinary URL
  const cloudinaryPattern = /^https?:\/\/res\.cloudinary\.com\//;
  if (!cloudinaryPattern.test(url)) {
    return url;
  }

  const urlParts = url.split('/');

  // Find the index of the "upload" segment
  const uploadIndex = urlParts.indexOf('upload');

  // If "upload" is found, insert the new parameters immediately after it
  if (uploadIndex !== -1) {
    urlParts.splice(uploadIndex + 1, 0, paramsToAdd);
  }

  // Reconstruct the URL
  const transformedUrl = urlParts.join('/');

  // Ensure the URL uses https
  return transformedUrl.replace('http://', 'https://');
};
