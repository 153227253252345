import { gql } from '@apollo/client';
import { IAccount } from './account';
import { ICustomer } from './customer';
import { IProposalType } from './proposalType';
import { IUser } from './user';
import { IProposalLine } from './proposalLine';
import { IDirectInvoice } from './directInvoice';

export interface IProposal {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  entity?: string;

  customer?: ICustomer;
  customerID?: number;

  owner?: IUser;
  ownerID?: number;

  proposalTypeID: number;
  proposalType?: IProposalType;
  poNo?: string;
  status: string;

  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;

  fullyInvoiced?: boolean;
  fullyExecuted?: boolean;
  invoiceAbleLines?: boolean;

  date?: string; // datetime
  agreementDate?: string; // datetime
  suggestedDate?: string;
  suggestedDateAlternative?: string;
  comments?: string;
  name?: string;
  body?: string;
  documentNo?: string;
  totalPrice?: number;
  servicePrice?: number;

  gdpr: boolean;
  allowMarketing: boolean;
  allowPhotos: boolean;
  directInvoice: boolean;
  actuallyDirectInvoice: boolean;

  proposalLines?: IProposalLine[];
  mailings?: any[];
  invoices?: any[];
  directInvoices?: IDirectInvoice[];
  candidates: {
    id: number;
    user: IUser;
  }[];
  employees: IUser[];
}

export interface ICreditNote {
  invoice_id: number;
  information: string;
}

export function extractCreditNotes(invoiceList: any[] | undefined): ICreditNote[] {
  const creditNotesList: ICreditNote[] = [];

  if (invoiceList === undefined) {
    return creditNotesList;
  }

  invoiceList.forEach((invoice: any) => {
    const eenvouwdigFacturerenInvoice = invoice.eenvouwdigFacturerenInvoice;
    if (eenvouwdigFacturerenInvoice && eenvouwdigFacturerenInvoice.creditnotes) {
      eenvouwdigFacturerenInvoice.creditnotes.forEach((creditNote: any) => {
        creditNotesList.push(creditNote);
      });
    }
  });

  return creditNotesList;
}

export const GET_PROPOSALS_CANDIDATES = gql`
  query getProposalsCandidates($filter: ProposalFilterInput, $orderBy: ProposalOrderByInput, $skip: Int, $take: Int) {
    findManyProposalsCandidates(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      entity
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
      }
      owner {
        firstName
        lastName
      }
      proposalLines {
        id
      }

      invoiceAbleLines
      totalPrice
      servicePrice
      ownerID
      status
      documentNo
      candidates {
        id
        user {
          auth0ID
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PROPOSALS = gql`
  query getProposals($filter: ProposalFilterInput, $orderBy: ProposalOrderByInput, $skip: Int, $take: Int) {
    findManyProposals(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      createdAt
      entity
      updatedAt
      id
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
      }
      owner {
        firstName
        lastName
      }
      proposalLines {
        id
      }
      fullyInvoiced
      fullyExecuted
      invoiceAbleLines
      totalPrice
      servicePrice
      ownerID
      directInvoice
      actuallyDirectInvoice
      status
      documentNo
      candidates {
        id
        user {
          auth0ID
          firstName
          lastName
        }
      }
      employees {
        id
        auth0ID
        firstName
        lastName
        image
      }
    }
  }
`;

// full object for detail page
export const GET_PROPOSAL = gql`
  query getProposal($where: ProposalWhereInput) {
    findOneProposal(where: $where) {
      createdAt
      updatedAt
      id
      entity
      poNo
      candidates {
        id
        userID
        user {
          auth0ID
          firstName
          lastName
        }
      }
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
        institutionID
        email
        phone
        address {
          address
          city
          zipcode
          country
        }
        billingCustomer {
          id
          createdAt
          updatedAt
          billingID
          institution
          address {
            address
            city
            zipcode
            country
          }
          email
          phone
        }
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      totalPrice
      servicePrice
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        proposalLineUsers {
          id
          userID
          user {
            firstName
            lastName
            jobTitle
            image
          }
        }
        date
        invoiced
        actuallyInvoiced
        executed
      }
      gdpr
      allowMarketing
      allowPhotos
      directInvoice
      actuallyDirectInvoice
      directInvoices {
        id
        price
        date
        vat
        description
        eenvouwdigFacturerenID
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      mailings {
        id
        createdAt
        type
        subject
        sender
        recipients
        attachments
      }
    }
  }
`;

export const UPDATE_PROPOSAL = gql`
  mutation updateProposal($id: Int!, $data: ProposalUpdateInput) {
    updateProposal(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      poNo
      totalPrice
      servicePrice
      gdpr
      allowMarketing
      allowPhotos
      entity
      directInvoice
      actuallyDirectInvoice
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
        email
        address {
          address
          city
          zipcode
          country
        }
        billingCustomer {
          id
          createdAt
          updatedAt
          billingID
          institution
          address {
            address
            city
            zipcode
            country
          }
          email
          phone
        }
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        proposalLineUsers {
          id
          userID
          user {
            firstName
            lastName
            jobTitle
          }
        }
        date
        invoiced
        actuallyInvoiced
        executed
      }
      directInvoices {
        id
        price
        description
        date
        eenvouwdigFacturerenID
        vat
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      mailings {
        id
        createdAt
        type
        subject
        sender
        recipients
        attachments
      }
    }
  }
`;

export const CANCEL_PROPOSAL = gql`
  mutation cancelProposal($id: Int!, $data: ProposalUpdateInput) {
    cancelProposal(id: $id, data: $data) {
      createdAt
      updatedAt
      id
      poNo
      totalPrice
      servicePrice
      gdpr
      allowMarketing
      allowPhotos
      entity
      directInvoice
      actuallyDirectInvoice
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
        email
        address {
          address
          city
          zipcode
          country
        }
        billingCustomer {
          id
          createdAt
          updatedAt
          billingID
          institution
          address {
            address
            city
            zipcode
            country
          }
          email
          phone
        }
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        proposalLineUsers {
          id
          userID
          user {
            firstName
            lastName
            jobTitle
          }
        }
        date
        invoiced
        actuallyInvoiced
        executed
      }
      directInvoices {
        id
        price
        description
        date
        eenvouwdigFacturerenID
        vat
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      mailings {
        id
        createdAt
        type
        subject
        sender
        recipients
        attachments
      }
    }
  }
`;

export const CREATE_PROPOSAL = gql`
  mutation createProposal($data: ProposalCreateInput!) {
    createProposal(data: $data) {
      createdAt
      updatedAt
      id
      poNo
      entity
      gdpr
      allowMarketing
      allowPhotos
      directInvoice
      actuallyDirectInvoice
      totalPrice
      servicePrice
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
        institutionID
        email
        phone
        address {
          address
          city
          zipcode
          country
        }
        billingCustomer {
          id
          createdAt
          updatedAt
          billingID
          institution
          address {
            address
            city
            zipcode
            country
          }
          email
          phone
        }
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        proposalLineUsers {
          id
          userID
          user {
            firstName
            lastName
            jobTitle
          }
        }
        invoiced
        actuallyInvoiced
        executed
      }
      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      directInvoices {
        id
        price
        description
        date
        eenvouwdigFacturerenID
        vat
      }
      mailings {
        id
        createdAt
        type
        subject
        sender
        recipients
        attachments
      }
    }
  }
`;

export const DELETE_PROPOSAL = gql`
  mutation deleteProposal($where: ProposalWhereInput) {
    deleteProposal(where: $where) {
      id
    }
  }
`;

export const INVOICE_PROPOSAL_LINES = gql`
  mutation invoiceProposalLines($data: ProposalInvoiceLinesInput!) {
    invoiceProposalLines(data: $data) {
      createdAt
      updatedAt
      id
      poNo
      totalPrice
      servicePrice
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        invoiced
        actuallyInvoiced
        executed
      }
    }
  }
`;

export const SEND_NOTIFICATIONS = gql`
  mutation sendNotifications($data: NotificationsSendInput!) {
    sendNotifications(data: $data) {
      createdAt
      updatedAt
      id
      poNo
      gdpr
      allowMarketing
      allowPhotos
      directInvoice
      actuallyDirectInvoice
      totalPrice
      servicePrice
      proposalTypeID
      proposalType {
        title
      }
      customer {
        institution
        institutionID
        email
        phone
        address {
          address
          city
          zipcode
          country
        }
        billingCustomer {
          id
          createdAt
          updatedAt
          billingID
          institution
          address {
            address
            city
            zipcode
            country
          }
          email
          phone
        }
      }
      owner {
        firstName
        lastName
      }
      ownerID
      firstName
      lastName
      email
      phone
      date
      agreementDate
      comments
      name
      body
      suggestedDate
      suggestedDateAlternative
      documentNo
      status
      proposalLines {
        id
        title
        price
        vatPercentage
        quantity
        description
        proposalLineUsers {
          id
          userID
          user {
            firstName
            lastName
            jobTitle
          }
        }
        invoiced
        actuallyInvoiced
        executed
      }
      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      directInvoices {
        id
        price
        description
        date
        eenvouwdigFacturerenID
        vat
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
      mailings {
        id
        createdAt
        type
        subject
        sender
        recipients
        attachments
      }
    }
  }
`;
