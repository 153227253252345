import { gql } from '@apollo/client';
import { IComboBoxOption } from '../types';

export interface IProposalType {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;
  title?: string;
}

export const GET_PROPOSAL_TYPES = gql`
  query getProposalTypes($filter: ProposalTypeFilterInput, $take: Int, $skip: Int, $orderBy: ProposalTypeOrderByInput) {
    findManyProposalTypes(filter: $filter, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      createdAt
      title
    }
  }
`;

export const convertProposalTypesToComboBoxOptions = (proposalTypes: IProposalType[]) => {
  if (proposalTypes) {
    const result: IComboBoxOption[] = proposalTypes.map(
      (proposalType: IProposalType): IComboBoxOption => ({
        id: proposalType.id as any,
        name: `${proposalType.title}`,
        active: true, //no attribute active in proposalType
      }),
    );
    return result;
  }

  return [];
};
