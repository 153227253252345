import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import jwt_decode from 'jwt-decode';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Events from './routes/evenements';
import { AuthenticationGuard } from './components/authenticationGuard';
import { SearchProvider } from './context/searchContext';
import ErrorPage from './error-page';
import Accounts from './routes/accounts';
import BillingCustomers from './routes/billingCustomer';
import Customers from './routes/customers';
import Activities from './routes/activities';
import Orders from './routes/orders';
import Products from './routes/products';
import Proposals from './routes/proposals';
import Root from './routes/root';
import Coupons from './routes/coupons';
import Tickets from './routes/tickets';
import Users from './routes/users';
import Venues from './routes/venues';
import candidates, { Candidates } from './routes/candidates';
import { PermissionContext, PermissionProvider } from './context/permissionContext';
import Prestaties from './routes/prestaties';

function App() {
  const backend_uri = process.env.REACT_APP_BACKEND_URI;
  const { permissions, setPermissions } = useContext(PermissionContext);
  const [token, setToken] = useState<string | undefined>();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: backend_uri,
  });

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently().then(token => {
        setToken(token);
      });
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently().then(token => {
        setToken(token);
        const decoded: any = jwt_decode(token);
        if (decoded && typeof decoded === 'object' && decoded.permissions) {
          setPermissions(decoded.permissions);
        }
      });
    }
  }, [isAuthenticated, isLoading]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            findManyOrders: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyEvents: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyTickets: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyActivities: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyProposals: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyProposalsCandidates: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyCustomers: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyBillingCustomers: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyUsers: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyAccounts: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'orderBy'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            findManyProducts: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AuthenticationGuard component={(<Root />) as any} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'vormingen',
          element: <AuthenticationGuard component={(<Activities />) as any} permission='nav:vormingen' />,
        },
        {
          path: 'events',
          element: <AuthenticationGuard component={(<Events />) as any} permission='nav:evenementen' />,
        },
        {
          path: 'tickets',
          element: <AuthenticationGuard component={(<Tickets />) as any} permission='nav:tickets' />,
        },
        {
          path: 'bestellingen/:id',
          element: <AuthenticationGuard component={(<Orders />) as any} permission='nav:bestellingen' />,
        },
        {
          path: 'bestellingen',
          element: <AuthenticationGuard component={(<Orders />) as any} permission='nav:bestellingen' />,
        },
        {
          path: 'coupons',
          element: <AuthenticationGuard component={(<Coupons />) as any} permission='nav:bestellingen' />,
        },
        {
          path: 'locaties',
          element: <AuthenticationGuard component={(<Venues />) as any} permission='nav:locaties' />,
        },
        {
          path: 'gebruikers',
          element: <AuthenticationGuard component={(<Users />) as any} permission='nav:gebruikers' />,
        },
        {
          path: 'klanten',
          element: <AuthenticationGuard component={(<Customers />) as any} permission='nav:klanten' />,
        },
        {
          path: 'factuurklanten',
          element: <AuthenticationGuard component={(<BillingCustomers />) as any} permission='nav:klanten' />,
        },
        {
          path: 'rekeningen',
          element: <AuthenticationGuard component={(<Accounts />) as any} permission='nav:rekeningen' />,
        },
        {
          path: 'producten',
          element: <AuthenticationGuard component={(<Products />) as any} permission='nav:producten' />,
        },
        {
          path: 'aanvragen',
          element: <AuthenticationGuard component={(<Proposals />) as any} permission='nav:aanvragen' />,
        },
        {
          path: 'candidates',
          element: <AuthenticationGuard component={(<Candidates />) as any} permission='nav:aanvragen' />,
        },
        {
          path: 'candidates/:id',
          element: <AuthenticationGuard component={(<Candidates />) as any} permission='nav:aanvragen' />,
        },
        {
          path: 'prestaties',
          element: <AuthenticationGuard component={(<Prestaties />) as any} permission='nav:aanvragen' />,
        },
      ],
    },
  ]);

  return (
    <ApolloProvider client={client}>
      <SearchProvider>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <RouterProvider router={router} />
        </DndProvider>
      </SearchProvider>
    </ApolloProvider>
  );
}

export default App;
