import { gql } from '@apollo/client';
import { IComboBoxOption, IRole } from '../types';

export interface IUser {
  id?: number;

  createdAt?: Date;
  updatedAt?: Date;

  image?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  auth0ID?: string;
  jobTitle?: string;
  roles?: IRole[];
  blocked?: boolean;
  active?: boolean;
  userEntities?: any[];
}

export interface ICreateUser {
  image?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  auth0ID?: string;
  jobTitle?: string;
  roles?: IRole[];
  blocked?: boolean;
  active?: boolean;
}

export interface IUpdateUser {
  image?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  auth0ID?: string;
  jobTitle?: string;
  roles?: IRole[];
  blocked?: boolean;
  active?: boolean;
}

//limited obj for overvieuw table
export const GET_USERS = gql`
  query getUsers($filter: UserFilterInput, $orderBy: UserOrderByInput, $skip: Int, $take: Int) {
    findManyUsers(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      firstName
      lastName
      email
      jobTitle
      blocked
      active
      image
    }
  }
`;

// full object for detail page
export const GET_USER = gql`
  query getUser($where: UserWhereInput) {
    findOneUser(where: $where) {
      id
      image
      firstName
      lastName
      email
      jobTitle
      auth0ID
      createdAt
      updatedAt
      roles {
        name
        description
        key
      }
      userEntities {
        id
        entity {
          title
        }
      }
      blocked
      active
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      image
      firstName
      lastName
      email
      jobTitle
      auth0ID
      createdAt
      updatedAt
      userEntities {
        id
        entity {
          title
        }
      }
      roles {
        name
        description
        key
      }
      blocked
      active
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $data: UserUpdateInput) {
    updateUser(id: $id, data: $data) {
      id
      image
      firstName
      lastName
      email
      jobTitle
      auth0ID
      createdAt
      userEntities {
        id
        entity {
          title
        }
      }
      updatedAt
      roles {
        name
        description
        key
      }
      blocked
      active
    }
  }
`;

export const convertUsersToComboBoxOptions = (users: IUser[]) => {
  if (users) {
    const result: IComboBoxOption[] = users.map(
      (user: IUser): IComboBoxOption => ({
        id: user.id as any,
        name: `${user.firstName} ${user.lastName}`,
        active: user.active && !user.blocked,
      }),
    );

    return [...result, { id: 'selecteer', name: 'selecteer', active: true }];
  }

  return [];
};
