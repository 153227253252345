/* eslint-disable @next/next/no-img-element */
import { useRouteError, Link } from 'react-router-dom';
import { CEGO_LOGO } from './utils';

export const ErrorPage: React.FC = ({ ...props }) => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
      <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
        <div className='flex flex-shrink-0 justify-center'>
          <a href='/' className='inline-flex'>
            <span className='sr-only'>Your Company</span>
            <img className='h-16 md:h-24 w-auto' src={CEGO_LOGO} alt='Cego logo' />
          </a>
        </div>
        <div className='py-16'>
          <div className='text-center'>
            <p className='text-6xl font-bold text-cego-black'>404</p>
            <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Page not found.</h1>
            <p className='mt-2 text-base text-gray-500'>Sorry, we couldn’t find the page you’re looking for.</p>
            <div className='mt-6'>
              <Link to='/' className='text-base font-medium text-cego-black'>
                Go back home
                <span aria-hidden='true'> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
