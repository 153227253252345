import { gql } from '@apollo/client';
import { IThemeType } from './themeType';
import { ITargetAudienceType } from './targetAudienceType';

export interface IActivity {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  slug?: string;
  title?: string;
  activityTypeID?: number;
  activityType?: any;

  themeTypeID?: number;
  themeType?: IThemeType;

  targetAudienceTypeID?: number;
  targetAudienceType?: ITargetAudienceType;

  entityID?: number;
  entity?: any;
  body?: string;
  usp?: string;
  goals?: string[];
  quotes?: { name: string; jobTitle: string; quote: string }[];
  additional?: string;

  isFeatured?: boolean;
  featuredID?: number;
  featured?: any;
  featuredIDPrismic?: string;
  image?: string;

  featuredBy?: any;
  activityUsers?: any[];
  attributeValues?: any[];
  events?: any[];
  activityTargetAudienceTypes?: any[];
  activityThemeTypes?: any[];
  activityEntities?: any[];
}

export interface ICreateActivity {
  title?: string;
  activityTypeID?: number;
  entityID?: number;
  body?: string;
  usp?: string;
  goals?: string[];
  additional?: string;

  featuredID?: number;
  featuredIDPrismic?: string;
  image?: string;
}

export interface IUpdateActivity {
  title?: string;
  activityTypeID?: number;
  entityID?: number;
  body?: string;
  usp?: string;
  goals?: string[];
  additional?: string;

  featuredID?: number;
  featuredIDPrismic?: string;
  image?: string;
}

//limited obj for overvieuw table
export const GET_ACTIVITIES = gql`
  query getActivities($filter: ActivityFilterInput, $skip: Int, $take: Int) {
    findManyActivities(filter: $filter, skip: $skip, take: $take) {
      id
      title
      slug
      activityTypeID
      activityType {
        id
        title
      }
      activityEntities {
        id
        entity {
          id
          title
        }
      }
      entityID
      entity {
        id
        title
      }
      events {
        id
        location {
          name
          address
          city
          zipcode
          locationUrl
        }
        waitingList {
          firstName
          lastName
          email
          phone
        }
        extraDates {
          startTime
          endTime
        }
        startTime
        endTime
        online
        closed
      }
    }
  }
`;

// full object for detail page
export const GET_ACTIVITY = gql`
  query getActivity($where: ActivityWhereInput) {
    findOneActivity(where: $where) {
      id
      title
      slug
      usp
      body
      goals
      isFeatured
      quotes {
        name
        jobTitle
        quote
      }
      image
      activityTypeID
      activityType {
        id
        title
      }
      entityID
      entity {
        id
        title
      }
      themeTypeID
      themeType {
        id
        title
      }
      targetAudienceTypeID
      targetAudienceType {
        id
        title
      }
      events {
        id
        status
        location {
          name
          address
          city
          zipcode
          locationUrl
        }
        waitingList {
          firstName
          lastName
          email
          phone
        }
        extraDates {
          startTime
          endTime
        }
        startTime
        endTime
        online
        closed
        ticketTypes {
          id
          price
          stock
        }
      }
      activityUsers {
        user {
          id
          firstName
          lastName
          jobTitle
          image
        }
      }
      activityThemeTypes {
        id
        themeType {
          id
          title
        }
      }
      activityTargetAudienceTypes {
        id
        targetAudienceType {
          id
          title
        }
      }
      activityEntities {
        id
        entity {
          id
          title
        }
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity($data: ActivityCreateInput!) {
    createActivity(data: $data) {
      id
      slug
      title
      usp
      body
      goals
      isFeatured
      quotes {
        name
        jobTitle
        quote
      }
      image
      activityTypeID
      activityType {
        id
        title
      }
      entityID
      entity {
        id
        title
      }
      themeTypeID
      themeType {
        id
        title
      }
      targetAudienceTypeID
      targetAudienceType {
        id
        title
      }
      events {
        id
        location {
          name
          address
          city
          zipcode
          locationUrl
        }
        waitingList {
          firstName
          lastName
          email
          phone
        }
        extraDates {
          startTime
          endTime
        }
        startTime
        endTime
        online
        closed
        ticketTypes {
          id
          price
          stock
        }
      }
      activityUsers {
        id
        user {
          id
          firstName
          lastName
          jobTitle
          image
        }
      }
      activityThemeTypes {
        id
        themeType {
          id
          title
        }
      }
      activityTargetAudienceTypes {
        id
        targetAudienceType {
          id
          title
        }
      }
      activityEntities {
        id
        entity {
          id
          title
        }
      }
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity($id: Int!, $data: ActivityUpdateInput) {
    updateActivity(id: $id, data: $data) {
      id
      slug
      title
      usp
      body
      goals
      image
      isFeatured
      quotes {
        name
        jobTitle
        quote
      }
      activityTypeID
      activityType {
        id
        title
      }
      entityID
      entity {
        id
        title
      }
      themeTypeID
      themeType {
        id
        title
      }
      targetAudienceTypeID
      targetAudienceType {
        id
        title
      }
      events {
        id
        location {
          name
          address
          city
          zipcode
          locationUrl
        }
        waitingList {
          firstName
          lastName
          email
          phone
        }
        extraDates {
          startTime
          endTime
        }
        startTime
        endTime
        online
        closed
        ticketTypes {
          id
          price
          stock
          name
        }
      }
      activityUsers {
        user {
          id
          firstName
          lastName
          jobTitle
          image
        }
      }
      activityThemeTypes {
        id
        themeType {
          id
          title
        }
      }
      activityTargetAudienceTypes {
        id
        targetAudienceType {
          id
          title
        }
      }
      activityEntities {
        id
        entity {
          id
          title
        }
      }
    }
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation deleteActivity($where: ActivityWhereInput) {
    deleteActivity(where: $where) {
      id
      title
    }
  }
`;
