import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  resolveMoney,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import Button from '../../components/tailwind/button';
import { toastError, toastSuccess } from '../../utils/toast';
import { CREATE_PRODUCT, DELETE_PRODUCT, GET_PRODUCT, IProduct, UPDATE_PRODUCT } from '../../utils/product';
import { CREATE_ACCOUNT, DELETE_ACCOUNT, GET_ACCOUNT, GET_ACCOUNTS, IAccount, UPDATE_ACCOUNT } from '../../utils/account';

export const Accounts: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  // ===========================================
  // ============== CUSTOMER STATE ==============

  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
  const [selectedProductTab, setSelectedProductTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingProducts, setSortingProducts] = useState<ISorting>(DEFAULT_SORTING);
  const [openProductDetail, setOpenProductDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openProductCreate, setOpenProductCreate] = useState(DEFAULT_DETAIL_OPEN);

  const productColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'product.name',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-18', !item.active && 'text-cego-darkgray')}>
            {item?.name}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Prijs',
      fieldName: 'product.price',
      render: (item: IProduct) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46', !item.active && 'text-cego-darkgray')}>
            {item?.price && resolveMoney(item?.price)}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'BTW Percentage',
      fieldName: 'product.vatPercentage',
      render: (item: IProduct) => {
        return (
          <span
            className={classNames('text-ellipsis overflow-hidden block w-18 text-base', !item.active && 'text-cego-darkgray')}
          >{`${item?.vatPercentage}%`}</span>
        );
      },
    },
  ];

  const [getProduct, { loading: loadingProduct, error: errorProduct, refetch: refetchProduct }] = useLazyQuery(GET_PRODUCT);
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const handleSetProductSelection = (product?: IProduct) => {
    if (product?.id) {
      getProduct({
        variables: {
          where: {
            id: product?.id,
          },
        },
        onCompleted: data => {
          setSelectedProduct(data.findOneProduct);
          setOpenProductDetail(true);
        },
      });
    } else {
      setSelectedProduct(undefined);
    }

    setOpenProductDetail(true);
  };

  const handleUpdateProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProduct?.id) {
            const updateProductObj: any = {
              name: selectedProduct?.name,
              price: selectedProduct?.price && parseFloat(selectedProduct?.price.toString()),
              vatPercentage: selectedProduct?.vatPercentage && parseFloat(selectedProduct?.vatPercentage.toString()),
              active: selectedProduct?.active,
              account: {
                connect: {
                  id: selectedAccount?.id,
                },
              },
            };

            updateProduct({
              variables: {
                id: selectedProduct?.id,
                data: updateProductObj,
              },
              onCompleted: data => {
                resolve(data.updateProduct);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedProduct: IProduct = (await res) as any;

      setSelectedProduct(newUpdatedProduct);
      refetchAccount();

      toastSuccess('Product succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedProduct?.id) {
            const createProductObj: any = {
              name: selectedProduct?.name,
              price: selectedProduct?.price && parseFloat(selectedProduct?.price.toString()),
              vatPercentage: selectedProduct?.vatPercentage && parseFloat(selectedProduct?.vatPercentage.toString()),
              active: selectedProduct?.active,
              account: {
                connect: {
                  id: selectedAccount?.id,
                },
              },
            };

            createProduct({
              variables: {
                data: createProductObj,
              },
              onCompleted: data => {
                resolve(data.createProduct);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const product: IProduct = (await res) as any;
      setSelectedProduct(product);
      refetchAccount();

      toastSuccess('Product succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedProduct?.id) {
            deleteProduct({
              variables: {
                where: {
                  id: selectedProduct?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedProduct(undefined);
      refetchAccount();
      setOpenProductDetail(false);

      toastSuccess('Product succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const productDetailSchema: DetailSchema = {
    id: 'product_detail',
    object: selectedProduct,
    permission: '',
    selectedTab: selectedProductTab,
    setSelectedTab: setSelectedProductTab,
    handleUpdate: handleUpdateProduct,
    handleCreate: handleCreateProduct,
    handleDelete: handleDeleteProduct,
    delete: {
      title: 'Product verwijderen?',
      text: 'Weet je zeker dat je dit product wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'product_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'product_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedProduct?.name,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_1',
            label: 'Prijs',
            name: 'price',
            required: true,
            value: selectedProduct?.price,
            placeholder: '',
            type: 'number',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Prijs moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_3',
            label: 'BTW Percentage',
            name: 'vatPercentage',
            required: true,
            value: selectedProduct?.vatPercentage,
            placeholder: '',
            type: 'number',
            max: 100,
            min: 0,
            step: 1,
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && (value as any) !== 0) {
                return {
                  valid: false,
                  message: 'BTW moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'product_detail_section_0_field_5',
            label: 'Actief',
            name: 'active',
            required: true,
            value: selectedProduct?.active,
            placeholder: '',
            type: 'switch',
            width: 20.5,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // ========== BILLING CUSTOMER STATE =========

  const [selectedAccount, setSelectedAccount] = useState<IAccount | undefined>();
  const [selectedAccountTab, setSelectedAccountTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingAccount, setSortingAccount] = useState<ISorting>(DEFAULT_SORTING);
  const [openAccountDetail, setOpenAccountDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openAccountCreate, setOpenAccountCreate] = useState(DEFAULT_DETAIL_OPEN);

  const accountColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'account.name',
      render: (item: IAccount) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-18', !item.active && 'text-cego-darkgray')}>
            {item?.name}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Code',
      fieldName: 'account.code',
      render: (item: IAccount) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-18', !item.active && 'text-cego-darkgray')}>
            {item?.code}
          </span>
        );
      },
    },
  ];

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ name: { contains: filterArray[i] } }, { code: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataAccounts,
    loading: loadingAccounts,
    error: errorAccounts,
    refetch: refetchAccounts,
    fetchMore: fetchMoreAccounts,
  } = useQuery(GET_ACCOUNTS, {
    variables: {
      filter: getFilters(searchQuery),
    },
  });

  const [getAccount, { loading: loadingAccount, error: errorAccount, refetch: refetchAccount }] = useLazyQuery(GET_ACCOUNT);
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);

  const handleSetAccountSelection = (account?: IAccount) => {
    if (account?.id) {
      getAccount({
        variables: {
          where: {
            id: account?.id,
          },
        },
        onCompleted: data => {
          setSelectedAccount(data.findOneAccount);
          setOpenAccountDetail(true);
        },
      });
    } else {
      setSelectedAccount(undefined);
    }

    setOpenAccountDetail(true);
  };

  const handleUpdateAccount = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedAccount?.id) {
            const updateAccountObj: any = {
              name: selectedAccount?.name,
              code: selectedAccount?.code,
              active: selectedAccount?.active,
            };

            updateAccount({
              variables: {
                id: selectedAccount?.id,
                data: updateAccountObj,
              },
              onCompleted: data => {
                resolve(data.updateAccount);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedAccount: IAccount = (await res) as any;

      setSelectedAccount(newUpdatedAccount);
      refetchAccounts();

      toastSuccess('Rekening succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateAccount = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedAccount?.id) {
            const createAccountObj: any = {
              name: selectedAccount?.name,
              code: selectedAccount?.code,
            };

            createAccount({
              variables: {
                data: createAccountObj,
              },
              onCompleted: data => {
                resolve(data.createAccount);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const account: IAccount = (await res) as any;
      setSelectedAccount(account);
      refetchAccounts();

      toastSuccess('Rekening succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedAccount?.id) {
            deleteAccount({
              variables: {
                where: {
                  id: selectedAccount?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedAccount(undefined);
      refetchAccounts();
      setOpenAccountDetail(false);

      toastSuccess('Rekening succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const accountDetailSchema: DetailSchema = {
    id: 'account_detail',
    object: selectedAccount,
    permission: '',
    selectedTab: selectedAccountTab,
    setSelectedTab: setSelectedAccountTab,
    handleUpdate: handleUpdateAccount,
    handleCreate: handleCreateAccount,
    handleDelete: selectedAccount?.products && selectedAccount?.products.length > 0 ? undefined : handleDeleteAccount,
    delete: {
      title: 'Bestelling verwijderen?',
      text: 'Weet je zeker dat je deze bestelling wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'account_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'account_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedAccount?.name,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAccount((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'account_detail_section_0_field_1',
            label: 'Code',
            name: 'code',
            required: true,
            value: selectedAccount?.code,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedAccount((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Code moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'account_detail_section_0_field_5',
            label: 'Actief',
            name: 'active',
            required: true,
            value: selectedAccount?.active,
            placeholder: '',
            type: 'switch',
            width: 20.5,
            callback: (name: string, value: string) => {
              setSelectedAccount((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
      },
      {
        id: 'account_detail_section_1',
        title: 'Producten',
        width: 100,
        fields: [
          {
            id: 'account_detail_section_1_field_0',
            label: 'Producten',
            name: 'products',
            value: selectedAccount?.products ? selectedAccount?.products : [],
            table: {
              onCreate: handleSetProductSelection,
              columns: productColumns,
              items: selectedAccount?.products ? selectedAccount?.products : [],
              onSelect: handleSetProductSelection,
              selection: selectedProduct,
              showCreateCallback: () => {
                return true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'account_detail_section_1_field_1',
            label: '',
            name: 'detail_customers',
            value: selectedAccount?.products ? selectedAccount?.products : [],
            detail: {
              open: openProductDetail,
              setOpen: setOpenProductDetail,
              title: 'Product',
              caption: selectedProduct?.id ? 'Product updaten' : 'Product toevoegen',
              schema: productDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedProduct((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedAccount && selectedAccount.id && selectedAccount.active === true) {
            return true;
          } else {
            return false;
          }
        },
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Rekeningen</h1>
      </header>
      <div>
        <div className='my-4'>
          <Button
            quaternary
            onClick={() => {
              handleSetAccountSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorAccounts ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingAccounts ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataAccounts ? dataAccounts.findManyAccounts : []}
                columns={accountColumns}
                loading={loadingAccounts}
                onSelect={handleSetAccountSelection}
                selection={selectedAccount}
                lazyLoading={true}
                loadMore={true}
                loadMoreCallback={() => {
                  fetchMoreAccounts({
                    variables: {
                      filter: getFilters(searchQuery),
                      // orderBy: getOrderBy(sortingOrders),
                      skip: dataAccounts ? dataAccounts.findManyAccounts.length : 0,
                      take: DEFAULT_TAKE_LOADMORE,
                    },
                  });
                }}
              />
            </div>

            <Modal open={openAccountDetail} setOpen={setOpenAccountDetail}>
              <>
                {openAccountDetail === true ? (
                  <>
                    {!loadingAccount && !errorAccounts && (
                      <DetailForm
                        schema={accountDetailSchema}
                        title={'Rekening'}
                        caption={selectedAccount?.id ? 'Rekening updaten' : 'Rekening toevoegen'}
                      />
                    )}
                    {loadingAccount && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorAccount && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Accounts;
