import { gql } from '@apollo/client';
import { IUser } from './user';
import { IEvent } from './event';

export interface IEventUser {
  id?: number;
  createdAt?: string; // datetime
  updatedAt?: string; // datetime
  createdByID?: number;
  updatedByID?: number;
  versioning?: number;

  eventID?: number;
  event?: IEvent;
  userID?: number;
  user?: IUser;
}

export const GET_EVENT_USERS = gql`
  query getEventUsers($filter: EventUserFilterInput) {
    findManyEventUsers(filter: $filter) {
      createdAt
      updatedAt
      id
      eventID
      userID
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_EVENT_USER = gql`
  mutation createEventUser($data: EventUserCreateInput!) {
    createEventUser(data: $data) {
      createdAt
      updatedAt
      id
    }
  }
`;

export const DELETE_EVENT_USER = gql`
  mutation deleteEventUser($where: EventUserWhereInput) {
    deleteEventUser(where: $where) {
      createdAt
      updatedAt
      id
    }
  }
`;
