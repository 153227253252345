import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { BeatLoader } from 'react-spinners';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { ISorting } from '../../types';
import { SearchContext } from '../../context/searchContext';
import Button from '../../components/tailwind/button';
import { toastError, toastSuccess } from '../../utils/toast';
import { CREATE_VENUE, DELETE_VENUE, GET_VENUE, GET_VENUES, IVenue, UPDATE_VENUE } from '../../utils/venue';

export const Venues: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  const [selectedVenue, setSelectedVenue] = useState<IVenue | undefined>();
  const [selectedVenueTab, setSelectedVenueTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingVenues, setSortingVenues] = useState<ISorting>({
    key: 'name',
    direction: 'asc',
  });
  const [openVenueDetail, setOpenVenueDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [openVenueCreate, setOpenVenueCreate] = useState(DEFAULT_DETAIL_OPEN);
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery]);

  const venueColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'venue.name',
      render: (item: IVenue) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-18', !item.active && 'text-cego-darkgray')}>
            {item?.name}
          </span>
        );
      },
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'venue.address',
      render: (item: IVenue) => {
        return (
          <span className={classNames('text-ellipsis text-base overflow-hidden block w-46', !item.active && 'text-cego-darkgray')}>
            {item?.address}
          </span>
        );
      },
    },
    {
      key: 'column3',
      name: 'Postcode',
      fieldName: 'venue.zipcode',
      render: (item: IVenue) => {
        return (
          <span className={classNames('text-ellipsis overflow-hidden block w-18 text-base', !item.active && 'text-cego-darkgray')}>
            {item?.zipcode}
          </span>
        );
      },
    },
    {
      key: 'column4',
      name: 'Plaats',
      fieldName: 'venue.city',
      render: (item: IVenue) => {
        return (
          <span className={classNames('text-ellipsis overflow-hidden block w-18 text-base', !item.active && 'text-cego-darkgray')}>
            {item?.city}
          </span>
        );
      },
    },
  ];

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ name: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const getOrderBy = useCallback(
    (sorting: any) => {
      return { [sorting.key]: sorting.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingVenues],
  );

  const {
    data: dataVenues,
    loading: loadingVenues,
    error: errorVenues,
    refetch: refetchVenues,
    fetchMore: fetchMoreVenues,
  } = useQuery(GET_VENUES, {
    variables: {
      filter: getFilters(searchQuery),
      orderBy: getOrderBy(sortingVenues),
    },
  });

  const [getVenue, { loading: loadingVenue, error: errorVenue, refetch: refetchVenue }] = useLazyQuery(GET_VENUE);
  const [createVenue] = useMutation(CREATE_VENUE);
  const [updateVenue] = useMutation(UPDATE_VENUE);
  const [deleteVenue] = useMutation(DELETE_VENUE);

  const handleSetVenueSelection = (venue?: IVenue) => {
    if (venue?.id) {
      getVenue({
        variables: {
          where: {
            id: venue?.id,
          },
        },
        onCompleted: data => {
          setSelectedVenue(data.findOneVenue);
          setOpenVenueDetail(true);
        },
      });
    } else {
      setSelectedVenue(undefined);
    }

    setOpenVenueDetail(true);
  };

  const handleUpdateVenue = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedVenue?.id) {
            const updateVenueObj: any = {
              name: selectedVenue?.name,
              address: selectedVenue?.address,
              zipcode: selectedVenue?.zipcode,
              city: selectedVenue?.city,
              active: selectedVenue?.active,
            };

            updateVenue({
              variables: {
                id: selectedVenue?.id,
                data: updateVenueObj,
              },
              onCompleted: data => {
                resolve(data.updateVenue);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const newUpdatedVenue: IVenue = (await res) as any;

      setSelectedVenue(newUpdatedVenue);
      refetchVenues();

      toastSuccess('Locatie succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateVenue = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedVenue?.id) {
            const createVenueObj: any = {
              name: selectedVenue?.name,
              address: selectedVenue?.address,
              zipcode: selectedVenue?.zipcode,
              city: selectedVenue?.city,
              active: selectedVenue?.active,
            };

            createVenue({
              variables: {
                data: createVenueObj,
              },
              onCompleted: data => {
                resolve(data.createVenue);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const venue: IVenue = (await res) as any;
      setSelectedVenue(venue);
      refetchVenues();

      toastSuccess('Locatie succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteVenue = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedVenue?.id) {
            deleteVenue({
              variables: {
                where: {
                  id: selectedVenue?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedVenue(undefined);
      refetchVenues();
      setOpenVenueDetail(false);

      toastSuccess('Locatie succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const venueDetailSchema: DetailSchema = {
    id: 'order_detail',
    object: selectedVenue,
    permission: '',
    selectedTab: selectedVenueTab,
    setSelectedTab: setSelectedVenueTab,
    handleUpdate: handleUpdateVenue,
    handleCreate: handleCreateVenue,
    handleDelete: handleDeleteVenue,
    delete: {
      title: 'Locatie verwijderen?',
      text: 'Weet je zeker dat je deze locatie wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'customer_detail_section_x',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'venue_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedVenue?.name,
            placeholder: '',
            type: 'text',
            width: 25.5,
            callback: (name: string, value: string) => {
              setSelectedVenue((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'venue_detail_section_0_field_01',
            label: 'Adres',
            name: 'address',
            required: true,
            value: selectedVenue?.address,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedVenue((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Adres moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'venue_detail_section_0_field_0',
            label: 'Postcode',
            name: 'zipcode',
            required: true,
            value: selectedVenue?.zipcode,
            placeholder: '',
            type: 'text',
            width: 15.5,
            callback: (name: string, value: string) => {
              setSelectedVenue((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Postcode moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'venue_detail_section_0_field_02',
            label: 'Plaats',
            name: 'city',
            required: true,
            value: selectedVenue?.city,
            placeholder: '',
            type: 'text',
            width: 15.5,
            callback: (name: string, value: string) => {
              setSelectedVenue((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Adres moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'venue_detail_section_0_field_53',
            label: 'Actief',
            name: 'active',
            required: true,
            value: selectedVenue?.active,
            placeholder: '',
            type: 'switch',
            width: 6.5,
            callback: (name: string, value: string) => {
              setSelectedVenue((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
      },
    ],
  };

  // ===========================================

  return (
    <div id='customers'>
      <header>
        <h1 className='font-bold text-2xl'>Locaties</h1>
      </header>
      <div>
        <div className='my-4'>
          <Button
            quaternary
            onClick={() => {
              handleSetVenueSelection();
            }}
          >
            {'Nieuw'}{' '}
            <span className='inline ml-1'>
              <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
            </span>
          </Button>
        </div>
        {errorVenues ? (
          <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
            <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
              <div className='py-16'>
                <div className='text-center'>
                  <p className='text-6xl font-bold text-cego-black'>Error</p>
                  <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                  <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
                </div>
              </div>
            </main>
          </div>
        ) : loadingVenues ? (
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <Table
                items={dataVenues ? dataVenues.findManyVenues : []}
                columns={venueColumns}
                loading={loadingVenues}
                onSelect={handleSetVenueSelection}
                selection={selectedVenue}
                lazyLoading={true}
                loadMore={true}
                // loadMoreCallback={() => {

                // }}
              />
            </div>
            <Modal open={openVenueDetail} setOpen={setOpenVenueDetail}>
              <>
                {openVenueDetail === true ? (
                  <>
                    {!loadingVenue && !errorVenue && (
                      <DetailForm
                        schema={venueDetailSchema}
                        title={'Locatie'}
                        caption={selectedVenue?.id ? 'Locatie updaten' : 'Locatie toevoegen'}
                      />
                    )}
                    {loadingVenue && (
                      <div className='h-5/6 flex items-center'>
                        <div className={classNames('sweet-loading text-center w-full')}>
                          <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                        </div>
                      </div>
                    )}
                    {errorVenue && <p>Error</p>}
                  </>
                ) : (
                  <div />
                )}
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Venues;
