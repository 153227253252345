import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Input: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  className,
  ...props
}) => {
  return (
    <input
      className={classNames(
        'block w-full shadow-sm focus:ring-cego-lightbrown focus:border-cego-lightbrown font-light text-base border-cego-lightbrown rounded-sm py-2.5',
        props.disabled ? 'bg-gray-100 text-gray-500' : '',
        className || '',
      )}
      {...props}
    />
  );
};

export { Input };
