import { gql } from '@apollo/client';
import { IEvent } from './event';
import { ITicket } from './ticket';
import { IAttendee } from './attendee';
import { IOrder } from './orders';
import { ISessionType } from './sessionType';

export interface IRoom {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  event: IEvent;
  eventID: number;
  order: IOrder;
  orderID: number;
  price: number;
  status: string;
  roomType: ISessionType;
  roomTypeID: number;
}

export const UPDATE_ROOM = gql`
  mutation updateRoom($id: Int!, $data: RoomUpdateInput) {
    updateRoom(id: $id, data: $data) {
      id
      createdAt
      updatedAt
      createdByID
      updatedByID
      versioning

      price
      status

      roomType {
        id
        name
        price
      }

      eventID
    }
  }
`;
